/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Users from "views/Users";
import Employees from "views/Employees";
import Schedules from "views/Schedules";
import Questionnaire from "views/Questionnaire";
import Settings from "views/TestTypeSetting/TestTypesSetting";
import employeeIcon from "../src/assets/img/employee-icon.png";
import calendarIcon from "../src/assets/img/calendar-icon.svg";
import activeProgramIcon from "../src/assets/img/active-programs.png";
import copyIcon from "../src/assets/img/Copy-icon.svg";
import garageIcon from "../src/assets/img/settings-garage.png";
import Programs from "views/Programs";
import Crew from "views/Crew";
import ZoneMatrix from "views/ZoneMatrix";
import TestingCalendar from "views/TestingCalendar/TestingCalendar";
import TestView from "components/Test/TestView";
import TotalTestApi from "components/Test/TotalTestApi";

const dashboardRoutes = [
  {
    path: "/employees",
    name: "Employees",
    src: employeeIcon,
    icon: "nc-icon nc-badge",
    component: Employees,
    layout: "/admin",
  },
  {
    path: "/totalTest",
    name: "Total Tests",
    src: copyIcon,
    icon: "",
    component: TotalTestApi,
    layout: "/admin",
  },
  {
    path: "/test/:testId",
    name: "Test",
    icon: "nc-icon nc-circle-09",
    component: TestView,
    layout: "/admin",
    redirect: true,
  },
  // {
  //   path: "/schedules",
  //   name: "Schedules",
  //   icon: "nc-icon nc-badge",
  //   component: Schedules,
  //   layout: "/admin",
  // },
  // {
  //   path: "/questionnaire",
  //   name: "Questionnaire",
  //   icon: "nc-icon nc-badge",
  //   component: Questionnaire,
  //   layout: "/admin",
  // },
  // {
  //   path: "/programs",
  //   name: "Active Programs",
  //   icon: "nc-icon nc-badge",
  //   src: activeProgramIcon,
  //   component: Programs,
  //   layout: "/admin",
  // },
  {
    path: "/testingcalendar",
    name: "Testing Calendar",
    src: calendarIcon,
    icon: "",
    component: TestingCalendar,
    layout: "/admin",
  },
  {
    path: "/tcmatrix",
    name: "TC Matrix",
    icon: "nc-icon nc-badge",
    src: copyIcon,
    component: ZoneMatrix,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "System Users",
    icon: "nc-icon nc-badge",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/crews",
    name: "Crews",
    icon: "nc-icon nc-badge",
    component: Crew,
    layout: "/admin",
  },
  // {
  //   path: "/alertmanagement",
  //   name: "Incident Mgt",
  //   icon: "nc-icon nc-badge",
  //   component: Programs,
  //   layout: "/admin",
  //   disabled: true
  // },
  // {
  //   path: "/contracttracing",
  //   name: "Contract Tracing",
  //   icon: "nc-icon nc-badge",
  //   component: Programs,
  //   layout: "/admin",
  //   disabled: true
  // },
  // {
  //   path: "/virtualhealthconfig",
  //   name: "Virtual Health Config",
  //   icon: "nc-icon nc-badge",
  //   component: Programs,
  //   layout: "/admin",
  //   disabled: true
  // },
  {
    path: "/settings",
    name: "Settings",
    icon: "nc-icon nc-badge",
    src: garageIcon,
    component: Settings,
    layout: "/admin",
  },
];

export default dashboardRoutes;
