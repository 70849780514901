import React from "react";
import { formatNumber } from "utils";
import { removeSpaceIns } from "utils";

const ScheduleZoneEventBar = (props) => {
  const { childNode, event, testByZone, handleClickCustomSch } = props;

  return (
    <div className="event-bar mb-2" key={event.id}>
      <span
        className="event-bar-left"
        style={{
          backgroundColor: `${removeSpaceIns(event?.color || "#000")}`,
        }}
      />
      <span
        style={{
          backgroundColor: `${removeSpaceIns(event?.color || "#000")}`,
        }}
        onClick={() => handleClickCustomSch && handleClickCustomSch(event)}
        title={
          testByZone
            ? `${event.testTypes.join()}`
            : event.testTypes.length > 1
            ? `${event.name}: ${event.testTypes.join()}`
            : `${event.name}`
        }
        className="event d-flex align-items-center justify-content-between pe-3"
        ref={childNode}
      >
        <span className="eventName custom-ellipsis d-block">
          {testByZone ? `${event.title}` : event.testTypes.length > 1 ? `Multi` : `${event.testTypes}`}
        </span>
        <span className="eventName">
          {testByZone
            ? ` (${formatNumber(event.employees.length)})`
            : event.testTypes.length > 1
            ? ` (${formatNumber(event.employees.length)})`
            : ` (${formatNumber(event.employees.length)})`}
        </span>
      </span>
    </div>
  );
};

export default ScheduleZoneEventBar;
