import React, { useState, useContext } from "react";

// react-bootstrap components
import { Card, InputGroup, FormControl } from "react-bootstrap";
import ColorLabel from "components/ColorLabel";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  STATUS_OPTIONS,
  VACCINATED_OPTIONS,
  QA_OPTIONS,
  TEST_OPTIONS,
  weekDaysOptions,
  CHECKIN_OPTIONS,
  ZONE_STATUS_OPTIONS,
  DRIVE_ACCESS_OPTIONS,
  COLOR_OPT,
  CHECKIN_OPTIONS_MATRIX,
  Quarantine_OPTIONS,
  EXPECTED_ON_SET,
} from "constant";
import { WEEK_DAYS } from "constant";
import { AppContext } from "../context/app-context";
import { RESULT_OPTIONS } from "constant";
import { TEST_TYPES } from "constant";
import { t } from "constants/stringConstants";
import { CONFIG } from "utils";

const Filter = ({
  filterTerms,
  setFilter,
  filter,
  isUsersTable,
  triggerFilter,
  setTriggerFilter,
  handleClearFilter,
  isZoneLevel,
  program,
  matrix,
}) => {
  const [clearFilter, setClearFilter] = useState(false);
  const appContext = useContext(AppContext);
  const colorOptions = () => {
    const unique = [...new Set(appContext.programs.map((item) => item.color))];
    return unique.map((p) => {
      return { value: p, label: <ColorLabel color={p} /> };
    });
  };
  const filterAlias = (term) => {
    switch (term) {
      case "Crew ID":
        return "id";
      case "Name":
        return "name";
      case "First Name":
        return "firstName";
      case "Last Name":
        return "lastName";
      case "Phone":
        if (isUsersTable) {
          return "phone_number";
        } else {
          return "phoneNumber";
        }
      // return "phoneNumber";
      case "Email":
        return "email";
      case "Barcode":
        return "barcode";
      case "Status":
        return "status";
      case "Result":
        return "result";
      // case "Lab":
      //   return "lab";
      // case "Show":
      //   return "site_name";
      case "Role":
        return "role";
      case "Contact Name":
        return "contact_name";
      case "Contact Phone":
        return "contact_phone";
      case "Contact Email":
        return "contact_email";
      case "Test Type":
        return "test_type";
      case "Associated":
        return "associated";
      case "Tester":
        return "tester_name";
      case "Crew Id":
        return "employeeID";
      case "Department":
        return "departmentName";
      case "Schedule Type":
        return "schType";
      case "Schedule Name":
        return "scheduleName";
      case "Start Date":
        return "startDate";
      case "End Date":
        return "endDate";
      case t("onSetDate"):
        return "onSetDates";
      case "Group Assigned":
        return "groupName";
      case "Group Name":
        return "groupName";
      case `${program} Name`:
        return "programName";
      case "Zone Color":
        return "zoneColor";
      case "Zone":
        return "zoneSearch";
      case "Color":
        return "color";
      case "Drive on Access":
        return "driveOnAccess";
      case "Schedule":
        return "scheduleName";
      case "Gender":
        return "gender";
      case "isVaccinated":
        return "vaccinated";
      case "Region":
        return "region";
      case "Office":
        return "location";
      case "SCHR ID":
        return "schrID";
      case "Screening Type":
        return "testingGroup";
      case `${program} Status`:
        return "isSchedule";
      case "PSQ":
        return "qaDone";
      case "Test":
        return "testDone";
      case "Days of Weeks":
        return "days";
      case "Check In":
        return "checkIn";
      case "Call Time":
        return "callTime";
      case "Local #":
        return "localNo";
      case "Job Title":
        return "jobTitle";
      case "Time Zone":
        return "empTZ";
      case "Test_Type":
        return "tests";
      case "Updated At":
        return "updatedAt";
      case "Quarantine":
        return "quarantineColor";
      case "Positive Employee":
        return "positiveEmployee";
      case CONFIG.expectedOnSet:
        return "onSet";
      default:
        return term;
    }
  };
  const getDropDown = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];
    const prevSelectedVal = prevData ? data.filter((d) => prevData.indexOf(d.value) !== -1) : null;
    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        className="filterSelector"
        defaultValue={null}
        menuPlacement="auto"
        value={prevSelectedVal && prevSelectedVal.length > 0 ? prevSelectedVal[0] : null}
        onChange={(e) => {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.value,
          });
        }}
      />
    );
  };
  const getMultiDropDown = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];
    const prevSelectedVal = prevData ? data.filter((d) => prevData.indexOf(d.value) !== -1) : null;
    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        className="filterSelector"
        defaultValue={null}
        menuPlacement="auto"
        isMulti
        value={prevSelectedVal}
        onChange={(e) => {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.map((t) => t.value),
          });
        }}
      />
    );
  };
  const lowerCaseKeys = (obj) => {
    const keys = Object.keys(obj);
    const newObj = {};
    for (const key of keys) {
      newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
  };
  const dateRangeSelection = (filterTerm) => (
    <DateRangePicker
      onApply={(event, picker) => {
        picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: { startDate: picker.startDate, endDate: picker.endDate },
        });
      }}
      onCancel={(event, picker) => {
        picker.element.val("");
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: {},
        });
      }}
      initialSettings={
        filter[filterAlias(filterTerm)]
          ? filter[filterAlias(filterTerm)]
          : {
              drops: "auto",
              autoUpdateInput: false,
              locale: {
                cancelLabel: "Clear",
              },
            }
      }
    >
      <input
        type="text"
        placeholder="Select Date Range"
        readOnly
        className="dateRangeFilter filerInputField"
        defaultValue=""
      />
    </DateRangePicker>
  );
  const filterContent = (filterTerm) => {
    switch (filterTerm) {
      case `${program} Name`:
        return getMultiDropDown(
          appContext.programs.map((p) => {
            return { value: p.name, label: p.name };
          }),
          filterTerm
        );
      case "Zone Color":
        return getMultiDropDown(colorOptions(), filterTerm);
      case "Color":
        return getMultiDropDown(colorOptions(), filterTerm);
      case "Quarantine":
        return getMultiDropDown(Quarantine_OPTIONS, filterTerm);
      case "Result":
        return getMultiDropDown(RESULT_OPTIONS, filterTerm);
      case "Test Type":
        return getMultiDropDown(TEST_TYPES, filterTerm);
      case `${program} Status`:
        return getDropDown(isZoneLevel ? ZONE_STATUS_OPTIONS : STATUS_OPTIONS, filterTerm);
      case "isVaccinated":
        return getDropDown(VACCINATED_OPTIONS, filterTerm);
      case "Test":
        return getDropDown(TEST_OPTIONS, filterTerm);
      case "PSQ":
        return getDropDown(QA_OPTIONS, filterTerm);
      case "Days of Weeks":
        return getMultiDropDown(weekDaysOptions, filterTerm);
      case "Start Date":
        return dateRangeSelection(filterTerm);
      case "End Date":
        return dateRangeSelection(filterTerm);
      case "Updated At":
        return dateRangeSelection(filterTerm);
      case "on Set Date Range":
        return dateRangeSelection(filterTerm);
      case "Check In":
        return getDropDown(matrix ? CHECKIN_OPTIONS_MATRIX : CHECKIN_OPTIONS, filterTerm);
      case "Drive on Access":
        return getDropDown(DRIVE_ACCESS_OPTIONS, filterTerm);
      case "Expected on Set":
        return getDropDown(EXPECTED_ON_SET, filterTerm);

      case "Positive Employee":
        return getDropDown(
          [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ],
          filterTerm
        );
      default:
        return (
          <FormControl
            className="filerInputField"
            onChange={(e) => {
              setFilter({
                ...filter,
                [filterAlias(filterTerm)]: e.target.value.toLowerCase(),
              });
            }}
            value={filter[filterAlias(filterTerm)]}
          />
        );
    }
  };
  const renderFilters = (filters) => {
    return filters.map((filterTerm) => {
      return (
        <InputGroup key={filterTerm} className="d-flex">
          {triggerFilter && (setFilter({ ...filter }), setTriggerFilter(false))}
          <InputGroup.Text
            style={{
              width: "150px",
            }}
          >
            {filterTerm}
          </InputGroup.Text>
          {filterContent(filterTerm)}
        </InputGroup>
      );
    });
  };

  return (
    <div>
      <div className="filterResetBtn">
        <span
          onClick={() => {
            Array.from(document.getElementsByClassName("filerInputField")).forEach((input) => (input.value = ""));
            setFilter({});
            if (handleClearFilter) handleClearFilter();
          }}
          className="linkedText textUnderLine"
        >
          Clear Filter
        </span>
      </div>
      <Card className="mt-3">{renderFilters(filterTerms)}</Card>
    </div>
  );
};

export default Filter;
