import parsePhoneNumber, { AsYouType, isValidNumber } from "libphonenumber-js";
import moment from "moment";
import {
  AMAZON_CLIENT_RESULT,
  LOCATION,
  REGION,
  NEW_PERSONALIZE,
  EMP_ZONE_PERSONALIZE,
  SCHEDULE_CATEGORY,
  HISTORY_TEST,
  ZONESTATUS_TITLE,
  PROGRAMSTATUS_TITLE,
  FUTURE_INITAIL_DATE,
} from "constant";
import { isPossiblePhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import { GROUP_TYPES } from "constant";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import FileSaver from "file-saver";
import { jsonToCSV } from "react-papaparse";
import postalCodes from "postal-codes-js";
import { TEST_TYPE_VALUE, TIME_ZONE_OFFSET } from "constant";
import { v4 as uuidv4 } from "uuid";
import { STATUS } from "constant";
import Status from "components/Status/Status";
import { PERSONALIZATION } from "constant";

export const CONFIG = {
  url: process.env.REACT_APP_URL,
  env: process.env.REACT_APP_ENV,
  isLive: process.env.REACT_APP_ENV === "live",
  expectedOnSet: process.env.REACT_APP_EXPECTED_ON_SET,
  eos: process.env.REACT_APP_EOS,
  neos: process.env.REACT_APP_NEOS,
  ueos: process.env.REACT_APP_UEOS,
  updateOnSetList: process.env.REACT_APP_UPDATE_ON_SET_LIST,
  onSetDateRange: process.env.REACT_APP_ON_SET_DATE,
};

export const downloadDataAsCSV = async (data, fileName) => {
  const content = jsonToCSV(data);
  const fileContent = new Blob([content], { type: "csv" });
  await FileSaver.saveAs(fileContent, `${fileName}.csv`);
};

export const formatPhoneNumber = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    const phone = formatPhoneNumberIntl(phone_number);
    return phone;
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const phoneFormatter = (phone) => {
  if (phone) {
    const d = setPhoneNo(phone);
    return new AsYouType("US").input(d);
  }
  return phone;
};
export const isValidEmail = (email) => {
  if (
    new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(email)
  ) {
    return true;
  } else return false;
};
export const isValidCallTime = (clt) => {
  if (new RegExp(/^([01][0-9]|2[0-3]):([0-5][0-9])$/).test(clt)) {
    return true;
  }
  return false;
};
export const checkValidity = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/[^A-Z0-9]/gi, "");
};
export const removeSpaceIns = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/\s/g, "");
};
export const toTitleCase = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.toLowerCase().replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase());
};
export const CapsFirstLetter = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2);
};
export const convertToLower = (val) => {
  const v = val.trim();
  if (v) return v.toLowerCase();
  return val;
};

export const getCallTime = (clt) => {
  if (!clt) return "";
  const fullTme = moment(clt, "h:mm A").format("HH:mm");
  console.log("Convert Format", fullTme, clt);
  return fullTme;
  // const time = clt.split(":");
  // if (time[0].length == 1) time[0] = "0" + time[0];
  // const calltime = [...time];
  // if (calltime[1] % 15 != 0) {
  //   calltime[1] = calltime[1] - (calltime[1] % 15);
  //   if (calltime[1] == 0) calltime[1] = "00";
  //   return calltime.join(":");
  // }
  // return time.join(":");
};

export const getTodayDate = () => {
  // console.log('current date : ', moment().local().format('YYYY-MM-DD HH:mm:ss'))
  // const Date = moment().startOf("day").utc().toISOString();
  let today = moment().local().format("YYYY-MM-DD");
  console.log("moment().tz() : ", today);
  today = today + " 00:00:00";
  today = moment(today).utc();
  console.log("data is : ", today.toISOString());
  return today.toISOString();
};

export const getValidDep = (name) => {
  if (!name) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};
export const getValidReg = (reg) => {
  if (!reg) return "";
  return CapsFirstLetter(reg.replace(/  +/g, " "));
};
export const getValidSpaces = (name) => {
  if (!name) return "";
  return name.replace(/  +/g, " ");
};

export const isValidName = (name) => {
  if (!name) return false;
  if (new RegExp(/^(?!.*([a-z])\1{2})[A-Za-z ]{2,75}$/i).test(name)) {
    return true;
  } else return false;
};
export const isValidPhone = (phone) => isValidNumber(phone, "US");
export const validatePhone = (value) => {
  if (value) return isPossiblePhoneNumber(value);
  return false;
};

export const differenceBtw2Days = (a, b) => {
  a = moment(a);
  b = moment(b);
  return a.diff(b, "days") + 1 || 1;
};

export const getZoneLevelSetting = async (item, programList, departments) => {
  let testList = [];
  let testDone = false;
  let dailyTask = item.dailyTask;
  let questionLogDate = null;
  let logDate = null;
  const empTestDoneList = item.testHistory || [];
  const dpt = departments.find((f) => f.id === item.department);
  let currentDate = moment().format("YYYY-MM-DD");
  if (dpt?.deptTimeZone) {
    currentDate = moment().utc().add(TIME_ZONE_OFFSET[dpt.deptTimeZone], "minutes").format("YYYY-MM-DD");
  }
  let scheduleItems = null; // use this key to search the testing schedule first from zone then from custom schedule
  let zoneSchedule = null; // use this key to search the question schedule
  try {
    if (item.programID) {
      const programSetting = programList.find((f) => f.id === item.programID);
      if (programSetting && programSetting.testsData && programSetting.testsData.length > 0) {
        scheduleItems = programSetting.testsData.find((t) => t.date === currentDate);
        zoneSchedule = programSetting.testsData.find((t) => t.date === currentDate);
      }
    }

    // if custom schedule is assign then find the date and use that schedule
    if (item.CustomSchedule && item.CustomSchedule.length > 0) {
      const customSchedule = item.CustomSchedule.find((t) => t.date === currentDate);
      if (customSchedule) {
        scheduleItems = customSchedule;
      }
    }

    // create testing schedule
    if (scheduleItems && scheduleItems.testTypes) {
      // there is a possibility that no test is assign on that specific day
      logDate = `${currentDate}T00:00:00Z`;
      scheduleItems?.testTypes.forEach((obj) => {
        const prevTest = empTestDoneList.find((e) => {
          let testDoneDate = moment(e.date).utc().format("YYYY-MM-DD");
          if (dpt?.deptTimeZone) {
            testDoneDate = moment(e.date).utc().add(TIME_ZONE_OFFSET[dpt.deptTimeZone], "minutes").format("YYYY-MM-DD");
          }
          return e.value === obj.value && moment().utc().format("YYYY-MM-DD") === testDoneDate;
        });
        if (prevTest) {
          testList.push(prevTest);
        } else {
          testList.push({
            id: uuidv4(),
            value: obj.value,
            label: TEST_TYPE_VALUE[obj.value],
            location: obj.location || "On Location",
            testNow: false,
            isDone: false,
            scheduleCategory: "Testing",
            result: "",
          });
        }
      });
    }
    console.log("empTestDoneList", empTestDoneList, testList);

    return [testList, logDate];
  } catch (err) {
    console.log("Zone setting error", err);
  }
  return [[], null];
};

export const getZoneLevelCustomTestsName = async (item, programSetting, timezone) => {
  let dailyTask = null;
  let logDate = null;
  const testList = [];
  const empTestDoneList = item.testHistory || [];
  let callTimeDate = moment().add(TIME_ZONE_OFFSET[timezone], "minutes");

  let dateToCheck = callTimeDate.format("YYYY-MM-DD");
  logDate = `${dateToCheck}T00:00:00Z`;
  try {
    let scheduleItems = null; // use this key to search the testing schedule first from zone then from custom schedule
    let zoneSchedule = null; // use this key to search the question schedule

    // if any zone is assgined then check the date is in schedule
    if (item.programID) {
      const empTestDoneList = item.testHistory || [];
      if (programSetting && programSetting.testsData && programSetting.testsData.length > 0) {
        scheduleItems = programSetting.testsData.find((t) => t.date === dateToCheck);
        zoneSchedule = programSetting.testsData.find((t) => t.date === dateToCheck);
      }
    }

    // if custom schedule is assign then find the date and use that schedule
    if (item.CustomSchedule && item.CustomSchedule.length > 0) {
      const customSchedule = item.CustomSchedule.find((t) => t.date === dateToCheck);
      if (customSchedule) {
        scheduleItems = customSchedule;
      }
    }

    // create testing schedule
    if (scheduleItems && scheduleItems.testTypes) {
      // there is a possibility that no test is assign on that specific day
      scheduleItems?.testTypes.forEach((obj) => {
        const prevTest = empTestDoneList.find(
          (e) =>
            e.value === obj.value &&
            moment().add(TIME_ZONE_OFFSET[timezone], "minutes").format("YYYY-MM-DD") ===
              moment(e.date).add(TIME_ZONE_OFFSET[timezone], "minutes").format("YYYY-MM-DD")
        );
        if (prevTest) {
          testList.push(prevTest);
        } else {
          testList.push({
            id: uuidv4(),
            value: obj.value,
            label: obj.label || obj.value,
            location: obj.location || "On Location",
            testNow: false,
            isDone: false,
            scheduleCategory: "Testing",
            result: "",
          });
        }
      });
    }

    // create question schedule
    if (zoneSchedule && zoneSchedule.question) {
      dailyTask = [
        {
          isDone: item.hasOwnProperty("qaDone") ? item.qaDone : false,
          scheduleCategory: "Question",
          scheduleRecord: { id: callTimeDate.format("d"), isChecked: true, name: callTimeDate.format("dddd") },
        },
      ];
    }
  } catch (err) {
    console.log("Zone setting error", err);
  }
  return [testList, dailyTask, logDate];
};

export const getPageNumbers = (items) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
};
export const isValidDob = (dobs) => {
  if (new RegExp(/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/).test(dobs)) {
    return true;
  }
  return false;
};
export const parseBooleanValue = (val) => (val ? "Yes" : "No");
export const getVaccinated = (vac) => {
  if (!vac) return false;
  const val = vac.toLowerCase();
  if (val) return val === "y" || val === "yes" ? true : false;
  return false;
};
export const capitalizeLetter = (value) => {
  if (!value) return "";
  if (value.length === 2) return value.toUpperCase();
  return `${value.substring(0, 1).toUpperCase()}${value.substring(1).toLowerCase()}`;
};

export const formatNumber = (num) => {
  if (!num) return 0;
  return num.toLocaleString("en-US");
};

export const getCrewsName = (empDemos) => {
  // console.log(typeof empDemos);
  if (!empDemos) return "";
  let parsed = typeof empDemos === "string" ? JSON.parse(empDemos) : empDemos;
  let first = parsed.firstName.charAt(0).toUpperCase() + parsed.firstName.slice(1).toLowerCase();
  let last = parsed.lastName.charAt(0).toUpperCase() + parsed.lastName.slice(1).toLowerCase();
  return `${first} ${last}`;
};
export const onBoardingTest = (test) => {
  if (Array.isArray(test)) return [...test];
  if (test instanceof Object) return [{ ...test }];
  return [];
};

export const formatAppsettings = (settings) => {
  if (!settings) return;
  if (typeof settings.generalCallTimeReminder === "string") {
    settings.generalCallTimeReminder = JSON.parse(settings.generalCallTimeReminder);
  }
  if (typeof settings.generalCallTimeReminder?.callTimeDirection === "string") {
    settings.generalCallTimeReminder.callTimeDirection = JSON.parse(
      settings.generalCallTimeReminder?.callTimeDirection
    );
  }
  if (typeof settings.callTimeReminder === "string") {
    settings.callTimeReminder = JSON.parse(settings.callTimeReminder);
  }
  return settings;
};

export const formatTest = (testData, clients) => {
  let demos =
    typeof testData.employee_demographics === "string"
      ? JSON.parse(testData.employee_demographics)
      : testData.employee_demographics;
  let name;
  let firstName;
  let lastName;
  let phoneNumber = testData.phoneNumber;
  let dob = formatDateOfBirth(testData.dob);
  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
    phoneNumber = `${demos.countryCode}${demos.phoneNumber}`;
    dob = formatDateOfBirth(demos.dob);
  }
  const test = { ...testData };
  test.viewedResult = testData.result;
  if (clients && clients.length > 0) {
    const client = clients.find((c) => c.id === test.clientID);
    if (client && client.resultType === "P/F") {
      test.viewedResult = AMAZON_CLIENT_RESULT[test.result];
    }
  }

  test.status = testData.status.toLowerCase() === "pending" && !testData.result ? "New" : testData.status;
  test.test_type =
    testData.test_type === "Antigen" ? "Antigen" : testData.test_type === "Other" ? "Rapid PCR" : testData.test_type;
  return { ...test, name, firstName, lastName, phoneNumber, dob };
};

export const parseMinutesIntoHours = (minutesVal) => {
  if (!minutesVal) return "";
  const number = parseInt(minutesVal);
  if (number >= 15) {
    const valDivide = number / 15;
    if (valDivide >= 4) {
      const reminder = valDivide % 4;
      const hours = Math.floor(valDivide / 4);
      const minutes = reminder * 15;
      return minutes ? `${hours}h ${minutes}m` : `${hours}h`;
    }
    return `${number}m`;
  } else {
    return `${number}h`;
  }
};

export const parseMinutesIntoDHM = (value) => {
  if (isNaN(value)) return value;
  let minutes = parseInt(value || 0);
  let days = Math.floor(minutes / 1440) || 0;
  let hours = Math.floor((minutes % 1440) / 60) || 0;
  let mins = minutes % 60 || 0;
  return `${days ? `${days}d` : ""}${days && (hours || mins) ? " " : ""}${hours ? `${hours}h` : ""}${
    hours && mins ? " " : ""
  }${mins ? `${mins}m` : ""}`;
};

export const parseDHMToMins = (checkVal) => {
  let days = 0;
  let hours = 0;
  let mins = 0;
  const testingRegex = /^\d+d\s\d+h\s\d+m$|^\d+d\s\d+h$|^\d+d\s\d+m$|^\d+h\s\d+m$|^\d+m$|^\d+h$|^\d+d$/;

  if (testingRegex.test(checkVal)) {
    if (checkVal && checkVal.includes("d")) {
      days = parseInt(checkVal.split("d")[0]);
      checkVal = checkVal.split("d")[1].trim();
    }
    if (checkVal && checkVal.includes("h")) {
      hours = parseInt(checkVal.split("h")[0]);
      checkVal = checkVal.split("h")[1].trim();
    }
    if (checkVal && checkVal.includes("m")) {
      mins = parseInt(checkVal.split("m")[0]);
      checkVal = checkVal.split("m")[1].trim();
    }
  }

  return mins + hours * 60 + days * 1440 || 0;
};

export const checkValidReminderFormat = (val) => {
  let checkVal = val || 0;
  const testingRegex = /^\d+d\s\d+h\s\d+m$|^\d+d\s\d+h$|^\d+d\s\d+m$|^\d+h\s\d+m$|^\d+m$|^\d+h$|^\d+d$/;

  if (testingRegex.test(checkVal)) {
    return true;
  } else {
    return false;
  }
};

export const getDropDownValue = (val) => {
  if (val === null || val === undefined) return null;
  if (val) return { value: true, label: "Yes" };
  return { value: false, label: "No" };
};
export const formatQuestion = (ques) => {
  return ques.map((q) => ({ ...q, question: JSON.parse(q.question) }));
};

export const formatQuaratineDisplayDate = (value) => {
  if (!value || value == "") return "-";
  return moment(value).format("MM-DD-YYYY");
};

export const connectedIDByURL = (url) => {
  let urlparams = url.split("/");
  let connectedID = "";
  let testID = "";

  if (urlparams.length === 4) {
    connectedID = urlparams[3];
  }
  if (urlparams.length === 5) {
    connectedID = urlparams[3];
    testID = urlparams[4];
  }
  return { connectedID, testID };
};

export const getDemoGraphics = (test) =>
  typeof test.employee_demographics === "string" ? JSON.parse(test.employee_demographics) : test.employee_demographics;

export const formatDateOfBirth = (dob) =>
  dob ? `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}` : "";

export const getAge = (dob) => {
  const val = formatDateOfBirthDOB(dob);
  return dob ? moment().diff(val, "years") : "";
};
export const getValidGender = (gen) => {
  if (gen.toLowerCase() == "male" || gen.toLowerCase() == "m") return "M";
  if (gen.toLowerCase() == "female" || gen.toLowerCase() == "f") return "F";
  if (gen.toLowerCase() == "x") return "X";
  return "";
};

export const formatTime = (date) => (date ? moment(date).format("hh:mmA") : "");

export const formatDate = (date) => (date ? moment(date).format("MMM DD, YYYY") : "");

export const formatCreatedDate = (date) => moment(date).format("MM/DD/YYYY");

export const formatDateMDY = (date) => (date ? moment(date.substring(0, 10)).format("MM/DD/YYYY") : "");

export const formatDateMDYTime = (date) => (date ? moment(date).format("MM/DD/YYYY hh:mm A") : "");

export const currentDate = () => moment().format("MM/DD/YYYY");

export const formatPDFName = (demos) => {
  let name = "";
  if (demos.lastName) name = `${demos.lastName.substring(0, 1)}${demos.lastName.substring(1).toLowerCase()}`;
  if (name) name = `${name}, `;
  if (demos.firstName) name = `${name}${demos.firstName.substring(0, 1)}${demos.firstName.substring(1).toLowerCase()}`;
  return name;
};

export const formatZip = (val) => {
  if (!val) return "";
  const input = val.replaceAll("[^0-9]+", "");
  if (input.toString().length > 5) {
    return input.substring(0, 5) + "-" + input.substring(6);
  }
  if (input.toString().length === 5) {
    return input.toString();
  }
  return input;
};
export const changeSort = (
  sortBy,
  sortDescending,
  filteredTests,
  setFilteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  switch (sortBy) {
    case "employee_demographics":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
                ? -1
                : 0
            )
      );
      break;
    case "clientID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) < getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) < getClientName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) > getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) > getClientName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "siteID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) < getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) > getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "labID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
            )
      );
      break;
    case "result":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
            )
      );
      break;
    case "qaDone":
    case "testDone":
    case "checkIn":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] || "";
              const testerA = a[sortBy] || "";
              return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] || "";
              const testerA = a[sortBy] || "";
              return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
            })
      );
      break;
    case "employeeID":
    case "status":
    case "callTime":
    case "isVaccinated":
    case "programName":
    case "zoneColor":
    case "localNo":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "location":
    case "region":
    case "street":
    case "city":
    case "gender":
    case "country":
    case "jobTitle":
    case "tester_name":
    case "tests":
    case "onBoardingTesting":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB.toLowerCase() < testerA.toLowerCase()
                ? 1
                : testerA.toLowerCase() < testerB.toLowerCase()
                ? -1
                : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB.toLowerCase() > testerA.toLowerCase()
                ? 1
                : testerA.toLowerCase() > testerB.toLowerCase()
                ? -1
                : 0;
            })
      );
      break;
    case "totalTests":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              b.tests.length < a.tests.length ? 1 : a.tests.length < b.tests.length ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              b.tests.length > a.tests.length ? 1 : a.tests.length > b.tests.length ? -1 : 0
            )
      );
      break;
    default:
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy];
              const testerA = a[sortBy];
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy];
              const testerA = a[sortBy];
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
  }
};

export const isValidDate = (val) => {
  const date = val.replace(/\/|-|,/g, "");
  if (date?.length === 8) {
    const day = date.substring(0, 2);
    const month = date.substring(2, 4);
    const year = date.substring(4);
    const testDate = moment(`${year}-${month}-${day}`).format("YYYY-MM-DD");
    if (testDate !== "Invalid date") {
      return true;
    }
    return false;
  }
  return false;
};

export const setDate = (val) => {
  if (!val) return;
  const date = val.replace(/\/|-|,/g, "");
  const day = date.substring(0, 2);
  const month = date.substring(2, 4);
  const year = date.substring(4);
  return moment(`${year}-${month}-${day}`).format();
};

export const formatUTCDate = (date) => (date ? moment(date).format("MM-DD-YYYY") : "");

export const sortList = (sortBy, sortDescending, filteredTests) => {
  switch (sortBy) {
    case "employee_demographics":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
              ? -1
              : 0
          );
    case "qaDone":
    case "testDone":
    case "checkIn":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] || "";
            const testerA = a[sortBy] || "";
            return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] || "";
            const testerA = a[sortBy] || "";
            return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
          });
    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "departmentName":
    case "tester_name":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB.toLowerCase() < testerA.toLowerCase()
              ? 1
              : testerA.toLowerCase() < testerB.toLowerCase()
              ? -1
              : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB.toLowerCase() > testerA.toLowerCase()
              ? 1
              : testerA.toLowerCase() > testerB.toLowerCase()
              ? -1
              : 0;
          });
    case "totalTests":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            b.tests.length < a.tests.length ? 1 : a.tests.length < b.tests.length ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            b.tests.length > a.tests.length ? 1 : a.tests.length > b.tests.length ? -1 : 0
          );
    default:
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
  }
};

export const isValidFile = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files are allowed";
    }

    if (file.size / 1024 / 1020 > 10) {
      return "max. 10MB file size allow";
    }

    return "";
  }
  return "";
};

export const isValidEmailAttachment = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif", "pdf", "csv", "xls", "xlsx", "doc", "docx", "txt"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files, PDF, CSV are allowed";
    }

    if (file.size > 10000000) {
      return "Maximum file size should be 10MB!";
    }

    return "";
  }
  return "";
};

export const formatDateMatrix = (d) => moment(d).format("ddd MM/DD");

export const importPhoneNoFormat = (phone) => {
  if (!phone) return;
  const formattedPhoneNo = phone.replace(/\D/g, "");
  if (formattedPhoneNo && formattedPhoneNo.length == 10) {
    return `+1${formattedPhoneNo}`;
  }
  return `+${formattedPhoneNo}`;
};
export const searchPhone = (phone) => {
  if (!phone) return null;
  return phone.includes("+") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};
export const setPhoneNo = (phone) => {
  if (!phone) return null;
  return phone.includes("+1") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};
export const getPhoneNumber = (phone) => {
  if (!phone) return "";
  const countryCode = phone.substring(0, 1);
  console.log(countryCode);
  if (countryCode !== "+") {
    const phoneNumber = `+${phone.replace(/\D/g, "")}`;
    return phoneNumber;
  }
  return phone;
};
export const formatZipCode = (id) => {
  if (!id) return id;
  let val = id.split("-").join("");
  return val.match(/.{1,5}/g).join("-");
};

export const isValidLocation = (loc) => {
  if (!loc) return null;
  const val = loc.toLowerCase();
  return LOCATION.includes(val);
};

export const parseLocation = (loc) => {
  if (!loc) return "";
  if (loc === "On Location") return "On Set";
  return loc;
};

export const isValidRegion = (reg) => {
  if (!reg) return null;
  const val = reg.toLowerCase();
  return REGION.includes(val);
};

export const medFlowInLocalStorage = {
  save: () => localStorage.setItem("medFlowHR", "medFlow"),
  get: () => localStorage.getItem("medFlowHR") || null,
  clear: () => localStorage.clear(),
};

export const saveMatrixDatesInLC = {
  save: (obj) => localStorage.setItem("dates", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("dates")) {
      return JSON.parse(localStorage.getItem("dates"));
    }
    return FUTURE_INITAIL_DATE;
  },
  clear: () => localStorage.clear(),
};

export const sortingFilterInLC = {
  save: (obj) => localStorage.setItem("medflowhr", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("medflowhr")) {
      return JSON.parse(localStorage.getItem("medflowhr"));
    }
    return {
      employees: {},
      programs: {},
      schedules: {},
      questionnaire: {},
      departments: {},
      totalTests: {},
    };
  },
  clear: () => localStorage.removeItem("medflowhr"),
};

export const saveSubsUpdateInLC = {
  save: (arr) => localStorage.setItem("medflowHrSubs", JSON.stringify(arr)),
  get: () => {
    if (localStorage.getItem("medflowHrSubs")) {
      return JSON.parse(localStorage.getItem("medflowHrSubs"));
    }
    return [];
  },
  clear: () => localStorage.removeItem("medflowHrSubs"),
};

export const personalizationLocalStorageHR = {
  save: (obj) => localStorage.setItem("personalizationHR", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("personalizationHR")) {
      return JSON.parse(localStorage.getItem("personalizationHR"));
    }
    return PERSONALIZATION;
  },
  clear: () => localStorage.removeItem("personalizationHR"),
};
export const TCMatrixColsWidth = {
  save: (arr) => localStorage.setItem("medflowHrTcMatrixCols", JSON.stringify(arr)),
  get: () => {
    if (localStorage.getItem("medflowHrTcMatrixCols")) {
      return JSON.parse(localStorage.getItem("medflowHrTcMatrixCols"));
    }
    return { deptLeftStickPosition: [], zoneLeftStickPosition: [] };
  },
  clear: () => localStorage.removeItem("medflowHrTcMatrixCols"),
};

export const userCompanyID = {
  save: (id) => localStorage.setItem("cid", id),
  get: () => localStorage.getItem("cid") || null,
  clear: () => localStorage.removeItem("cid"),
};

export const loggedInUser = {
  save: (obj) => localStorage.setItem("mdhrUserInfo", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("mdhrUserInfo")) {
      return JSON.parse(localStorage.getItem("mdhrUserInfo"));
    }
    return {
      name: "",
      email: "",
      phone: "",
    };
  },
  clear: () => localStorage.removeItem("mdhrUserInfo"),
};

export const FormatAWSDate = (dobFromID) => {
  if (!dobFromID.includes("-")) {
    const awsdob = [];
    awsdob.push(dobFromID.substring(4));
    awsdob.push(dobFromID.substring(0, 2));
    awsdob.push(dobFromID.substring(2, 4));
    return awsdob.join("-");
  }
  return dobFromID;
};

export const formatDateOfBirthDOB = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.includes("-")) {
    const index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if (index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MM/DD/YYYY");
  }
  if (!dob.includes("-")) {
    const dobDate = FormatAWSDate(dob);
    return moment(dobDate).format("MM/DD/YYYY");
  }
  return moment(dob, "MM-DD-YYYY").format("MM/DD/YYYY");
};

export const formatDOB = (dobs) => {
  if (!dobs) return "";
  const dob = dobs.split(/\/|-|,/g);
  if (dob[0].length === 1) {
    dob[0] = "0" + dob[0];
  }
  if (dob[1].length === 1) {
    dob[1] = "0" + dob[1];
  }
  return moment(`${dob[2]}-${dob[0]}-${dob[1]}`).format("YYYY-MM-DD");
};

export const calculateTdWidth = (width, numberOfCol) =>
  screen.width >= "768" && screen.width <= "1024" ? 100 : Math.ceil(width / numberOfCol);

export const personalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, index) => {
    const selectedSetting = arr
      .filter((f) => f.isCheck)
      .map((f) => f.id)
      .join(",");

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    const programKeys = objData.personalize.split(":");
    programKeys[index] = selectedSetting;
    objData["personalize"] = programKeys.join(":");
    console.log("personalize Save as", objData);
    const obj = { personalize: programKeys.join(":") };
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  saveShowAll: (data, type) => {
    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[type] = data;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  getShowAll: (user, type) => {
    let objData = null;
    const obj = JSON.parse(localStorage.getItem("personalisation"));
    if (obj) {
      objData = obj;
    } else if (user["custom:personalisation"]) {
      objData = JSON.parse(user["custom:personalisation"]);
    }
    const isShow = objData[type];
    return isShow === "1" ? true : false;
  },
  get: (user, index, isZoneLevel) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    let NewPersonalize = isZoneLevel ? EMP_ZONE_PERSONALIZE : NEW_PERSONALIZE;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else if (user["custom:personalisation"]) {
        objData = user["custom:personalisation"];
      } else {
        return NewPersonalize;
      }
      objData = JSON.parse(objData);
      arrData = objData.personalize.split(":")[index].split(",");
    } catch (err) {}

    return NewPersonalize.map((f) => {
      return { ...f, isCheck: arrData.indexOf(f.id.toString()) !== -1 };
    });
  },
  getPersonalize: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = objData[key].split(",");
    } catch (err) {}

    return NEW_PERSONALIZE.map((f) => {
      return { ...f, isCheck: arrData?.indexOf(f.id.toString()) !== -1 };
    });
  },
  savePersonalize: (arr, key) => {
    const selectedSetting = arr
      .filter((f) => f.isCheck)
      .map((f) => f.id)
      .join(",");

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  getStorage: () => {
    if (localStorage.getItem("personalisation")) {
      let obj = JSON.parse(localStorage.getItem("personalisation"));
      if (!obj.tcMatrix) {
        Object.assign(obj, { tcMatrix: PERSONALIZATION.tcMatrix });
      }
      if (!obj.useEnter) {
        Object.assign(obj, { useEnter: PERSONALIZATION.useEnter });
      }
      if (!obj.eosSelectionData || Object.keys(obj.eosSelectionData).length === 0) {
        Object.assign(obj, { eosSelectionData: PERSONALIZATION.eosSelectionData });
      }
      return obj;
    }
    return PERSONALIZATION;
  },
  clear: () => localStorage.removeItem("personalisation"),
};

export const removeArrDuplicateObjs = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const sortWorkingDays = (datesArr) => {
  datesArr = datesArr.filter((d) => d.date);
  const tempWorkingDates = [...datesArr];
  tempWorkingDates.sort(function (a, b) {
    const keyA = new Date(a.date);
    const keyB = new Date(b.date);
    // Compare the 2 dates
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
  return tempWorkingDates;
};

export const tdEmail = (email) => (
  <td
    className=" ellipsis"
    style={{
      textDecoration: "underline",
      color: "#A82632",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => email && window.open(`mailto:${email}`)}
    title={email}
  >
    {email}
  </td>
);

export const tdCallTime = (ct) => {
  const callTime = formatCallTime(ct);
  return (
    <td className="ellipsis" style={{ textAlign: "center" }} title={callTime}>
      {callTime || "-"}
    </td>
  );
};

export const tdPhone = (phoneNumber) => (
  <td
    className="ellipsis"
    style={{
      textDecoration: "underline",
      color: "#A82632",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => phoneNumber && window.open(`tel:+${phoneNumber.replace(/\D/g, "")}`)}
    title={formatPhoneNumber(phoneNumber)}
  >
    {formatPhoneNumber(phoneNumber)}
  </td>
);

export const tdCheckBox = (item, user, openExternalTest) => {
  if (item.itemKey === "qaDone" && !user.testNow) {
    return (
      <td
        style={{ textAlign: "center", textOverflow: "visible" }}
        onDoubleClick={() => (user.qaDone === "X" && openExternalTest ? openExternalTest(user, "QA") : "")}
      >
        {user.qaDone === 1 || user.qaDone === "1" || user.qaDone === true ? (
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="green" crossIcon />
          </div>
        ) : user.qaDone === "M" ? (
          <div className="star-icon-wrapper">
            <Status
              type="circle"
              size="md"
              color="blue"
              crossIcon
              title={`${user.manualCheckInReason} \Allowed By: ${user.manualCheckAdmin}`}
            />
          </div>
        ) : user.qaDone === "X" ? (
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="maroon" crossIcon />
          </div>
        ) : (
          "-"
        )}
      </td>
    );
  } else if (item.itemKey === "testDone") {
    const totalTests = [...(user.testTwo || []), ...(user.testOne || [])];
    if (user.testNow) {
      const notTestDone = totalTests.filter((t) => t.testNow && !t.isDone).length > 0;
      const isTestNow = totalTests.filter((t) => t.testNow).length > 0;
      return (
        <td
          style={{ textAlign: "center", textOverflow: "visible" }}
          onClick={() => (notTestDone ? openExternalTest(user, "test") : "")}
        >
          {notTestDone ? (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color="maroon" crossIcon />
            </div>
          ) : totalTests.filter((t) => t.testNow).length === 0 ? (
            "-"
          ) : (
            <div className="star-icon-wrapper">
              <Status type="circle" size="md" color="blue" crossIcon />
            </div>
          )}
        </td>
      );
    }
    return (
      <td
        style={{ textAlign: "center", textOverflow: "visible" }}
        onClick={() => (user.testDone === "X" && openExternalTest ? openExternalTest(user, "test") : "")}
      >
        {user.testDone === 1 || user.testDone === "1" || user.testDone === true ? (
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="green" crossIcon />
          </div>
        ) : user.testDone === "X" ? (
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="maroon" crossIcon />
          </div>
        ) : (
          "-"
        )}
      </td>
    );
  } else if (item.itemKey === "checkIn" && !user.testNow) {
    return (
      <td style={{ textAlign: "center", textOverflow: "visible" }}>
        {user.checkIn === "1" ? (
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="green" crossIcon />
          </div>
        ) : (
          "-"
        )}
      </td>
    );
  } else if (item.itemKey === "driveOnAccess" && !user.testNow) {
    return (
      <td
        onClick={() => user.driveOnAccess && openExternalTest(user.DoAImage)}
        style={{ textAlign: "center", textOverflow: "visible" }}
      >
        {user.driveOnAccess === 1 ? (
          <div className="star-icon-wrapper">
            <Status
              type="circle"
              size="md"
              color="maroon"
              crossIcon
              title={
                user.driveOnAccessRange
                  ? `Start Date: ${formatDateMDY(user.driveOnAccessRange.startDate)} - End Date : ${formatDateMDY(
                      user.driveOnAccessRange.endDate
                    )}`
                  : ""
              }
            />
          </div>
        ) : user.driveOnAccess === 2 ? (
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="green" crossIcon title={user.DoAImage ? "Image" : "No Image"} />
          </div>
        ) : (
          "-"
        )}
      </td>
    );
  } else if (item.itemKey === "onSet") {
    return (
      <td style={{ textAlign: "center", textOverflow: "visible" }}>
        {user.onSet === 1 ? (
          <div className="star-icon-wrapper">
            <Status type="circle" size="md" color="green" crossIcon />
          </div>
        ) : (
          "-"
        )}
      </td>
    );
  } else {
    return <td style={{ textAlign: "center", textOverflow: "visible" }}>-</td>;
  }
};

export const tdProgramStatus = (item, user, isZoneLevel) => {
  return (
    <td
      style={{ textAlign: "center", textOverflow: "visible" }}
      title={isZoneLevel ? ZONESTATUS_TITLE[user.isSchedule] : PROGRAMSTATUS_TITLE[user.isSchedule]}
    >
      {user.isSchedule === STATUS.startProgram || user.isSchedule === STATUS.zoneAssign ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" crossIcon />
        </div>
      ) : user.isSchedule === STATUS.assignProgram ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="yellow" crossIcon />
        </div>
      ) : (
        "-"
      )}
    </td>
  );
};

export const findAndReplaceDailyTaskArray = (arrays) => {
  let duplicateArray = [...arrays];
  let res = duplicateArray.map((d) => {
    if (d.scheduleCategory == "Question") {
      return { ...d, isDone: true };
    }
    return d;
  });
  console.log("daily task is in utils function:!.......", res);
  return res;
};

export const tdZoneColor = (item, user) => {
  return (
    <td
      style={{
        textAlign: item.textAlign,
        textOverflow: item.textOverflow,
      }}
      title={user.zoneColor}
    >
      {user.zoneColor ? (
        <div
          className="m-auto"
          style={{ width: "10px", height: "10px", backgroundColor: `${removeSpaceIns(user.zoneColor)}` }}
        ></div>
      ) : (
        ""
      )}
    </td>
  );
};

export const formatTimeZone = (Tz) => {
  if (!Tz) return "";
  if (Tz.toLowerCase() === "pakistan standard time") return "PKT";
  return Tz.match(/\b\w/g).join("").toUpperCase();
};
export const statusOptions = () => {
  return [
    { value: "0", label: "Empty" },
    {
      value: "1",
      label: (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" crossIcon />
        </div>
      ),
    },
    {
      value: "2",
      label: (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="yellow" crossIcon />
        </div>
      ),
    },
  ];
};

export const formatEmployeesData = (employees, schedules, departments, programs, settings) => {
  if (employees.length === 0) return [];
  const currentDate = moment().format("YYYY-MM-DD");
  return employees.map((emp) => {
    let testingGroup = "";

    const empDept = departments.find((f) => f.id === emp.department);
    let timeZone = emp.empTZ || "";
    if (timeZone === "") {
      timeZone = empDept?.deptTimeZone || "";
    }
    let program = "";
    let zoneQuesSch = [];
    if (programs.length > 0) program = emp.programID ? programs.find((f) => f.id === emp.programID) : "";
    const isZoneLevel = settings?.programLevel === "Zone" ? true : false;
    const isExpectedLevel = settings?.onSet === 1 ? true : false;
    const obj = {
      ...emp,
      gender: emp.sex,
      departmentName: empDept?.name || "-",
      empTZ: timeZone,
      schedulesOptions: {},
      zoneColor: program?.color || "",
      tests: null,
      qaDates: [],
    };
    //  if(!obj.testHistory) {
    //   obj.testHistory = HISTORY_TEST
    //  }
    if (program && program.scheduleID) {
      zoneQuesSch = program.testsData.filter((f) => f.question);
    }

    const quesIndex = zoneQuesSch.findIndex((f) => f.date === currentDate);
    const checkInDate = obj.checkIn;
    if (!isZoneLevel) {
      testingGroup = emp.groupType ? GROUP_TYPES[emp.groupType].shortCode : "";
      const isTodaySchedule = obj.logDate && obj.logDate.substring(0, 10) === currentDate;
      let isTestingSchedule = false;
      if (obj.testOne && obj.testOne.length > 0) {
        isTestingSchedule = true;
      }
      if (obj.testTwo && obj.testTwo.length > 0) {
        isTestingSchedule = true;
      }
      if (isTestingSchedule && !isZoneLevel) {
        const testsTypes = [...(obj.testOne || []), ...(obj.testTwo || [])]
          .filter((f) => !f.testNow)
          .map((m) => TEST_TYPE_VALUE[m.value]);
        if (testsTypes.length === 1) {
          obj.tests = testsTypes[0];
        }
        if (testsTypes.length > 1) {
          obj.tests = "Multi";
        }
      }
      if (!obj.qaDone && isTodaySchedule && obj.dailyTask && obj.dailyTask.length > 0) {
        obj.qaDone = "X";
      } else if (obj.qaDone && obj.manualCheckIn) {
        obj.qaDone = "M";
      } else if (obj.qaDone && isTodaySchedule) {
        obj.qaDone = "1";
      } else {
        obj.qaDone = "0";
      }
      if (!obj.testDone && isTodaySchedule && isTestingSchedule) {
        obj.testDone = "X";
      } else if (obj.testDone && isTodaySchedule) {
        obj.testDone = "1";
      } else {
        obj.testDone = "0";
      }
      obj.scheduleName = "";
      if (obj.scheduleLinked && obj.scheduleLinked.length > 0) {
        const filVal = schedules.filter((s) => obj.scheduleLinked.indexOf(s.id) !== -1);
        const questionOpt = filVal.find((f) => f.scheduleCategory === SCHEDULE_CATEGORY.QUESTIONS);
        const testOpt = filVal.find((f) => f.scheduleCategory === SCHEDULE_CATEGORY.TESTING);
        obj.schedulesOptions = {
          questionSch: questionOpt || "",
          testOptSch: testOpt || "",
          questionOpt: questionOpt?.dayOptions || [],
          testOpt: testOpt?.dayOptions || [],
        };
        const sch = filVal.map((m) => m.scheduleName);
        if (sch.length > 0) obj.scheduleName = sch.join(",");
      } else if (obj.scheduleID) {
        const sch = schedules.find((s) => s.id === emp.scheduleID);
        if (sch) {
          obj.scheduleName = sch.scheduleName;
        }
      }
    }

    if (isZoneLevel) {
      let validTest = [];
      const employeeDoneTest = obj.testHistory || [];
      if (program?.testsData || (emp.CustomSchedule && emp.CustomSchedule.length > 0)) {
        let todayTest =
          emp.CustomSchedule?.find((t) => t.date === currentDate) ||
          program?.testsData?.find((t) => t?.date === currentDate);

        if (todayTest && todayTest.testTypes) {
          validTest = todayTest.testTypes?.reduce((arr, obj) => {
            let testDone = employeeDoneTest.find(
              (f) => f.value === obj.value && moment(f.date).format("YYYY-MM-DD") === currentDate
            );
            // console.log({ testDone, obj });
            if (testDone) {
              arr.push({
                id: testDone.id,
                date: moment(testDone.date).format("YYYY-MM-DD"),
                externalTestFile: testDone.externalTestFile,
                sequenceNo: testDone.sequenceNo,
                start: obj.date,
                value: obj.value,
                testNow: false,
                label: TEST_TYPE_VALUE[obj.value],
                isDone: true,
                result: testDone.result || "",
                location: obj.location,
              });
            } else {
              arr.push({
                id: uuidv4(),
                start: obj.date,
                value: obj.value,
                testNow: false,
                label: TEST_TYPE_VALUE[obj.value],
                isDone: false,
                result: "",
                location: obj.location,
              });
            }
            return arr;
          }, []);
        }
      }
      if (obj.onBoardingTesting && obj.onBoardingTesting.length > 0) {
        const testNow = obj.onBoardingTesting.filter((f) => f.testNowDate === currentDate);
        const PrevTest = [
          ...validTest,
          // ...employeeDoneTest.filter(
          //   (f) =>
          //     moment(f.date).format("YYYY-MM-DD") === currentDate &&
          //     validTest.findIndex((s) => f.value === s.value) === -1
          // ),
        ];
        const tests = PrevTest.filter((test) => testNow.findIndex((s) => test.value === s.value) === -1);
        validTest = [...testNow, ...tests];
      }

      if (!isExpectedLevel || (emp.onSet === 1 && isExpectedLevel)) {
        if (validTest.length === 1) {
          obj.tests = TEST_TYPE_VALUE[validTest[0].value];
        }
        if (validTest.length > 1) {
          obj.tests = "Multi";
        }

        obj.testTwo = validTest;

        obj.validTests = [...validTest];
        if (validTest.length > 0) {
          testingGroup = "Test";
        }
        if (validTest.length > 0 && validTest.every((e) => e.isDone)) {
          obj.testDone = "1";
        } else if (validTest.length > 0) {
          obj.testDone = "X";
        } else {
          obj.testDone = "0";
        }

        if (obj.qaDone && obj.manualCheckIn) {
          obj.qaDone = "M";
        } else if (obj.qaDone) {
          obj.qaDone = "1";
        } else if (quesIndex !== -1 && !obj.qaDone) {
          obj.qaDone = "X";
        } else {
          obj.qaDone = "0";
        }

        if (zoneQuesSch && zoneQuesSch.length > 0) {
          obj.qaDates = zoneQuesSch.map((m) => m.date);
          if (quesIndex !== -1) {
            testingGroup = "PSQ";
          }
          if (quesIndex !== -1 && validTest.length > 0) {
            testingGroup = "Q/T";
          }
        }

        if (obj.isSchedule !== STATUS.zoneAssign) {
          obj.isSchedule = STATUS.removeProgram;
        }
      } else {
        obj.qaDone = "0";
        obj.testDone = "0";
      }
    }
    obj.checkIn = obj.checkIn
      ? moment(obj.checkIn).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
        ? "1"
        : "0"
      : "0";

    if (obj.callTime === null) {
      obj.callTime = empDept?.callTime || null;
    }

    let callTimeOver = null;
    if (obj.checkIn === "0" && obj.callTime && empDept && empDept.deptTimeZone) {
      const timeArr = obj.callTime.split(":");
      const empCallTime = moment()
        .utc()
        .add(TIME_ZONE_OFFSET[empDept.deptTimeZone], "minutes")
        .startOf("day")
        .set({ hour: parseInt(timeArr[0], 10), minute: parseInt(timeArr[1], 10) });
      const currentTime = moment().utc().add(TIME_ZONE_OFFSET[empDept.deptTimeZone], "minutes");
      if (currentTime.diff(empCallTime, "minutes") > 30) {
        callTimeOver = empCallTime.format("hh:mm A");
      }
    }

    let quarantineColor = "#d7d4d4";
    let quarantineRemainingDays = 0;
    if (emp.isQuarantine) {
      const startDate = moment(emp.quarantinedStart);
      const diff = moment().diff(startDate, "days");
      if (diff <= emp.quarantineDays) {
        quarantineColor = "maroon";
        quarantineRemainingDays = emp.quarantineDays - diff;
      } else if (diff <= emp.positiveDays) {
        quarantineColor = "blue";
        quarantineRemainingDays = emp.positiveDays - diff;
      }
    }

    return {
      ...obj,
      testingGroup,
      checkInDate,
      quarantineColor,
      quarantineRemainingDays,
      callTimeOver,
      questionScheduleID: program?.scheduleID,
    };
  });
};

export const setCharAt = (str, index, chr) => {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
};

export const getValidName = (name) => {
  if (!name) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};

export const isValidIdNum = (id) => {
  if (!id) return false;
  if (new RegExp(/^(?!.*([a-zA-Z0-9])\1{4})[a-zA-Z0-9]{5,16}$/).test(id)) {
    return true;
  } else return false;
};

export const isValidReminder = (val) => {
  if (!val) return false;
  if (new RegExp(/^[0-9]{1,3}[h|m]$/).test(val)) {
    return true;
  } else return false;
};

export const isValidPhoneWithCode = (phone) => {
  if (phone) return isPossiblePhoneNumber(phone);
  return false;
};

export const isValidZipCode = (code, zip) => {
  if (!zip) return false;
  const validate = postalCodes.validate(`${code}`, `${zip}`);
  console.log("zipValid", validate, code, zip);
  return typeof validate !== "string" ? validate : false;
};

export const getPhoneNo = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }

    const phone = formatPhoneNumberIntl(phone_number);
    const phoneArr = phone.split(" ");
    const countryCode = phoneArr[0];
    phoneArr.splice(0, 1);
    const phoneNo = phoneArr.join("");
    return [countryCode, phoneNo, phone];
  } catch (err) {
    console.log("Error", err);
  }
  return ["", phone_number_value, phone_number_value];
};

export const getDaysArray = (startDate, endDate) => {
  const now = moment(startDate).startOf("day").clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("YYYY-MM-DD"));
    now.add(1, "days");
  }
  return dates;
};

export const differenceBtwDays = (startDate, endDate) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  // To calculate the time difference of two dates
  const Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
};

export const formatCallTime = (callTime) => {
  let formattedCallTime = "";

  if (callTime) {
    const timeArr = callTime.split(":");
    formattedCallTime = moment()
      .startOf("day")
      .set({ hour: parseInt(timeArr[0], 10), minute: parseInt(timeArr[1], 10) })
      .format("LT");
  }

  return formattedCallTime;
};

export const formatNewCallTime = (callTime, diff) => {
  let formattedCallTime = "";

  if (callTime) {
    const timeArr = callTime.split(":");
    formattedCallTime = moment()
      .startOf("day")
      .set({ hour: parseInt(timeArr[0], 10), minute: parseInt(timeArr[1], 10) })
      .add(diff, "minutes")
      .format("LT");
  }

  return formattedCallTime;
};

export const formatCallTimeReminder = (callTime, reminder, direction) => {
  let formattedCallTime = "";
  const TIME_UNIT = {
    h: "hours",
    m: "minutes",
  };
  if (!reminder) return "-";

  let reminderValue = reminder.replace(/\D/g, "");
  const reminderUnit = reminder.replace(/\d+/g, "");
  const unit = reminderUnit ? TIME_UNIT[reminderUnit] : "minutes";

  if (!direction || direction === "before") reminderValue = reminderValue * -1;

  if (callTime) {
    const timeArr = callTime.split(":");
    formattedCallTime = moment()
      .startOf("day")
      .set({ hour: parseInt(timeArr[0], 10), minute: parseInt(timeArr[1], 10) })
      .add(reminderValue, unit)
      .format("LT");
  }

  return formattedCallTime;
};

export const uniqueEmpIds = (ids) => {
  const testNowIds = ids.filter((f) => f.includes("%testNow"));
  let empIds = [...ids];
  if (testNowIds.length > 0) {
    testNowIds.forEach((f) => {
      const findID = ids.find((i) => i === f.replace("%testNow", ""));
      if (findID) {
        empIds = empIds.filter((f) => f !== `${findID}%testNow`);
      }
    });
  }
  return empIds;
};

export const convertNameIntoParts = (name) => {
  const [firstName, lastName] = name ? name.split(" ") : "";
  return [firstName, lastName];
};

export const isTooDark = (hexcolor) => {
  if (!hexcolor) return true;
  var r = parseInt(hexcolor.substr(1, 2), 16);
  var g = parseInt(hexcolor.substr(3, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  // Return true if to dark, else return false
  return yiq < 90 ? true : false;
};

export const formatEmployeesForCustomSch = (zone, date, programs) => {
  const zoneData = JSON.parse(zone);
  return zoneData.employees.map((emp) => {
    const program = programs.find((f) => f.id === emp.programID);
    const dateTests = program?.testsData?.find((f) => f.date == date);
    if (emp.CustomSchedule && emp.CustomSchedule.length > 0) {
      const customTest = emp.CustomSchedule.find((f) => f.date === date);
      if (customTest) {
        return {
          ...emp,
          testTypes: customTest.testTypes || [],
          zone: program,
          zoneTests: dateTests?.testTypes || [],
          customTest: true,
        };
      } else {
        return { ...emp, testTypes: dateTests?.testTypes || [], zone: program, zoneTests: dateTests?.testTypes || [] };
      }
    } else {
      return { ...emp, testTypes: dateTests?.testTypes || [], zone: program, zoneTests: dateTests?.testTypes || [] };
    }
  });
};
