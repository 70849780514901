import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Badge from "components/Badge";
import { DROPDOWN_OPT } from "constant";
import DropdownMenu from "components/DropdownMenu";

const CustomSchDetailItems = (props) => {
  const { item, handleCheck, checkboxes, handleRemoveTest, index, handleResetCustomSch, handleReSchedule } = props;
  const handleRemoveTestTypes = (label) => {
    console.log("label", label);
    handleRemoveTest(label, item.id, index);
  };

  const handleDropDownOptions = (test, key) => {
    handleReSchedule(test, key, item.id, index);
  };

  return (
    <tr>
      <td className="ellipsis" style={{ textAlign: "left" }}>
        <Form.Check>
          <Form.Check.Input
            type="checkBox"
            id={item.id}
            onChange={(e) => handleCheck(e, item.id)}
            value={item.id}
            checked={checkboxes.includes(item.id)}
          ></Form.Check.Input>
        </Form.Check>
      </td>
      <td className="tdWidth9 ellipsis">{item.firstName}</td>
      <td className="tdWidth9 ellipsis">{item.lastName}</td>
      <td className="tdWidth12 ellipsis">{item.email}</td>
      <td className="tdWidth12 ellipsis">{item.phoneNumber}</td>
      <td className="tdWidth12 ellipsis">{item.zone?.name || ""}</td>
      {/* <TDNameWithDetail title={item.email} label={item.phoneNumber} /> */}
      <td>
        <div className="d-flex flex-wrap gap-3">
          {item.testTypes?.map((t) => (
            <Badge
              label={t.label}
              handleClose={handleRemoveTestTypes}
              location={t.location}
              backgroundColor={item.zone?.color}
              handleDropDownOptions={handleDropDownOptions}
            />
          ))}
          {/* <div className="badge bg-secondary d-flex align-items-center justify-content-center gap-3 ">
              <span className="fs-6">{zone.testTypes.join(",")}</span>
              <i className="fas fa-times" />
            </div> */}
        </div>
      </td>
      <td>
        {item.customTest && (
          <i
            className="fas fa-undo"
            title="Reset Custom Schedule"
            onClick={() => handleResetCustomSch(item.id, index)}
          />
        )}
      </td>
    </tr>
  );
};

export default CustomSchDetailItems;
