import React, { useState, useContext, useMemo, useRef, useEffect, useCallback } from "react";
import { Button, Card, Row, Col, Overlay, Popover, Table, Modal, DropdownButton, Dropdown } from "react-bootstrap";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { AppContext } from "context/app-context";
import { TEST_TYPE_VALUE, CONFIRMATION_TYPE } from "constant";
import { removeSpaceIns, formatDate } from "utils";
import API from "api";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Navigate } from "react-big-calendar";
import TestingCalendarModal from "components/Modal/TestingCalendarModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import NotesModal from "components/Modal/NotesModal";
import CalendarDetails from "./CalendarDetails";
import workingDayOnIcon from "assets/img/film.png";
import workingDayOffIcon from "assets/img/film-gray.png";
import notesIcon from "assets/img/file-icon.png";
import debounce from "debounce";
import Select from "react-select";
import ExpectedOnSetCalendarDetails from "./ExpectedOnSetCalendarDetails";
import Loader from "components/Loader/Loader";
import CustomScheduleModal from "components/Modal/CustomSchedule/CustomScheduleModal";
import AddCustomScheduleModal from "components/Modal/CustomSchedule/AddCustomScheduleModal";
import CalendarSideBar from "./CalendarSideBar";
import ShowAlertMessage from "components/ShowAlertMessage";

const TestingCalendar = (props) => {
  const { expectedOnSet } = props;
  const appContext = useContext(AppContext);
  const localizer = momentLocalizer(moment);
  const childNode = useRef();
  const [popoverEvent, setPopoverEvent] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [pickDate, setPickDate] = useState("");
  const [blockOutDate, setBlockOutDate] = useState([]);
  const [workingDates, setWorkingDates] = useState([]);
  const [notesData, setNotesData] = useState(null);
  const [testByZone, setTestByZone] = useState(true);
  const [zones, setZones] = useState([]);
  const [quesSchedules, setQuesSchedules] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [calendarView, setCalendarView] = useState(Views.MONTH);
  const [navigatedMonth, setNavigatedMonth] = useState("");
  const [visibleDateRange, setVisibleDateRange] = useState({});
  const [removeNotes, setRemoveNotes] = useState("");
  const [customScheduleData, setCustomScheduleData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [showMessage, setShowMessage] = useState("");

  const monthPrevBtnRef = useRef();
  const monthNextBtnRef = useRef();
  const monthTodayBtnRef = useRef();
  const monthDateBtnRef = useRef();
  const monthViewBtnRef = useRef();
  const weekViewBtnRef = useRef();
  const weekDateBtnRef = useRef();

  const viewOptions = [
    { label: "Monthly", value: Views.MONTH },
    { label: "Weekly", value: Views.WEEK },
  ];

  useEffect(() => {
    if (appContext.company) {
      setBlockOutDate(appContext.company.blockOutDays || []);
      setWorkingDates(appContext.company.workingDays || []);
    }
    getDepartments();
  }, [appContext.company]);

  const getDepartments = async () => {
    const data = await API.getDepartments();
    setDepartments(data);
  };

  // const getYearOptions = () => {
  //   const yearArr = [];
  //   for (let i = -100; i < 100; i++) {
  //     if (i < 0) {
  //       console.log("Testing below years: ", moment(selectedData.date).subtract(12*Math.abs(i), "months").format("YYYY MMM"));
  //     } else {
  //       console.log("Testing below years: ", moment(selectedData.date).add(12*i, "months").format("YYYY MMM"));
  //     }
  //   }
  //   return null;
  // };
  // const yearOptions = getYearOptions();

  const updateBlockOutAPi = useCallback(
    debounce((dates, workingDates) => {
      handleUpdateBlockOutDate(dates, workingDates);
    }, 500),
    []
  );

  const handleUpdateBlockOutDate = async (dates, workingDate) => {
    try {
      await API.updateBlockOut(dates, workingDate);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const AddNotes = async (note, selectedDate, blockOutData, workingDateData) => {
    const dates = blockOutData ? [...blockOutData] : [...blockOutDate];
    const isIndex = dates.findIndex((f) => f.notesDate === selectedDate);
    if (isIndex !== -1) dates.splice(isIndex, 1);
    if (note) dates.push({ note: note, notesDate: selectedDate || notesData.date });

    try {
      workingDateData ? updateBlockOutAPi(dates, workingDateData) : updateBlockOutAPi(dates, workingDates);
      setBlockOutDate(dates);
    } catch (err) {
      console.log("error", err.message);
    }
    setNotesData(null);
  };

  const Event = ({ event }) => {
    return (
      <>
        {testByZone ? (
          <span title={`${event.testTypes.join()}`} className="py-1" ref={childNode}>
            {`${event.title} (${event.employees.length})`}
          </span>
        ) : (
          <span
            title={event.testTypes.length > 1 ? `${event.name}: ${event.testTypes.join()}` : `${event.name}`}
            className="py-1"
            ref={childNode}
          >
            {event.testTypes.length > 1 ? "Multi" : event.testTypes}
          </span>
        )}
      </>
    );
  };
  const MyEventContainerWrapper = (props) => {
    return <div style={{ border: "1px solid red", flexBasis: "14.2857%", maxWidth: "14.2857%" }}></div>;
  };

  const customDateCellWrapper = (props) => {
    const date = moment(props.value).format("YYYY-MM-DD");
    const blocked = blockOutDate.find((f) => f.date === date);
    const specificZones = uniqueZones.filter(({ start }) => start === date);
    const workingDate = workingDates.find((f) => f.date === date) ? true : false;
    if (calendarView !== Views.WEEK) {
      return (
        <div
          className={`${props.children.props.className} p-1`}
          style={{
            backgroundColor: `${blocked ? "#898483" : selectedData.date === date ? "antiquewhite" : ""}`,
            flexBasis: "14.2857%",
            maxWidth: "14.2857%",
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
          onClick={() => setSelectedData({ zones: specificZones, date, workingDate })}
        >
          <div className="date-container">
            <span className="a">{moment(date).format("DD")}</span>
          </div>
          <div className="events d-flex badge-colors">
            {!blocked &&
              specificZones &&
              specificZones.map((event) => (
                <span
                  className="event-dot m-1 cursor-pointer"
                  key={event.id}
                  style={{
                    backgroundColor: `${removeSpaceIns(event?.color || "#000")}`,
                  }}
                  title={
                    !testByZone
                      ? `${event.testTypes.join()}`
                      : event.testTypes.length > 1
                      ? `${event.name}: ${event.testTypes.join()}`
                      : `${event.name}`
                  }
                />
              ))}
          </div>
        </div>
      );
    } else {
      const cell = blockOutDate.find((f) => f.notesDate === date);
      return (
        <div
          className={`${props.children.props.className} p-1`}
          style={{
            backgroundColor: `${blocked ? "#898483" : selectedData.date === date ? "antiquewhite" : ""}`,
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
          onClick={() => setSelectedData({ zones: specificZones, date, workingDate })}
        >
          <div
            style={{
              width: "100%",
              backgroundColor: `${selectedData.date === date ? "antiquewhite" : ""}`,
            }}
            className={`custom-date-header d-flex justify-content-between py-2 px-3 header-left`}
          >
            <span
              className={`${!expectedOnSet && "cursor-pointer"}`}
              title={
                workingDate
                  ? expectedOnSet
                    ? "Working Day"
                    : "Remove Working Day"
                  : expectedOnSet
                  ? "Non-Working Day"
                  : "Add Working Day"
              }
              onClick={(e) => {
                if (!expectedOnSet) {
                  e.stopPropagation();
                  handleWorkingDate(date);
                }
              }}
            >
              {workingDate ? (
                <img className="workingDayIcon" src={workingDayOnIcon} alt="working-day-on-img" />
              ) : (
                <img className="workingDayIcon" src={workingDayOffIcon} alt="working-day-off-img" />
              )}
            </span>
            {cell && (
              <span
                className="cursor-pointer"
                title={cell.note}
                onClick={(e) => {
                  if (!expectedOnSet) {
                    e.stopPropagation();
                    setRemoveNotes(date);
                    AddNotes(null, date);
                  }
                }}
              >
                <img className="notesIcon" src={notesIcon} alt="notes-img" />
              </span>
            )}
          </div>
          <div className="date-container" onClick={() => setSelectedData({ zones: specificZones, date, workingDate })}>
            <span className="a">{moment(date).format("DD")}</span>
          </div>
        </div>
      );
    }
  };

  const onClickCalendarDate = (dateFormat, note) => {
    if (appContext.isReadOnly()) return;

    setNotesData({ date: dateFormat, note });
  };

  const customDateHeader = (props) => {
    const dateFormat = moment(props.date).format("YYYY-MM-DD");
    const cell = blockOutDate.find((f) => f.notesDate === dateFormat);
    const workDay = workingDates.find((f) => f.date === dateFormat);
    const specificZones = uniqueZones.filter(({ start }) => start === dateFormat);
    const currentCellBlocked = blockOutDate.find((f) => f.date === dateFormat);
    return (
      <div
        // style={{ paddingRight: "5px" }}
        // style={{ backgroundColor: `${currentCellBlocked && "#898483"}`, borderBottom: `1px solid ${currentCellBlocked && "#898483"}`, paddingRight: "5px" }}
        style={{
          backgroundColor: `${currentCellBlocked ? "#898483" : selectedData.date === dateFormat && "antiquewhite"}`,
        }}
        className={`custom-date-header d-flex justify-content-between ${expectedOnSet ? "pt-2" : "py-2"} px-3 ${
          currentCellBlocked ? "blocked-date-header" : ""
        } header-left`}
        onClick={() => setSelectedData({ zones: specificZones, date: dateFormat, workingDate: workDay ? true : false })}
      >
        {/* <div className="dh-item header-left"> */}
        <span
          // className="exclamation-mark mx-1"
          className={`${!expectedOnSet && "cursor-pointer"}`}
          title={
            workDay
              ? expectedOnSet
                ? "Working Day"
                : "Remove Working Day"
              : expectedOnSet
              ? "Non-Working Day"
              : "Add Working Day"
          }
          onClick={(e) => {
            if (!expectedOnSet) {
              e.stopPropagation();
              handleWorkingDate(dateFormat);
            }
          }}
        >
          {workDay ? (
            <img className="workingDayIcon" src={workingDayOnIcon} alt="working-day-on-img" />
          ) : (
            <img className="workingDayIcon" src={workingDayOffIcon} alt="working-day-off-img" />
          )}
        </span>
        {cell && (
          <span
            className="cursor-pointer"
            title={cell.note}
            onClick={(e) => {
              if (!expectedOnSet) {
                e.stopPropagation();
                setRemoveNotes(dateFormat);
                AddNotes(null, dateFormat);
              }
            }}
          >
            {/* <i className="fa fa-scroll" /> */}
            <img className="notesIcon" src={notesIcon} alt="notes-img" />
          </span>
        )}
        {/* </div>
         */}
        {/* <div
          className="dh-item header-right mx-1"
          title="Add Notes"
          style={{ cursor: "pointer" }}
          onDoubleClick={() => onClickCalendarDate(dateFormat, cell?.note || "")}
        >
          {props.label}
        </div> */}
      </div>
    );
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      switch (type) {
        case CONFIRMATION_TYPE.UPDATE_SCHEDULE_BLOCK_OUT:
          setZones([]);
          setQuesSchedules([]);
          break;
      }
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.BLOCK_OUT:
        onUpdateBlockOut();
        break;
      case CONFIRMATION_TYPE.UPDATE_SCHEDULE_BLOCK_OUT:
        onUpdateBlockOutandRemoveSch();
        break;
    }
  };

  const onUpdateBlockOutandRemoveSch = async () => {
    const zonesData = [...zones];
    const quesSch = [...quesSchedules];
    let testZones = [];
    let testQuesSch = [];
    if (zonesData.length > 0) {
      testZones = zonesData.map((test) => {
        const tests = test.testsData.filter((f) => f.date !== pickDate);
        const schTets = test.testSchedule.map((sch) => {
          const days = sch.days.filter((f) => f !== pickDate);
          return { ...sch, days };
        });
        return { ...test, testsData: tests, testSchedule: schTets };
      });
    }
    if (quesSch.length > 0) {
      testQuesSch = quesSch.map((ques) => {
        const quesDates = ques.dayOptions.filter((f) => f !== pickDate);
        return { ...ques, dayOptions: quesDates };
      });
    }
    try {
      if (testZones.length > 0) {
        await API.updateZonesTests(testZones);
        appContext.resetPrograms();
        setZones([]);
      }
      if (testQuesSch.length > 0) {
        await API.quesSchedulesUpdate(testQuesSch);
        appContext.resetSchedules();
        setQuesSchedules([]);
      }

      const dates = workingDates.filter((f) => f.date !== pickDate);
      updateBlockOutAPi(blockOutDate, dates);
      setWorkingDates(dates);
    } catch (err) {
      console.log("Error: ", err.message);
    }
  };

  const handleWorkingDate = async (pickDate) => {
    const dates = [...workingDates];
    if (moment().startOf("day").isAfter(pickDate)) return;
    const dateIndex = dates.findIndex((f) => f.date === pickDate);
    if (dateIndex !== -1) {
      const zonesData = appContext.programs.filter(
        (s) => s.testsData && s.testsData.findIndex((f) => f.date === pickDate) !== -1
      );

      if (zonesData.length > 0) {
        setPickDate(pickDate);
        setZones(zonesData);
        setOpenConfirmation({
          isShow: true,
          actionType: CONFIRMATION_TYPE.UPDATE_SCHEDULE_BLOCK_OUT,
          title: `Remove Schedule`,
          message: `Would you like to modify the current testing or pre screening schedule and Working Day off on this date?`,
        });
        return;
      } else {
        dates.splice(dateIndex, 1);
      }
    } else {
      dates.push({ date: pickDate });
    }

    try {
      updateBlockOutAPi(blockOutDate, dates);
      setWorkingDates(dates);
    } catch (err) {
      console.log("err", err.message);
    }
  };

  const onUpdateBlockOut = async () => {
    try {
      const dates = [...blockOutDate];
      const dateIndex = dates.findIndex((f) => f.date === pickDate);
      if (dateIndex !== -1) {
        dates.splice(dateIndex, 1);
      } else {
        dates.push({ date: pickDate });
      }
      updateBlockOutAPi(dates, workingDates);
      appContext.resetCompanyInfo();
      setBlockOutDate(dates);
    } catch (err) {
      console.log("error", err.message);
    }
  };

  const uniqueZones = useMemo(() => {
    return appContext.programs
      .filter((f) => f.testsData)
      .reduce((arrObj, prog) => {
        if (prog) {
          prog.testsData.reduce((arr, obj) => {
            if (obj) {
              obj.testTypes?.forEach((d) => {
                const findVal = arrObj.findIndex((f) => f.name === prog.name && f.start === d.date);
                if (findVal !== -1) {
                  arrObj[findVal].testTypes.push(TEST_TYPE_VALUE[d.value]);
                  arrObj[findVal].zoneDateTests.push(d);
                } else {
                  arrObj.push({
                    ...prog,
                    start: d.date,
                    end: moment(d.date).add(1, "day").format("YYYY-MM-DD"),
                    title: prog.name,
                    testTypes: [TEST_TYPE_VALUE[d.value]],
                    zoneDateTests: [d],
                  });
                }
              });
            }
          }, []);
        }
        return arrObj;
      }, []);
  }, [appContext.employees, appContext.programs]);

  useEffect(() => {
    if (workingDates.length > 0 && uniqueZones.length > 0) {
      const temp = { date: selectedData?.date || null };
      if (Object.keys(selectedData).length === 0) {
        const date = moment().format("YYYY-MM-DD");
        Object.assign(temp, {
          date,
        });
      }
      const zones = uniqueZones.filter(({ start }) => start === temp.date);
      const workingDate = workingDates.find((f) => f.date === temp.date) ? true : false;
      Object.assign(temp, {
        zones,
        workingDate,
      });
      setSelectedData(temp);

      if (
        selectedData.navigator &&
        (moment(temp.date).isBefore(moment(visibleDateRange.start)) ||
          moment(temp.date).isAfter(moment(visibleDateRange.end)))
      ) {
        handleMonthNavigation(Navigate.DATE);
      }
    }
  }, [workingDates, selectedData.date, uniqueZones]);

  const calendarStyle = (date) => {
    if (blockOutDate.find((f) => f.date === moment(date).format("YYYY-MM-DD"))) {
      return {
        style: {
          backgroundColor: "#898483",
          margin: 0,
          padding: 0,
        },
      };
    }
  };

  const handleChangedVisibleDates = (currentView, currentDate) => {
    const temp = {};
    if (currentView === Views.MONTH) {
      const monthStartDate = moment(currentDate).startOf("month").startOf("week");
      const monthEndDate = moment(currentDate).endOf("month").endOf("week");

      if (
        !(monthStartDate.isSame(moment(visibleDateRange.start)) && monthEndDate.isSame(moment(visibleDateRange.end)))
      ) {
        Object.assign(temp, {
          start: monthStartDate.format("YYYY-MM-DD"),
          end: monthEndDate.format("YYYY-MM-DD"),
        });
      }
    } else {
      const weekStartDate = moment(currentDate).startOf("week");
      const weekEndDate = moment(currentDate).endOf("week");

      if (!(weekStartDate.isSame(moment(visibleDateRange.start)) && weekEndDate.isSame(moment(visibleDateRange.end)))) {
        Object.assign(temp, {
          start: weekStartDate.format("YYYY-MM-DD"),
          end: weekEndDate.format("YYYY-MM-DD"),
        });
      }
    }

    if (JSON.stringify(temp) !== JSON.stringify(visibleDateRange)) {
      setVisibleDateRange(temp);
    }
  };

  const customToolbar = (e) => {
    handleChangedVisibleDates(e.view, e.date);
    setNavigatedMonth(e.label);
    return (
      <div className="custom-toolbar d-flex justify-content-between px-4">
        <h4 style={{ fontSize: "18px" }} className="fw-bold">
          Testing Calendar
        </h4>
        <span ref={monthTodayBtnRef} style={{ display: "none" }} onClick={() => e.onNavigate(Navigate.TODAY)} />
        <span ref={monthPrevBtnRef} style={{ display: "none" }} onClick={() => e.onNavigate(Navigate.PREVIOUS)} />
        <span ref={monthNextBtnRef} style={{ display: "none" }} onClick={() => e.onNavigate(Navigate.NEXT)} />
        <span
          ref={monthDateBtnRef}
          style={{ display: "none" }}
          onClick={() => e.onNavigate(Navigate.DATE, selectedData.date)}
        />
        <span
          ref={weekDateBtnRef}
          style={{ display: "none" }}
          onClick={() => e.onNavigate(Navigate.DATE, selectedData.date)}
        />

        <span ref={monthViewBtnRef} style={{ display: "none" }} onClick={() => e.onView(Views.MONTH)} />
        <span ref={weekViewBtnRef} style={{ display: "none" }} onClick={() => e.onView(Views.WEEK)} />

        <h4
          className="cursor-pointer fw-bold"
          style={{ color: "firebrick", fontSize: "18px" }}
          onClick={() => setTestByZone(!testByZone)}
        >
          {testByZone ? "View By Test Type" : "View By Zone"}
        </h4>
      </div>
    );
  };

  const handleDoubleClickEvent = (event) => {};

  const updateCustomSchedule = async (employees, date) => {
    setCustomScheduleData(null);
    try {
      setLoading(true);
      await API.updateCustomSchedule(employees, date);
      appContext.showSuccessMessage("add Custom Schedule");
      appContext.resetEmployees();
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
      console.log("err", err.message);
    }
  };

  const handleMonthNavigation = (direction, date) => {
    if (direction === Navigate.PREVIOUS) {
      monthPrevBtnRef.current.click();
    } else if (direction === Navigate.NEXT) {
      monthNextBtnRef.current.click();
    } else if (direction === Navigate.TODAY) {
      monthTodayBtnRef.current.click();
    } else if (date && direction === Navigate.DATE) {
      weekDateBtnRef.current.click();
    } else if (direction === Navigate.DATE) {
      monthDateBtnRef.current.click();
    }
  };

  const handleViewNavigation = (view) => {
    if (view === Views.MONTH) {
      setCalendarView(Views.MONTH);
      monthViewBtnRef.current.click();
    } else if ((view = Views.WEEK)) {
      setCalendarView(Views.WEEK);
      weekViewBtnRef.current.click();
      handleMonthNavigation(Navigate.DATE, visibleDateRange.start);
    }
  };

  const customWeekSlot = (e) => {
    const dateFormat = moment(e.value).format("YYYY-MM-DD");
    const specificZones = uniqueZones.filter(({ start }) => start === dateFormat);
    const workingDate = workingDates.find((f) => f.date === dateFormat) ? true : false;
    return (
      <div
        className={`${e.children.props.className} events d-flex badge-colors`}
        style={{ backgroundColor: `${selectedData.date === dateFormat ? "antiquewhite" : ""}` }}
        onClick={() => setSelectedData({ zones: specificZones, date: dateFormat, workingDate })}
      >
        {specificZones &&
          specificZones.map((event) => (
            <span
              className="event-dot m-1 cursor-pointer"
              key={event.id}
              style={{
                backgroundColor: `${removeSpaceIns(event?.color || "#000")}`,
              }}
              title={
                !testByZone
                  ? `${event.testTypes.join()}`
                  : event.testTypes.length > 1
                  ? `${event.name}: ${event.testTypes.join()}`
                  : `${event.name}`
              }
            />
          ))}
      </div>
    );
  };

  const handleSelectEvent = (event) => {};
  return (
    <div className="container-fluid">
      <Row>
        <Col>
          <Card className="strpied-tabled-with-hover">
            <Card.Header className="d-flex justify-content-between">
              <div className="d-flex justify-content-between" style={{ width: "80%" }}>
                <div className="a">
                  <span
                    className="fw-bold cursor-pointer mx-2"
                    style={{ fontSize: "x-large" }}
                    onClick={() => {
                      handleMonthNavigation(Navigate.TODAY);
                    }}
                  >
                    Today
                  </span>
                  <i
                    className="fas fa-angle-left arrowKeys mx-1"
                    onClick={() => handleMonthNavigation(Navigate.PREVIOUS)}
                  />
                  <i
                    className="fas fa-angle-right arrowKeys mx-1"
                    onClick={() => handleMonthNavigation(Navigate.NEXT)}
                  />
                </div>
                <Select
                  options={viewOptions}
                  blurInputOnSelect={true}
                  defaultValue={viewOptions.find((opt) => opt.value === Views.MONTH)}
                  menuPlacement="auto"
                  className="searchFilterButton cursor-pointer"
                  onChange={(e) => handleViewNavigation(e.value)}
                />
              </div>
              {/* <div className="d-flex" style={{ width: '420px' }}> */}
              <div className="d-flex" style={{ width: "20%" }}>
                <span className="m-auto" style={{ fontSize: "larger", fontWeight: "bold", color: "gray" }}>
                  {navigatedMonth}
                </span>
                {/* <Select
                  options={navigatedMonth}
                  blurInputOnSelect={true}
                  defaultValue={navigatedMonth}
                  menuPlacement="auto"
                  className="searchFilterButton cursor-pointer"
                  // onChange={(e) => handleViewNavigation(e.value)}
                /> */}
              </div>
            </Card.Header>
            <Card.Body style={{ padding: "15px 0" }}>
              <div className="testingCalendarParentDiv">
                <Calendar
                  // events={uniqueZones}
                  className={`testing-calendar ${expectedOnSet ? "eos-testing-calendar" : ""}`}
                  startAccessor="start"
                  endAccessor="end"
                  defaultDate={moment().toDate()}
                  defaultView={Views.MONTH}
                  localizer={localizer}
                  views={[Views.MONTH, Views.WEEK]}
                  // dayPropGetter={calendarStyle}
                  // selectable
                  onSelectEvent={handleSelectEvent}
                  // onNavigate={(focusDate, flipUnit, prevOrNext) => handlePrevOrNext(focusDate, flipUnit, prevOrNext)}
                  // popup={true}
                  showAllEvents={true}
                  // showMultiDayTimes
                  components={{
                    // event: Event,
                    dateHeader: customDateHeader,
                    // eventContainerWrapper: MyEventContainerWrapper,
                    // dateCellWrapper: calendarView !== Views.WEEK ? customDateCellWrapper : undefined,
                    dateCellWrapper: customDateCellWrapper,
                    toolbar: customToolbar,
                    timeSlotWrapper: customWeekSlot,
                  }}
                  eventPropGetter={(event, start, end, isSelected) => ({
                    event,
                    start,
                    end,
                    isSelected,
                    // style: { backgroundColor: `${removeSpaceIns(event?.color || "#000")}`, width: "fit-content" },
                  })}
                  // slotPropGetter={(d) => ({d, style: { backgroundColor: 'red' }})}
                  step={720}
                  timeslots={1}
                  hideTimeIndicator={true}
                  formats={{
                    // weekdayFormat: "dddd",   //Month view header format
                    dayFormat: "ddd", //Week view header format
                    //   eventTimeRangeFormat: (e) => console.log(e)
                  }}
                  // onShowMore={(event, date) => console.log("onShowMore", event, date)}
                  dayLayoutAlgorithm={"no-overlap"}
                  onDoubleClickEvent={handleDoubleClickEvent}
                />

                {expectedOnSet ? (
                  <ExpectedOnSetCalendarDetails
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setCustomScheduleData={setCustomScheduleData}
                    customScheduleData={customScheduleData}
                    childNode={childNode}
                    departments={departments}
                    eosModal
                  />
                ) : (
                  <CalendarSideBar
                    expectedOnSet={expectedOnSet}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    testByZone={testByZone}
                    setCustomScheduleData={setCustomScheduleData}
                    childNode={childNode}
                    handleAddNotes={AddNotes}
                    handleWorkingDate={handleWorkingDate}
                    blockOutDate={blockOutDate}
                    workingDates={workingDates}
                    removeNotes={removeNotes}
                    appContext={appContext}
                    setRemoveNotes={setRemoveNotes}
                    setShowMessage={setShowMessage}
                    departments={departments}
                    customScheduleData={customScheduleData}
                  />
                )}
              </div>
              {popoverEvent && popoverEvent.employees.length > 0 && (
                <TestingCalendarModal data={popoverEvent} handleClose={() => setPopoverEvent(null)} />
              )}
              {openConfirmation.isShow && (
                <ConfirmationModal
                  show={openConfirmation.isShow}
                  actionType={openConfirmation.actionType}
                  title={openConfirmation.title}
                  message={openConfirmation.message}
                  handleConfirm={onHandleConfirmation}
                  closeBtn
                />
              )}
              {loading && <Loader />}

              {/* This Component is used for custom zone scheduling  */}
              {customScheduleData && (
                <CustomScheduleModal
                  zone={{ ...customScheduleData }}
                  handleClose={() => {
                    setCustomScheduleData(null);
                    appContext.resetEmployees();
                  }}
                  handleSave={updateCustomSchedule}
                  expectedOnSet={customScheduleData.expectedOnSet}
                  empsToUpdate={customScheduleData.employees}
                  appContext={appContext}
                  cssClass={"eos-employeeModal"}
                />
              )}

              {showMessage && <ShowAlertMessage message={showMessage} handleClose={() => setShowMessage("")} info />}
              {notesData && (
                <NotesModal
                  handleSave={AddNotes}
                  handleClose={() => setNotesData(null)}
                  data={notesData}
                  testingCalendar
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TestingCalendar;
