import React from "react";
import { formatNumber } from "utils";

const GroupLabelTab = (props) => {
  const { groups, labelConcat, selectedGroup, setSelectedGroup, cssClass, eosModal, filterLabel } = props;
  return (
    <div className={`grouping-buttons d-block d-sm-flex ${cssClass || ""}`}>
      {groups.map((group) => (
        <div
          key={group.label}
          title={group.title || group.label}
          className="filter-groups mx-3 mb-2"
          style={{ minWidth: filterLabel && "130px" }}
          onClick={() => setSelectedGroup(group)}
        >
          <span
            className="filter-group-labels ellipsis"
            style={{
              color: `${selectedGroup.value === group.value ? "var(--primary-btn-color)" : "initial"}`,
            }}
          >
            {group.label}
            {eosModal ? (
              <span className="labelConcat">
                {group.value === "eos"
                  ? `(${formatNumber(labelConcat.eosEmps.length)})`
                  : `(${formatNumber(labelConcat.nonEosEmps.length)})`}
              </span>
            ) : (
              labelConcat
            )}
          </span>
          {selectedGroup.value === group.value && <span className="active-group-identifier" />}
        </div>
      ))}
    </div>
  );
};

export default GroupLabelTab;
