/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useState, useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { withAuthenticator } from "@aws-amplify/ui-react";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import API from "api";
import { AppContext } from "../context/app-context";
import labRoutes from "labRoutes.js";
import adminRoutes from "adminRoutes";
import subAdminRoutes from "subAdminRoutes";
import employeeRoutes from "employeeRoutes";
import zoneLevelRoutes from "zoneLevelRoutes";
import siteRoutes from "siteRoutes";
import sidebarImage from "assets/img/sidebar-3.jpg";
import { Hub } from "aws-amplify";
import { debounce } from "debounce";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { formatTest, medFlowInLocalStorage, userCompanyID, saveMatrixDatesInLC, loggedInUser } from "utils";

import { Auth } from "aws-amplify";
import { useIdleTimer } from "react-idle-timer";
import IdleModal from "components/IdleModal";
import { useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { Storage } from "aws-amplify";
import { AMAZON_CLIENT_RESULT, RESULT_PDF, TEST_TYPE_VALUE } from "../constant";
import SweetAlert from "react-bootstrap-sweetalert";
import PDF from "components/PDF";
import ReactPDF from "@react-pdf/renderer";
import Loader from "components/Loader/Loader";
import AnalyticalDashbaord from "views/AnalyticalDashboard.js";
import awsconfig from "../aws-exports";
import { DataStore, syncExpression } from "@aws-amplify/datastore";
import {
  Test,
  Employee,
  Question,
  Schedule,
  Department,
  Locations,
  Regions,
  Programs,
  BlockOutDays,
  EmployeeDailyLog,
  GeneralCallTime,
  AppSetting,
  EmployeeQuestion,
} from "../models";
import Employees from "views/Employees";
import moment from "moment";
import { saveSubsUpdateInLC } from "utils";
import ShowAlertMessage from "components/ShowAlertMessage";

function Admin() {
  const timeout = 1800000;
  const [showIdleModal, setShowIdleModal] = useState(false);
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState(+new Date());
  const [lastEvent, setLastEvent] = useState("Events Emitted on Leader");
  const [leader, setLeader] = useState(true);
  const [timedOut, setTimedOut] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [matrixData, setMatrixData] = useState([]);

  let history = useHistory();

  const handleCloseModal = () => {
    setShowIdleModal(false);
  };

  const handleLogOut = async () => {
    setShowIdleModal(false);
    try {
      await DataStore.clear();
      await Auth.signOut();
      medFlowInLocalStorage.clear();
      userCompanyID.clear();
      handlePause();
      history.push("/login");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };

  const handleOnIdle = (event) => {
    setShowIdleModal(true);
  };

  const handleOnActive = (event) => {
    setTimedOut(false);
  };

  const handleOnAction = (event) => {
    setTimedOut(false);
  };

  const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();
  const handleStart = () => start();

  const {
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime,
    getTotalIdleTime,
    getLastIdleTime,
    isIdle,
    pause,
    resume,
    start,
    isLeader,
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: false,
    },
  });

  // useEffect(() => {
  //   setRemaining(getRemainingTime());
  //   setLastActive(getLastActiveTime());
  //   setElapsed(getElapsedTime());
  //   setInterval(() => {
  //     setRemaining(getRemainingTime());
  //     setLastActive(getLastActiveTime());
  //     setElapsed(getElapsedTime());
  //     setLeader(isLeader());
  //   }, 1000);
  // }, []);

  useEffect(() => {
    if (showIdleModal) {
      const timeout = setTimeout(() => {
        if (showIdleModal) {
          handleLogOut();
        }
      }, 300000); // TODO: - This time is the extra time you want to see how long they're idle for until they get logged out
      return () => clearTimeout(timeout);
    }
  }, [showIdleModal]);

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [userRoutes, setUserRoutes] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [schedules, setSchedules] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [testFilter, setTestFilter] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [locations, setLocations] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [regions, setRegions] = useState([]);
  const [company, setCompany] = useState(null);
  const [proxyContacts, setProxyContacts] = useState([]);
  const [successMessageText, setSuccessMessageText] = useState("");
  const [errorMessageText, setErrorMessageText] = useState("");
  const [infoMessageText, setInfoMessageText] = useState("");
  const [settings, setSettings] = useState({});
  const [date, setDate] = useState(saveMatrixDatesInLC.get());

  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.disabled) return null;
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} render={(props) => <prop.component {...props} />} key={key} />;
      } else {
        return null;
      }
    });
  };

  const fetchUserGroups = async () => {
    const admins = await API.fetchAllUsersInGroup("Admins");
    const SecurityStaffs = await API.fetchAllUsersInGroup("SecurityStaffs");
    return Promise.all([admins, SecurityStaffs]).then((values) => [admins, SecurityStaffs]);
  };

  const fetchAdmins = async () => {
    const admins = await API.fetchUsersInGroup("Admins");
    return admins;
  };

  const onSubscriptionTrigger = debounce((msg) => refreshDataOnSubscribe(msg), 6000);
  const onZoneSubscriptionTrigger = debounce(() => refereshZoneDataOnSubscribe(), 6000);
  const onSchSubscriptionTrigger = debounce(() => refereshSchDataOnSubscribe(), 6000);
  const onWorkingDaysSubscriptionTrigger = debounce(() => refereshWorkingDataOnSubscribe(), 3000);

  const refreshDataOnSubscribe = (msg) => {
    if (msg && msg.element.companyID !== userCompanyID.get()) return;
    if (window.location.href.includes("tcmatrix")) {
      updateEmployee(msg);
    } else {
      fetchEmployees();
      saveSubsUpdateInLC.clear();
    }
  };
  const refereshZoneDataOnSubscribe = () => {
    console.log("ZONE_REFRESH_LIST");
    fetchPrograms();
  };
  const refereshSchDataOnSubscribe = () => {
    console.log("SCH_REFRESH_LIST");
    fetchSchedules();
  };

  const refereshWorkingDataOnSubscribe = () => {
    console.log("REFRESH_LIST");
    getCompnayInfo();
  };
  const [user, setUser] = useState();

  // useEffect(()=>{
  //   DataStore.stop();
  // },[])
  useEffect(() => {
    let subscription = null;
    let zoneSubscription = null;
    let schSubscription = null;
    let workingDaysSubscription = null;
    const getUSer = async () => {
      const user = await API.getCurrentUser();
      if (user) {
        const emp = loggedInUser.get();
        if (user.isAdmin() || user.isLab() || user.isSite() || user.isEmployee()) {
          let syncExpressionsByUserType = [];
          if (user.companyID && user.isAdmin()) {
            syncExpressionsByUserType = [
              syncExpression(Test, () => {
                return (test) => test.companyID("eq", user.companyID);
              }),
              syncExpression(Employee, () => {
                return (t) => t.or((test) => [test.companyID("eq", user.companyID), test.companyID("eq", "A")]);
              }),
              // syncExpression(Employee, () => {
              //   return (t) => t.or((test) => test.companyID("eq", user.companyID).isNew("eq", true));
              // }),
              syncExpression(Schedule, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
              syncExpression(Locations, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
              syncExpression(Department, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
              syncExpression(Regions, () => {
                return (emp) => emp.companyID("eq", user.companyID);
              }),
            ];
          } else if (user.isEmployee()) {
            syncExpressionsByUserType.push(
              syncExpression(Employee, () => {
                return (test) => test.schrID("eq", emp.schrID);
              })
            );
          }

          syncExpressionsByUserType.push(
            syncExpression(EmployeeDailyLog, () => {
              return (test) => test.companyID("eq", "1234");
            })
          );
          syncExpressionsByUserType.push(
            syncExpression(Programs, () => {
              return (emp) => emp.companyID("eq", user.companyID);
            })
          );

          syncExpressionsByUserType.push(
            syncExpression(Question, () => {
              return (emp) => emp.companyID("eq", user.companyID);
            })
          );
          syncExpressionsByUserType.push(
            syncExpression(GeneralCallTime, () => {
              return (emp) => emp.companyID("eq", user.companyID);
            })
          );
          syncExpressionsByUserType.push(
            syncExpression(AppSetting, () => {
              return (emp) => emp.id("eq", user.companyID);
            })
          );
          if (user.isEmployee()) {
            syncExpressionsByUserType.push(
              syncExpression(EmployeeQuestion, () => {
                return (test) => test.companyID("eq", user.companyID);
              })
            );
          } else {
            syncExpressionsByUserType.push(
              syncExpression(EmployeeQuestion, () => {
                return (test) => test.companyID("eq", "123");
              })
            );
          }

          DataStore.configure({
            maxRecordsToSync: 90000,
            syncPageSize: 2000,
            syncExpressions: syncExpressionsByUserType,
          });
          await DataStore.stop();
          await DataStore.start();
          setUser(user);
          handleStart();
        } else {
          setLoading(false);
          setShowAlert(true);
        }
      } else {
        setLoading(false);
        console.log("push to login");
        history.push("/login");
      }
    };
    getUSer().then((res) => {
      try {
        subscription = DataStore.observe(Employee).subscribe(async (msg) => {
          // if (window.location.href.includes("tcmatrix")) {
          //   const data = await saveSubsUpdateInLC.get();
          //   await saveSubsUpdateInLC.save([...data, msg.element.id]);
          // }
          onSubscriptionTrigger(msg);
        });
        zoneSubscription = DataStore.observe(Programs).subscribe((msg) => {
          console.log("message zone subs ", msg);
          onZoneSubscriptionTrigger();
        });
        schSubscription = DataStore.observe(Schedule).subscribe((msg) => {
          console.log("message sch subs ", msg);
          onSchSubscriptionTrigger();
        });
        workingDaysSubscription = DataStore.observe(BlockOutDays).subscribe((msg) => {
          console.log("message working subs ", msg);
          onWorkingDaysSubscriptionTrigger();
        });
      } catch (err) {
        console.log("Error", err);
      }
    });

    return () => {
      if (subscription) subscription.unsubscribe();
      if (zoneSubscription) zoneSubscription.unsubscribe();
      if (schSubscription) schSubscription.unsubscribe();
      if (workingDaysSubscription) workingDaysSubscription.unsubscribe();
    };
  }, []);

  const fetchEmployees = async () => {
    const models = await API.getEmployees();
    setEmployees(models.filter((f) => f.email !== "adminmf@yopmail.com"));
  };

  const updateEmployee = async (emp) => {
    if (!emp) return;
    fetchEmployees();
    fetchTestsDone();
    // const ids = await saveSubsUpdateInLC.get();
    // const models = await API.getEmployeeById(ids);
    // console.log("subs model", models, ids);
    // const prevEmployees = employees.filter((f) => !ids.includes(f.id));
    // if (prevEmployees.length === employees.length - models.length) {
    //   setEmployees([...prevEmployees, ...models]);

    // }
    // saveSubsUpdateInLC.clear();
  };
  const fetchLocations = async () => {
    const models = await API.getLocations();
    setLocations(models);
  };

  const loadMatrixData = async (dateObject) => {
    if (!dateObject) {
      return;
    }
    if (dateObject) {
      const response = await API.getCompanyTestDoneList(
        moment(dateObject.startDate).startOf("day").toISOString(),
        moment(dateObject.endDate).endOf("day").toISOString()
      );

      const isDeletedSequence = response.filter((f) => f.isDeleted && f.sequenceNo).map((m) => m.sequenceNo);
      const filterLogs = response.filter(
        (f) => !f.isDeleted && (!f.sequenceNo || !isDeletedSequence.includes(f.sequenceNo))
      );
      const items = filterLogs.reduce((obj, item) => {
        if (obj[item.schrID]) {
          if (item.sequenceNo) {
            const prevIndex = obj[item.schrID].findIndex((t) => t.sequenceNo === item.sequenceNo);
            if (prevIndex !== -1) {
              const prevList = obj[item.schrID];
              if (prevList[prevIndex].updatedAt < item.updatedAt) {
                prevList.splice(prevIndex, 1);
                prevList.push(item);
                obj[item.schrID] = prevList;
              }
            } else {
              obj[item.schrID].push(item);
            }
          } else {
            obj[item.schrID].push(item);
          }
        } else {
          obj[item.schrID] = [item];
        }
        return obj;
      }, {});
      setMatrixData(items);
    }
  };

  const fetchTestsDone = async (dateObj) => {
    if (dateObj) {
      await loadMatrixData(dateObj);
    } else {
      await loadMatrixData(saveMatrixDatesInLC.get());
    }
  };

  const getNextTestDateAndType = (testsData, zone) => {
    let nextTestDate = "";
    let nextTestType = "";
    const testTypes = [...testsData].sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0));
    const currentDate = moment();
    const todayTest = testTypes.find((t) => t.date === currentDate.format("MM-DD-YYYY") && t.testTypes.length > 0);

    if (todayTest) {
      nextTestDate = currentDate.format("MM-DD-YYYY");
      nextTestType = todayTest.testTypes.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
    } else {
      let ttl = testTypes.length;
      for (let i = 0; i < ttl; i++) {
        if (testTypes[i].testTypes?.length === 0) continue;
        const newDate = testTypes[i].date;

        if (moment(newDate).isSameOrAfter(moment(), "day")) {
          if (!nextTestDate) {
            nextTestDate = moment(newDate).format("MM-DD-YYYY");
            nextTestType = testTypes[i].testTypes.map((t) => TEST_TYPE_VALUE[t.value]).join(",");
          }
          break;
        }
      }
    }
    return [nextTestDate, nextTestType];
  };

  const fetchPrograms = async () => {
    const models = await API.getProgramSettings();
    let items = models.map((t) => {
      const testTypes =
        t.testSchedule?.map((m) => TEST_TYPE_VALUE[m.value]) ||
        t.testValidity?.map((m) => TEST_TYPE_VALUE[m.value]) ||
        [];
      let ttlEmployees = 0;
      let tests = "";
      const [nextTestDate, nextTestType] = getNextTestDateAndType(t.testsData || [], t.name);
      if (testTypes.length === 1) tests = testTypes[0];
      if (testTypes.length > 1) tests = "Multi";
      return { ...t, tests, totalEmployees: ttlEmployees, nextTestDate, nextTestType };
    });
    setPrograms(items);
  };
  const fetchRegions = async () => {
    const models = await API.getRegions();
    setRegions(models);
  };
  const fetchSchedules = async () => {
    const models = await API.getSchedules();
    setSchedules(models);
  };

  const fetchQuestions = async () => {
    const models = await API.getQuestions();
    setQuestions(models);
  };
  const loadSetting = async () => {
    const setting = await API.getCompanySetting();
    setSettings(setting);
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const isZoneLevelCompany = () => (company?.programLevel || "Employee") === "Zone";

  const isReadOnly = () => user["custom:privilege"] === "readOnly";

  const contextSwitch = (role) => {
    if (user?.isAdmin()) {
      return {
        company,
        user,
        users,
        employees,
        schedules,
        questions,
        locations,
        regions,
        programs,
        matrixData,
        proxyContacts,
        isZoneLevelCompany: () => isZoneLevelCompany(),
        isReadOnly: () => isReadOnly(),
        resetCompanyInfo: () => getCompnayInfo(),
        resetProxyContacts: () => getProxyContactsData(),
        resetSchedules: () => fetchSchedules(),
        resetQuestions: () => fetchQuestions(),
        resetEmployees: () => fetchEmployees(),
        resetUsers: () => setUsers(fetchUserGroups()),
        resetMatrixTestDone: (dateObj) => fetchTestsDone(dateObj),
        resetLocations: () => fetchLocations(),
        resetPrograms: () => fetchPrograms(),
        resetRegions: () => fetchRegions(),
        sendEmailToCrew: (tests, lab, emp) => sendResultOnEmail(tests, lab, emp),
        admins,
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
        showInfoMessage: (message) => setInfoMessageText(message),
      };
    }
    if (user?.isEmployee()) {
      return {
        company,
        user,
        users,
        employees,
        questions,
        schedules,
        programs,
        proxyContacts,
        isZoneLevelCompany: () => isZoneLevelCompany(),
        isReadOnly: () => isReadOnly(),
        resetCompanyInfo: () => getCompnayInfo(),
        resetProxyContacts: () => getProxyContactsData(),
        resetEmployees: () => fetchEmployees(),
        resetUsers: () => setUsers(fetchUserGroups()),
        sendEmailToCrew: (tests, lab, emp) => sendResultOnEmail(tests, lab, emp),
        admins,
        testFilter,
        setTestFilter: (filter) => setTestFilter(filter),
        showSuccessMessage: (message) => setSuccessMessageText(message),
        showErrorMessage: (message) => setErrorMessageText(message),
        showInfoMessage: (message) => setInfoMessageText(message),
      };
    }
  };

  const sendResultOnEmail = (tests, lab, emp) => {
    if (tests.length === 0) return;
    setLoading(true);

    try {
      tests.forEach(async (test, index) => {
        const resp = lab;
        const parseTestResultForPDF = (test) => {
          return test.result ? RESULT_PDF[test.result.toLowerCase()] : "-";
        };

        ReactPDF.pdf(<PDF test={test} testsLab={resp} parseTestResultForPDF={parseTestResultForPDF} />)
          .toBlob()
          .then(async (data) => {
            try {
              const fileName = `${test.id}-result.pdf`;
              await Storage.put(fileName, data);
              await API.sendEmailToCrew([{ email: emp ? emp.email : test.email, fileName: `public/${fileName}` }]);
              // await API.updateEmailStatus(test.id);
            } catch (error) {
              console.log("Error");
            }
            if (index === tests.length - 1) {
              setLoading(false);
            }
          });
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const customRoutes = (comp, user) => {
    if (user.phone_number === "+19999999999" || user.phone_number === "+18888888888") {
      return adminRoutes;
    }
    if (comp?.programLevel === "Zone" && !isReadOnly()) {
      return zoneLevelRoutes;
    }
    if (comp?.programLevel === "Zone" && isReadOnly()) {
      return zoneLevelRoutes.filter(
        (f) => f.path !== "/settings" && f.path !== "/testingcalendar" && f.path !== "/users"
      );
    }
    if (isReadOnly()) {
      console.log("readOnly");
      return subAdminRoutes.filter((f) => f.path !== "/settings" && f.path !== "/programs");
    }
    return subAdminRoutes;
  };

  const getCompnayInfo = async () => {
    const comp = await API.getCompanySetting();
    const blockOutDate = await API.getBlockOutDays();
    setCompany({
      ...comp,
      blockOutDays: blockOutDate[0]?.blockOutDays || [],
      workingDays: blockOutDate[0]?.workingDays || [],
    });
  };
  const getProxyContactsData = async () => {
    const contacts = await API.getProxyContactData();
    setProxyContacts(contacts);
  };
  const adminListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        const comp = await API.getCompanySetting();
        // setUsers(fetchUserGroups());
        getCompnayInfo();
        getProxyContactsData();
        fetchEmployees();
        fetchSchedules();
        fetchQuestions();
        fetchRegions();
        fetchLocations();
        fetchPrograms();
        fetchTestsDone();
        loadSetting();
        setUserRoutes(customRoutes(comp, user));
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  const employeeListener = () => {
    const hubListener = Hub.listen("datastore", async (hubData) => {
      const { event } = hubData.payload;
      if (event === "ready") {
        const comp = await API.getCompanySetting();
        // setUsers(fetchUserGroups());
        getCompnayInfo();
        fetchEmployees();
        fetchSchedules();
        fetchQuestions();
        fetchPrograms();
        loadSetting();
        setUserRoutes(employeeRoutes);
        Hub.remove("datastore", hubListener);
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    if (user?.isAdmin()) {
      adminListener();
    } else if (user?.isEmployee()) {
      employeeListener();
    }
  }, [user]);
  return (
    <>
      {!loading ? (
        <div className="wrapper">
          {showAlert && (
            <ShowAlertMessage
              message={"Your account does not have access to the Med Flow HR"}
              handleClose={async () => {
                await Auth.signOut();
                window.location.reload();
              }}
              error
            />
          )}
          {successMessageText && (
            <ShowAlertMessage message={successMessageText} handleClose={() => setSuccessMessageText("")} success />
          )}
          {errorMessageText && (
            <ShowAlertMessage message={errorMessageText} handleClose={() => setErrorMessageText("")} error />
          )}

          {infoMessageText && (
            <ShowAlertMessage message={infoMessageText} handleClose={() => setInfoMessageText("")} info />
          )}
          <Sidebar
            color={color}
            image={""}
            routes={userRoutes}
            user={user}
            testFilter={testFilter}
            setTestFilter={setTestFilter}
          />
          {/* comment back in to enable log out timer!!!!!!! */}
          {/* <IdleTimer
            crossTab={{
              emitOnAllTabs: false,
            }}
            startOnMount={false}
            startManually={true}
          /> */}
          <div className="main-panel" ref={mainPanel}>
            <AdminNavbar routes={userRoutes} role={user?.roles[0]} settings={settings} user={user} />
            <div className="content pb-0">
              <AppContext.Provider value={contextSwitch(user?.roles[0])}>
                <Switch>{getRoutes(userRoutes)}</Switch>
                {/* {window.location.pathname === "/admin" && user?.isAdmin() && <AnalyticalDashbaord users={employees} />} */}
                {user?.isAdmin() && window.location.pathname === "/admin" && <Employees />}
              </AppContext.Provider>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      ) : (
        <Loader />
      )}
      {/* <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      /> */}
      <IdleModal
        showChildModal="showChildModal"
        showIdleModal={showIdleModal}
        animation={true}
        handleLogOut={handleLogOut}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
}

export default Admin;
// export default Admin;
