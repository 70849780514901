import debounce from "debounce";
import moment from "moment";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import { removeSpaceIns } from "utils";

import Select from "react-select";
import { GROUPS } from "constant";
import GroupLabelTab from "components/GroupLabelTab";
import { formatCallTime } from "utils";
import CalendarSideBarHeader from "./Components/CalendarSideBarHeader";
import WorkingDayCalendarSideBar from "./Components/WorkingDayCalendarSideBar";
import CalendarDetails from "./CalendarDetails";
import ExpectedOnSetCalendarDetails from "./ExpectedOnSetCalendarDetails";
import api from "api";

const CalendarSideBar = (props) => {
  const {
    selectedData,
    setSelectedData,
    testByZone,
    childNode,
    handleAddNotes,
    handleWorkingDate,
    blockOutDate,
    workingDates,
    removeNotes,
    setRemoveNotes,
    setCustomScheduleData,
    appContext,
    expectedOnSet,
    setShowMessage,
    customScheduleData,
    departments,
  } = props;

  const [selectedGroup, setSelectedGroup] = useState(GROUPS[0]);
  const [eosComponent, setEosComponent] = useState(false);
  const [customCallTimeData, setCustomCallTimeData] = useState([]);

  useEffect(() => {
    getCustomCallTime();
  }, []);

  const getCustomCallTime = async () => {
    const data = await api.fetchAllCustomCallTime();
    console.log("GeneralCalltime", data);
    setCustomCallTimeData(data);
  };

  useEffect(() => {
    if (!testByZone || !selectedData.workingDate) {
      setSelectedGroup(GROUPS[0]);
    }
  }, [testByZone, selectedData.workingDate]);

  useEffect(() => {
    if (selectedGroup && selectedGroup.value.includes("eos")) {
      setEosComponent(true);
    } else {
      setEosComponent(false);
    }
  }, [selectedGroup]);

  return (
    <>
      <div className="calendar-details eos-calendar-details" style={{ border: "1px solid #ddd", borderRight: "0" }}>
        <CalendarSideBarHeader
          date={selectedData.date}
          defaultCallTime={
            customCallTimeData.find((ct) => ct.gctDate === selectedData.date)?.gctTime ||
            appContext.company?.defaultCallTime
          }
          setSelectedData={setSelectedData}
        />
        <WorkingDayCalendarSideBar
          handleWorkingDate={handleWorkingDate}
          workingDate={selectedData.workingDate}
          date={selectedData.date}
        />
        {testByZone && selectedData.workingDate && (
          <GroupLabelTab
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            groups={GROUPS}
            cssClass="testing-calendar-group  py-2 justify-content-around"
          />
        )}
        {!eosComponent ? (
          <CalendarDetails
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            testByZone={testByZone}
            selectedGroup={selectedGroup}
            setCustomScheduleData={setCustomScheduleData}
            childNode={childNode}
            handleAddNotes={handleAddNotes}
            handleWorkingDate={handleWorkingDate}
            blockOutDate={blockOutDate}
            workingDates={workingDates}
            removeNotes={removeNotes}
            setRemoveNotes={setRemoveNotes}
            setShowMessage={setShowMessage}
          />
        ) : (
          <ExpectedOnSetCalendarDetails
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            setCustomScheduleData={setCustomScheduleData}
            customScheduleData={customScheduleData}
            childNode={childNode}
            selectedGroup={selectedGroup}
            departments={departments}
          />
        )}
      </div>
    </>
  );
};

export default CalendarSideBar;
