import React, { useState } from "react";
import GroupQuestion from "components/Questionnaire/QuestionItem";
import { Button, Modal } from "react-bootstrap";
import { QuestionStatus } from "constant";
import ErrorMessage from "components/Message/ErrorMessage";

const QuestionnaireModal = ({ item, handleClose, handleSubmit }) => {
  const [newGroup, setNewGroup] = useState(JSON.parse(item));
  const [error, setError] = useState("");
  const handleQuestion = (e, id) => {
    const questionData = newGroup.question.question;
    const index = questionData.findIndex((i) => i.id === id);
    if (index !== -1) {
      questionData[index].name = e.target.value;
    }
    setNewGroup({ ...newGroup, question: { question: questionData } });
  };

  const handleAnswer = (e, id) => {
    const questionData = newGroup.question.question;
    const index = questionData.findIndex((i) => i.id === id);
    if (index !== -1) {
      questionData[index].answer = e.target.value;
    }
    setNewGroup({ ...newGroup, question: { question: questionData } });
  };
  const handleDeleteQuestion = (id) => {
    const questionData = newGroup.question.question;
    const index = questionData.findIndex((i) => i.id === id);

    if (index !== -1) {
      questionData.splice(index, 1);
    }
    const newObj = { ...newGroup, question: { question: [...questionData] } };
    console.log("New Object", newObj);
    setNewGroup({ ...newGroup, question: { question: [...questionData] } });
  };
  console.log("New Group", newGroup);
  const addQuestion = () => {
    const data = newGroup.question.question;
    data.push({
      id: (Math.random() * new Date()) % 10,
      name: "",
      answer: "",
      status: QuestionStatus.new,
    });
    setNewGroup({ ...newGroup, question: { question: data } });
  };

  const handleCloseModal = () => {
    const { question } = newGroup;
    if (
      ((newGroup.groupName || question.question.some((f) => f.name)) && item !== JSON.stringify(newGroup)) ||
      (JSON.stringify(JSON.parse(item).question.question) !== JSON.stringify(newGroup.question.question) &&
        newGroup.question.question.some((s) => s.name))
    ) {
      setError("please first save work");
      return;
    } else {
      setNewGroup({
        id: (Math.random() * new Date()) % 10,
        groupName: "",
        question: {
          question: [
            {
              id: (Math.random() * new Date()) % 10,
              name: "",
              status: QuestionStatus.new,
            },
          ],
        },
      });
      handleClose();
    }
  };

  const handleButton = () => {
    let isField = false;
    let isAnswer = 0;
    let value = 0;
    newGroup.question.question.map((i) => {
      console.log(i);
      if (i.name.length <= 0) isField = true;
      if (i.answer?.length > 0) isAnswer++;
      value++;
    });
    if (newGroup.groupName.length <= 0 || isField || isAnswer !== value) {
      setError("Please fill all fields");
      return;
    }
    console.log("new group", newGroup);
    handleSubmit(newGroup);
  };

  return (
    <Modal show animation={true} onHide={() => handleCloseModal()} centered size={"xl"} keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${JSON.parse(item).isNew ? "Create" : "Edit"} Pre-Screening Questionnaire`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0">
        <div className="row">
          <div className="col-12 col-sm-9">
            <div className="createClientsWrapper">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders mt-0">Group Name:</label>
                <input
                  // style={{ maxWidth: "80%", width: "100%", flexBasis: "unset" }}
                  id="groupname"
                  className="modalInput w-100"
                  placeholder="Group Name"
                  value={newGroup.groupName}
                  onChange={(e) => setNewGroup({ ...newGroup, groupName: e.target.value.replace(/  +/g, " ") })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-9">
              <h5 className="mt-0 fw-bold">Questions:</h5>
            </div>
            <div className="col-md-3">
              <h5 className="mt-0 fw-bold">Expected Results:</h5>
            </div>
          </div>
        </div>
        <div className="">
          <div
            style={{
              maxHeight: "380px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {newGroup.question &&
              newGroup.question.question.map((item, index) => {
                return (
                  <GroupQuestion
                    listLength={newGroup.question.question.length}
                    key={item.id}
                    index={index}
                    item={item}
                    handleQuestion={handleQuestion}
                    handleAnswer={handleAnswer}
                    handleDeleteQuestion={handleDeleteQuestion}
                    addQuestion={addQuestion}
                  />
                );
              })}
          </div>
        </div>
      </Modal.Body>
      <div className="px-4" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer>
        <Button className="modalButtons headerButton btn-fill" variant="primary" onClick={() => handleCloseModal()}>
          Cancel
        </Button>
        <Button className="modalButtons headerButton btn-fill" variant="secondary" onClick={() => handleButton()}>
          {`${JSON.parse(item).isNew ? "Create Group" : "Update"}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuestionnaireModal;
