import React, { useState, useContext, useRef, useMemo } from "react";
import {
  Button,
  Card,
  Table,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
  Overlay,
  Popover,
  Modal,
  Container,
} from "react-bootstrap";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "api";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { AppContext } from "../context/app-context";
import SweetAlert from "react-bootstrap-sweetalert";
// import Filter from "../components/Filter";
import FilterPopOver from "../components/FilterPopOver";
import HeaderItem from "components/Table/HeaderItem";
import EmployeeModal from "components/Employee/EmployeeModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import AssignGroupModal from "components/Modal/AssignGroupModal";
import ExportToExcel from "components/ExportToExcel";
import StartProgramModal from "components/Modal/StartProgramModal";
import {
  EmptyUserObj,
  PAGE_LIMIT,
  GROUP_TYPES,
  EMPLOYEE_HEADERS,
  CONFIRMATION_TYPE,
  STATUS,
  NOTIFICATION_FROM,
  SEND_QR_CODE_VIA,
} from "../constant";
import {
  formatDOB,
  setPhoneNo,
  parseBooleanValue,
  formatDateMDYTime,
  isValidPhone,
  validatePhone,
  getPhoneNumber,
  isValidRegion,
  isValidLocation,
  isValidDob,
  isValidEmail,
  getValidGender,
  isValidCallTime,
  capitalizeLetter,
  getVaccinated,
  getValidDep,
  getCallTime,
  formatDateMDY,
  personalizationLocalStorage,
  tdEmail,
  tdPhone,
  tdCheckBox,
  tdCallTime,
  tdProgramStatus,
  tdZoneColor,
  isValidName,
  calculateTdWidth,
  toTitleCase,
  getValidReg,
  sortingFilterInLC,
  changeSort,
  sortList,
  getValidSpaces,
  checkValidity,
  formatEmployeesData,
  formatTimeZone,
  importPhoneNoFormat,
  formatDate,
} from "utils";
import MFPagination from "components/Pagination/MFPagination";
import EmployeeImportModal from "components/Modal/EmployeeImportModal";
import "../../src/tableStyling.css";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import PopoverStick from "components/PopoverStick";
import EditIcon from "../assets/img/edit-icon.png";
import TrashIcon from "../assets/img/trash-icon.png";
import FileSaver from "file-saver";
import { jsonToCSV } from "react-papaparse";
import TestNowModal from "components/Modal/TestNowModal";
import Loader from "components/Loader/Loader";
import ExportSelectedData from "components/ExportSelectedData";
import ProgramsOptionsModal from "components/Modal/ProgramsOptionsModal";
import ZonePopoverStick from "components/ZonePopoverStick";
import ExternalTestProgramModal from "components/Modal/ExternalTestProgramModal";
import EmployeeAuditTrail from "components/Modal/EmployeeAuditTrail";
import ViewportList from "react-viewport-list";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import ZoneTestNowModal from "components/Modal/ZoneTestNowModal";
import NotificationInputModal from "components/Modal/NotificationInputModal";
import moment from "moment";
import { uniqueEmpIds } from "utils";
import FullImageView from "components/Image/FullPageImageView";
import { API, Storage } from "aws-amplify";
import PositiveResultAcknowledgeModal from "components/Modal/PositiveResultAcknowledgeModal";
import ClearedProgramModal from "components/Modal/ClearedProgramModal";
import AssigneDepartmentModal from "components/Modal/AssigneDepartmentModal";
import DateRangeModal from "components/Modal/DateRangeModal";
import QrPrint from "components/QrCard/QrPrint";
import Icon from "components/Icon";
import ExpectedOnSetCalendarModal from "components/Modal/ExpectedOnSetCalendarModal";
import { getDaysArray } from "utils";
import ExpectedOnSetCalendarDetailsModal from "components/Modal/ExpectedOnSetCalendarDetailsModal";
import AssignZoneModal from "components/Modal/AssignZoneModal";
import { t } from "constants/stringConstants";
import CustomScheduleModal from "components/Modal/CustomSchedule/CustomScheduleModal";
import MergeProfileModal from "components/Modal/MergeProfileModal";
import { downloadDataAsCSV } from "utils";
import ShowAlertMessage from "components/ShowAlertMessage";
import { CONFIG } from "utils";
import { formatNumber } from "utils";

const Employees = (props) => {
  const componentRef = useRef();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [newUser, setNewUser] = useState(EmptyUserObj);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [count, setCount] = useState(0);
  const [fullscreen, setFullscreen] = useState(true);
  const [title, setTitle] = useState("");
  const [assignProgram, setAssignProgram] = useState([]);
  const [assignProgramSuccessMsg, setAssignProgramSuccessMsg] = useState("");
  const [openTestNowModal, setOpenTestNowModal] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [programData, setProgramData] = useState(null);
  const [exportManifest, setExportManifest] = useState(false);
  const [showProgramOptions, setShowProgramOptions] = useState(false);
  const [showExternalTest, setShowExternalTest] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [showClearedProgram, setShowClearedProgram] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedEmps, setSelectedEmps] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [dateRangeKey, setDateRangeKey] = useState("");
  const [zoneTestNow, setZoneTestNow] = useState(false);
  const [openDateRangeModal, setOpenDateRangeModal] = useState(false);
  const [openAssignDeprtment, setOpenAssignDepartment] = useState(false);
  const [date, setDate] = useState([null]);
  const [qrPrint, setQrPrint] = useState(null);
  const [openExpectedOnSetDetailModal, setOpenExpectedOnSetDetailModal] = useState(false);
  const [customSchData, setCustomSchData] = useState(null);
  const [showMergeProfileModal, setShowMergeProfileModal] = useState(false);

  let program = appContext.isZoneLevelCompany() ? "Zone" : "Program";
  const searchTerms = [
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Schedule",
    "isVaccinated",
    "Job Title",
    "Department",
    "Call Time",
    "Gender",
    "Region",
    "Office",
    `${program} Name`,
    "Zone Color",
    "SCHR ID",
    "Local #",
    "Screening Type",
    "Type of Test",
    "PSQ",
    "Test",
    "Check In",
    "Updated At",
    `${program} Status`,
    "Drive on Access",
    "Positive Employee",
    CONFIG.expectedOnSet,
    CONFIG.onSetDateRange,
    "Todays Test",
    "Todays Question Schedule",
  ];
  const [driveAccessibility, setDriveAccessibility] = useState(true);
  const [expectedOnSet, setExpectedOnSet] = useState(true);
  const [sendQrCodeVia, setSendQrCodeVia] = useState(SEND_QR_CODE_VIA.EMAIL);
  const [externalTestImage, setExternalTestImage] = useState(null);
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [programMessage, setProgramMessage] = useState("");
  const [openErrModal, setOpenErrModal] = useState(false);
  const [showZoneAssignModal, setShowZoneAssignModal] = useState(false);
  const [openProgram, setOpenProgram] = useState(false);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [personalize, setPersonalize] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [openNotificationModal, setOpenNotificationModal] = useState(null);
  const [openExpectedOnSetModal, setOpenExpectedOnSetModal] = useState(false);
  const headerAlignKeys =
    "location,region,isVaccinated,schrID,gender,updatedAt,jobTitle,tests,departmentName,programName,dob,zoneColor,testingGroup,empTZ,scheduleName,qaDone,testDone,checkIn,isSchedule,qrCode,onSet,driveOnAccess,callTime,localNo";

  const location = useLocation();

  const tdWidth = calculateTdWidth(
    width - (appContext.isZoneLevelCompany() ? 180 : 130),
    personalize.length > 0 ? personalize.filter((p) => p.isCheck).length : 24
  );
  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      if (filterTerm && filterTermName) {
        setFilter({ ...filter, [filterTerm]: filterTermName });
        setTimeout(() => setShowFilter(false), 1);
      }
    }
  }, [location.state]);

  useEffect(() => {
    setLoading(false);
  }, [showAll]);

  const getDepartments = async () => {
    const data = await api.getDepartments();
    setDepartments(data);
  };
  const getEmployeeScheduleDetails = (emp) => {
    const clone = { ...emp };
    if (appContext.schedules && clone.scheduleLinked) {
      for (let i = 0; i < clone.scheduleLinked.length; i++) {
        const schID = clone.scheduleLinked[i];
        const empSchedule = appContext.schedules.find((s) => s.id === schID);
        if (typeof empSchedule !== "undefined") {
          if (empSchedule.scheduleCategory === "Testing") {
            clone.scheduleData = empSchedule.dayOptions;
            clone.endDate = new Date(empSchedule.endDate);
            // emp.scheduleData = empSchedule.dayOptions;
          }
        }
      }
    }

    return clone;
  };

  const uniquePrograms = filteredUsers
    .filter((f) => f.isSchedule === 1)
    .reduce((obj, user) => {
      const userObj = getEmployeeScheduleDetails(user);
      if (obj[user.department]) {
        obj[user.department].push(userObj);
      } else {
        obj[user.department] = [userObj];
      }
      return obj;
    }, {});

  useEffect(() => {
    getDepartments();
    const sortLS = sortingFilterInLC.get();
    if (sortLS.employees && sortLS.employees.sortBy) {
      setSortBy(sortLS.employees.sortBy);
      setSortDescending(sortLS.employees.sortDescending);
    }
    setPersonalize(personalizationLocalStorage.get(appContext.user, 0, appContext.isZoneLevelCompany()));
    setShowAll(personalizationLocalStorage.getShowAll(appContext.user, "employeeAll"));
  }, [appContext.company]);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const filterUsers = (searchTerm, searchInput) => {
    return setFilteredUsers(users.filter((user) => user[searchTerm].includes(searchInput)));
  };

  const handleCustomSch = () => {
    const selectEmps = filteredUsers.filter((f) => checkboxes.includes(f.id));
    setCustomSchData({ date: moment().format("YYYY-MM-DD"), employees: selectEmps });
  };

  const uniqueEmps = async () => {
    const sameEmps = filteredUsers.filter(
      (value, index, self) =>
        index !==
        self.findIndex(
          (t) => t.firstName === value.firstName && t.lastName === value.lastName && t.email === value.email
        )
    );

    await downloadDataAsCSV(sameEmps, "SeverenceEmployees.csv");
  };

  const getDuplicateData = async () => {
    const emps = filteredUsers.filter((f, i, arr) => arr.findIndex((m) => m.phoneNumber === f.phoneNumber) !== i);
    const duplicateData = filteredUsers.filter((f, i) => emps.findIndex((m) => m.phoneNumber === f.phoneNumber) !== -1);
    console.log("duplicateData", duplicateData);
    await downloadDataAsCSV(duplicateData, "SevantDuplicateEmployees.csv");
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects
    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length && !Object.keys(filters[key]).length) {
          return true;
        }
        if (Number(filter[key]) === 0 && key !== "isVaccinated") return obj;
        if (key === "updatedAt") {
          return (
            new Date(obj.updatedAt) > filters[key].startDate._d && new Date(obj.updatedAt) < filters[key].endDate._d
          );
        }
        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "scheduleID") {
          return obj.scheduleID && (obj.scheduleID === filters[key] || obj.scheduleLinked.indexOf(filters[key]) !== -1);
        }
        if (key === "questionScheduleID") {
          return obj.questionScheduleID && obj.questionScheduleID === filters[key];
        }
        if (key === "department") {
          return obj.department && obj.department === filters[key];
        }
        if (key === "programID") {
          return obj.programID && obj.programID === filters[key];
        }
        if (key === "isSchedule") {
          return (obj.isSchedule ? obj.isSchedule : 0) == filters[key];
        }
        if (key === "isVaccinated") {
          return (obj.isVaccinated ? 1 : 0).toString() === filter[key];
        }
        if (key === "qaDone") {
          return obj.qaDone === filter[key];
        }
        if (key === "todaysQS") {
          return obj.qaDone !== "0";
        }
        if (key === "todaysTest") {
          return obj.testDone === "1" || obj.testDone === "X";
        }
        if (key === "testDone") {
          return obj.testDone === filter[key];
        }
        if (key === "empTZ") {
          return formatTimeZone(obj[key]).toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "checkIn") {
          return obj.checkIn === filter[key];
          // return new Date(obj.checkIn) > filters[key].startDate._d && new Date(obj.checkIn) < filters[key].endDate._d;
        }
        if (key === "driveOnAccess") {
          return obj.driveOnAccess === (Number(filter[key]) ? Number(filter[key]) : Number(filter[key]) || null); //driveOnAccess Data is stored in 'Number' format
        }
        if ((key === "programName" || key === "zoneColor") && filters[key] && filters[key].length > 0) {
          return obj[key] && filters[key].indexOf(obj[key]) !== -1;
        }
        if (key === "positiveEmployee") {
          if (filters[key] === "Yes" && obj?.testHistory) {
            return (
              obj?.testHistory.filter((t) => t.result && !t.isAcknowledged && t.result.toLowerCase() === "positive")
                .length > 0
            );
          } else if (filters[key] === "No") {
            return (
              !obj?.testHistory ||
              (obj?.testHistory &&
                obj?.testHistory.filter((t) => t.result && !t.isAcknowledged && t.result.toLowerCase() === "positive")
                  .length === 0)
            );
          }
        }
        if (key === "onSet") {
          return obj.onSet === (Number(filter[key]) ? Number(filter[key]) : Number(filter[key]) || null);
        }
        if (key === "onSetDates") {
          return (
            obj.onSetDates &&
            obj.onSetDates?.dates?.findIndex(
              (d) => new Date(d) > filters[key].startDate._d && new Date(d) < filters[key].endDate._d
            ) !== -1
          );
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const updateAcknowledgeBy = async () => {
    let emps = [];
    emps = users.filter(
      (f) =>
        checkboxes.includes(f.id) &&
        f.testHistory &&
        f.testHistory.filter((t) => t.result && !t.isAcknowledged && t.result.toLowerCase() === "positive").length > 0
    );
    if (emps.length == 0) return;
    try {
      setLoading(true);
      await api.updateEmpAcknowledgeBy(emps, appContext.user.name);
      setCheckboxes([]);
      setAllSelected(false);
      appContext.resetEmployees();
      appContext.showSuccessMessage("Result Successfully Acknowledged");
      setLoading(false);
    } catch (err) {
      console.log("err", err.message);
    }
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    if (filteredUsers.length === filteredList.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    setCheckboxes(filteredList);
    if (e.target.checked && user.scheduleID) {
      setAssignProgram((a) =>
        a.length > 0 ? (a[0].scheduleID === user.scheduleID ? [...a, user] : [...a]) : [...a, user]
      );
    } else {
      setAssignProgram(assignProgram.filter((f) => f.id !== user.id));
    }
  };
  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredUsers.map((t) => t.id) : []);
    const values = val ? filteredUsers.filter((f) => f.isSchedule === 2 && f) : [];
    let assignVal = [];
    if (values.length > 0) {
      const reduceVal = values.reduce((result, value) => {
        if (value.scheduleLinked && value.scheduleLinked.length > 0) {
          result[value.scheduleLinked] = (result[value.scheduleLinked] || 0) + 1;
        }
        return result;
      }, {});
      const maxVal = Object.keys(reduceVal).reduce((a, b) => (reduceVal[a] > reduceVal[b] ? a : b));
      const arrVal = maxVal.split(",");
      assignVal = values.filter((f) => JSON.stringify(f.scheduleLinked) === JSON.stringify(arrVal));
    }
    setAssignProgram(assignVal);
  };

  const createUser = async (user) => {
    try {
      setLoading(true);
      const phoneNo = setPhoneNo(user.phoneNumber);
      let resetLocations = false;
      let resetRegions = false;
      let resetEmployees = true;
      if (user.location) resetLocations = await api.newLocations(user.location, appContext.user);
      if (user.region) resetRegions = await api.newRegions(user.region, appContext.user);

      let logData = [];
      if (user.isNew) {
        const result = await api.newEmployee(user, null, appContext.user);
        appContext.showSuccessMessage("Employee created successfully");
        await appContext.resetEmployees();

        if (result) {
          if (user.isApp) handleCreateSystemUSer(result);
          // logData.push({
          //   schrID: result.id,
          //   auditType: "crewMemberCreated",
          //   action: "Created",
          //   companyID: result.companyID,
          //   userName: appContext.user.name,
          // });

          if (!appContext.isReadOnly() && (!user.programID || !appContext.isZoneLevelCompany())) {
            if (result?.id) setCheckboxes([result.id]);
            console.log("result", result);
            setOpenConfirmation({
              isShow: true,
              actionType: CONFIRMATION_TYPE.ASSIGN_PROGRAM,
              title: `Assign ${program}`,
              message: `Would you like to assign the ${program}?`,
            });
          }
        }
      } else {
        await api.updateEmployee(user, appContext.user);
        if (user.isApp) handleCreateSystemUSer(user);
        if (!user.onAlert) appContext.showSuccessMessage("Employee updated successfully");
        // logData.push({
        //   schrID: user.id,
        //   auditType: "crewMemberUpdated",
        //   action: "Updated",
        //   companyID: user.companyID,
        //   userName: appContext.user.name,
        // });
      }
      // if (logData.length > 0) {
      //   await api.addlogs(logData);
      // }
      // await appContext.resetEmployees();
      if (resetLocations) await appContext.resetLocations();
      if (resetRegions) await appContext.resetRegions();
      setLoading(false);
    } catch (error) {
      console.log("ERROR:User-", error);
      appContext.showErrorMessage(error.message);
      setOpenCreator(true);
    }
  };

  const getGroupTypeUsers = () => {
    const filterCheckData = users.filter((i) => checkboxes.includes(i.id) && i.groupType);
    const findVal = users.filter((i) => checkboxes.includes(i.id) && !i.groupType);
    if (findVal.length > 0 && !assignProgramSuccessMsg) {
      setAssignProgramSuccessMsg({ error: findVal });
    }
    setProgramMessage(
      `Success, you have Started ${program} of ${filterCheckData.length} ${
        filterCheckData.length > 1 ? "employees" : "an employee"
      }`
    );
    return filterCheckData;
  };

  const handleProgram = async (val) => {
    const data = getGroupTypeUsers();
    if (Object.keys(data).length === 0 && !assignProgramSuccessMsg) {
      setShowAlert(true);
      setCheckboxes([]);
      setAssignProgram([]);
      setAllSelected(false);
      setAssignProgramSuccessMsg("");
      return;
    }
    try {
      setLoading(true);
      await api.updateEmployeeProgramState(data, val, null, appContext.user.name);
      // await saveProgramNotStartedIds();
      setCheckboxes([]);
      setAssignProgram([]);
      setAllSelected(false);
      await appContext.resetEmployees();
      if (val === 1) {
        setOpenProgram(true);
      }
    } catch (err) {
      console.log("Error Program", err);
      setLoading(false);
    }
  };
  // const updateProgramID = useMemo(() => {
  //   if (appContext.employees && appContext.programs) {
  //     const dataList = appContext.employees
  //       .filter((f) => f.programName)
  //       .map((t) => {
  //         const prg = appContext.programs.find((f) => f.name === t.programName);
  //         if (prg) {
  //           return { ...t, programID: prg.id };
  //         }
  //       });
  //     return dataList;
  //   }
  // }, [appContext.employees, appContext.programs]);

  // console.log("updateProgramID", updateProgramID);
  // useEffect(async () => {
  //   if (updateProgramID) {
  //     await api.updateProgramIDs(updateProgramID);
  //   }
  // }, [updateProgramID]);

  const updateSubId = async () => {
    filteredUsers
      .filter((f) => !f.subID && f.id == "81315651-67ed-47b8-920b-c76c7ea09901")
      .map(async (emp) => {
        let searchFilter = `phone_number ^=\  "${emp.phoneNumber}\"`;
        const employees = await api.fetchAllUsers(searchFilter);
      });
  };

  const connectedTo = async () => {
    console.log(filteredUsers.filter((f) => f.connectedTo && f.connectedTo.length > 0));
  };

  const saveProgramNotStartedIds = async () => {
    try {
      const filterCheckData = users.filter((i) => checkboxes.includes(i.id) && i.isSchedule !== 1);
      const ids = filterCheckData.map((t) => {
        return {
          firstName: t.firstName,
          lastName: t.lastName,
          phoneNumber: t.phoneNumber,
          email: t.phoneNumber,
        };
      });
      const content = jsonToCSV(ids);
      const fileContent = new Blob([content], { type: "csv" });
      await FileSaver.saveAs(fileContent, "program_not_start_data.csv");
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleAssignProgramandStart = async (obj) => {
    const findVal = users.filter(
      (f) =>
        (checkboxes.includes(f.id) && f.departmentName === "-") ||
        (checkboxes.includes(f.id) &&
          f.departmentName &&
          departments.findIndex((d) => d.id === f.department && d.deptTimeZone) === -1)
    );
    let checkVal = checkboxes;
    if (findVal.length > 0) {
      checkVal = checkboxes.filter((chb) => findVal.findIndex((f) => f.id === chb) === -1);
    }
    const data = users.filter((f) => checkVal.includes(f.id));
    try {
      setLoading(true);
      await api.updateEmployeeProgramState(data, 1, obj, appContext.user.name);
      setProgramMessage(
        `Success, you have Started ${program} of ${checkVal.length} ${
          checkVal.length > 1 ? "employees" : "an employee"
        }`
      );
      setAssignProgramSuccessMsg({
        success: null,
        error: findVal,
      });
      setCheckboxes([]);
      setAllSelected(false);
      setAssignProgram([]);
      appContext.resetEmployees();
      setOpenProgram(true);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };
  const handleAssignGroupSave = async (obj) => {
    // const checkboxez = checkboxes.filter((f)=> users.find((t)=> t.id === f &&  t.isSchedule !== 1))
    // if(checkboxez.length === 0) return
    const findVal = users.filter(
      (f) =>
        (checkboxes.includes(f.id) && f.departmentName === "-") ||
        (checkboxes.includes(f.id) &&
          f.departmentName &&
          departments.findIndex((d) => d.id === f.department && d.deptTimeZone) === -1)
    );
    let checkVal = checkboxes;
    if (findVal.length > 0) {
      checkVal = checkboxes.filter((chb) => findVal.findIndex((f) => f.id === chb) === -1);
    }
    try {
      setLoading(true);
      await api.updateEmployeeGroup(checkVal, obj);
      setAssignProgramSuccessMsg({
        success: `Success, you have assigned ${program} of ${checkVal.length} ${
          checkVal.length > 1 ? "employees" : "employee"
        }`,
        error: findVal,
      });
      setCheckboxes([]);
      setAllSelected(false);
      setAssignProgram([]);
      appContext.resetEmployees();
      setDisplayMessage(true);
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };

  const handleAssignGroupClose = () => {
    setOpenAssignModal(false);
  };
  const handleClose = () => {
    setOpenCreator(false);
    setOpenConfirmation({ isShow: false });
  };
  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.employees = { sortBy, sortDescending, filter, timeFilter };
    sortingFilterInLC.save(pervSortLS);
  };

  const currentUsers = (users) => {
    if (!showAll) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return users.slice(indexOfFirstUser, indexOfLastUser);
    }
    return users;
  };

  let usersToMap = currentUsers(filteredUsers);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(filteredUsers);
  useEffect(() => {
    formatEmployees();
    setLoading(false);
  }, [appContext.employees]);

  useEffect(() => {
    formatEmployees();
  }, [appContext.schedules, departments, appContext.programs, appContext.company]);

  const isResultPositive = (item) =>
    item.testHistory?.filter((t) => t.result && !t.isAcknowledged && t.result.toLowerCase() === "positive").length > 0
      ? true
      : false;

  useEffect(() => {
    formatEmployees();
  }, [appContext.questions]);

  useEffect(() => {
    handleSaveFilter();
    if (filteredUsers.length === 0) return;
    changeSort(sortBy, sortDescending, filteredUsers, setFilteredUsers);
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(sortList(sortBy, sortDescending, nestedFilter(users, filter)));
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    if (checkboxes.length > 0) {
      setDriveAccessibility(filteredUsers.some(({ id, driveOnAccess }) => checkboxes.includes(id) && !driveOnAccess));
      // setExpectedOnSet(filteredUsers.some(({ id, onSet }) => checkboxes.includes(id) && !onSet));
      setExpectedOnSet(
        users.findIndex(
          ({ id, onSetDates }) =>
            checkboxes.includes(id) && onSetDates && (onSetDates?.dates?.length > 0 || onSetDates?.startDate)
        ) === -1
      );
    } else {
      setDriveAccessibility(true);
      setExpectedOnSet(true);
    }
  }, [checkboxes, filteredUsers]);

  const formatEmployees = () => {
    const emps = formatEmployeesData(
      appContext.employees,
      appContext.schedules,
      departments,
      appContext.programs,
      appContext.company
    );
    let empData = [];
    // emps.forEach((f) => {
    //   empData.push(f);
    //   if (f.onBoardingTesting && f.onBoardingTesting.length > 0 && appContext.isZoneLevelCompany()) {
    //     const obj = { ...f };
    //     const validTest = obj.onBoardingTesting.filter((f) => f.testDate === moment().format("YYYY-MM-DD"));

    //     const testStatus = {
    //       1: validTest[0]?.label,
    //       0: "",
    //     };

    //     obj.tests = testStatus[validTest.length] ?? "Multi";

    //     obj.testTwo = validTest.filter((f) => f.location === "On Location");
    //     obj.testOne = validTest.filter((f) => f.location === "At Home");
    //     console.log("validTest", validTest, obj.onBoardingTesting);
    //     if (validTest.length > 0 && validTest.every((e) => e.isDone)) {
    //       obj.testDone = "1";
    //     } else if (validTest.length > 0) {
    //       obj.testDone = "X";
    //     } else {
    //       obj.testDone = "0";
    //     }

    //     empData.push({
    //       ...obj,
    //       id: `${obj.id}%testNow`,
    //       testNow: true,
    //       isSchedule: STATUS.zoneAssign,
    //       validTests: validTest,
    //     });
    //   }
    // });

    setUsers(emps);
    if (sortBy) {
      setFilteredUsers(sortList(sortBy, sortDescending, nestedFilter(emps, filter)));
    } else {
      setFilteredUsers(nestedFilter(emps, filter));
    }
  };

  if (filteredUsers.length > 0) {
    pageNumbers = getPageNumbers(filteredUsers);
  }

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleEditUser = (user) => {
    const obj = { ...user, isNew: false, sendEmail: user.sendEmail || 1, sendSMS: user.sendSMS || 1 };
    setNewUser(obj);
    setOpenCreator(true);
  };

  const onDeleteConfirm = async () => {
    let ttl = checkboxes.length;
    if (userToDelete || ttl > 0) {
      try {
        setLoading(true);
        if (userToDelete) {
          await api.deleteEmployee(userToDelete.id);
        } else if (ttl > 0) {
          let resArr = [];
          for (let i = 0; i < ttl; i++) {
            let res = api.deleteEmployee(checkboxes[i]);
            resArr.push(res);
          }
          await Promise.all(resArr);
          setLoading(false);
        }
        setUserToDelete(null);
        setCheckboxes([]);
        setAssignProgram([]);
        setAllSelected(false);

        appContext.showSuccessMessage("Employee deleted successfully");
        appContext.resetEmployees();
      } catch (error) {
        appContext.showErrorMessage(error.message);
        console.log("Error:-", error.message);
        setLoading(false);
      }
    }
  };

  const handleRemoveGroup = async () => {
    try {
      setLoading(true);
      await api.removeEmployeeGroup(checkboxes, appContext.isZoneLevelCompany(), appContext.user.name);
      setCheckboxes([]);
      setAllSelected(false);
      setAssignProgram([]);
      const successCount = filteredUsers.filter((f) => checkboxes.includes(f.id) && f.programName);
      appContext.showSuccessMessage(
        `${formatNumber(successCount.length)} ${
          successCount.length > 1 ? "employees" : "employee"
        } ${program} removed succesfully`
      );
      appContext.resetEmployees();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleZoneTestNow = (obj) => {
    setZoneTestNow(false);
    setLoading(true);
    appContext.showSuccessMessage(
      `Assign Test Now  ${formatNumber(checkboxes.length)} ${checkboxes.length > 1 ? "Employees" : "Employee"} `
    );
    appContext.resetEmployees();
    setCheckboxes([]);
    setAllSelected(false);
    setLoading(false);
  };
  const handleImport = async (empData) => {
    if (empData.length === 0) return;
    const companyObj = await api.getCompanySetting();
    let allEmployess = companyObj.employeeCounter;
    const programName = await api.getProgramSettings();
    let isNotZoneKeyInImport = true;
    const arr = [];
    const arrdata = [];
    const empList = [...users];
    const newEmpIds = [];
    let logData = [];
    const departmentList = [...departments];
    const locList = [...appContext.locations];
    const regList = [...appContext.regions];
    const ttlLength = empData.length;
    const filterheader = EMPLOYEE_HEADERS.every((c) => Object.keys(empData[0].data).includes(c));
    if (!filterheader) {
      appContext.showErrorMessage("data not matched");
      setOpenImportModal(false);
      return;
    }
    setOpenImportModal(false);
    setLoading(true);

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data, countryCode: "", callTime: data.callTime || null };
      if (!emp.firstName && !emp.lastName && !emp.phoneNumber && !emp.email) continue;
      emp.firstName = getValidDep(emp.firstName || "");
      emp.lastName = getValidDep(emp.lastName || "");
      emp.isVaccinated = getVaccinated(emp.isVaccinated || "");
      emp.isBooster = getVaccinated(emp.isBooster || "");
      emp.jobTitle = getValidSpaces(emp.jobTitle || "");
      emp.zip = getValidSpaces(emp.zip || "");
      emp.sendEmail = emp.sendEmail || 1;
      emp.sendSMS = emp.sendSMS || 1;
      emp.phoneNumber = getPhoneNumber(emp.phoneNumber || "");
      if (emp.location) {
        emp.location = getValidReg(emp.location);
        const locationVal = locList.find((loc) => checkValidity(loc.name) == checkValidity(emp.location));
        if (locationVal) {
          emp.location = locationVal.name;
        } else {
          await api.newLocations(emp.location, appContext.user);
          locList.push({ name: emp.location });
        }
      } else if (locList.length === 1) {
        emp.location = locList[0].name;
      }
      if (emp.region) {
        emp.region = getValidReg(emp.region);
        const regionVal = regList.find((reg) => checkValidity(reg.name) == checkValidity(emp.region));
        if (regionVal) {
          emp.region = regionVal.name;
        } else {
          await api.newRegions(emp.region, appContext.user);
          regList.push({ name: emp.region });
        }
      } else if (regList.length === 1) {
        emp.region = regList[0].name;
      }
      emp.callTime = getCallTime(emp.callTime);
      if (!isValidCallTime(emp.callTime)) {
        emp.callTime = null;
      }

      if (emp.department) {
        if (!emp.callTime) {
          emp.callTime = appContext.company.defaultCallTime;
        }
        if (!emp.deptTimeZone) {
          emp.deptTimeZone = appContext.company.defaultTimeZone;
        }
        emp.department = getValidReg(emp.department);
        const val = departmentList.find((curr) => checkValidity(curr.name) == checkValidity(emp.department));
        if (val) {
          emp.department = val.id;
        } else {
          const obj = { name: emp.department, callTime: emp.callTime, deptTimeZone: emp.deptTimeZone };
          const id = await api.addDepartment(obj, appContext.user);
          departmentList.push({ id: id, name: getValidReg(emp.department), callTime: emp.callTime });
          emp.department = id;
        }
      }
      if (emp.gender) {
        emp.gender = getValidGender(emp.gender);
      }
      if (emp.country) {
        emp.country = getValidDep(emp.country);
      } else {
        emp.country = "United States";
      }
      if (emp.department && !emp.callTime) {
        emp.callTime = departmentList.find((dpt) => emp.department === dpt.id)?.callTime || null;
      }

      if (emp.dob && !isValidDob(emp.dob)) {
        arr.push({
          message: `Invalid (${emp.dob}) Date of Birth format is (MM/DD/YYYY)  on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      if (emp.dob) {
        emp.dob = formatDOB(emp.dob);
      }

      emp.phoneNumber = importPhoneNoFormat(emp.phoneNumber);

      const phone = formatPhoneNumberIntl(emp.phoneNumber);
      const phoneArr = phone.split(" ");
      emp.countryCode = phoneArr[0];

      if (emp.phoneNumber && !validatePhone(emp.phoneNumber)) {
        arr.push({
          message: `phone number is not correct ${emp.phoneNumber} on row ${index + 2}`,
          data: emp,
        });
        continue;
      }

      if (emp.email && !isValidEmail(emp.email)) {
        arr.push({
          message: `Invalid email ${emp.email} on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      const findVal = await empList.find(
        (curr) =>
          curr.phoneNumber === emp.phoneNumber &&
          checkValidity(emp.firstName) === checkValidity(curr.firstName) &&
          checkValidity(emp.lastName) === checkValidity(curr.lastName) &&
          emp.email === curr.email
      );

      if (findVal) {
        arr.push({
          message: `Employee Already exist on row ${index + 2}.`,
          data: emp,
        });
        continue;
      }
      try {
        const program = emp.programName?.trim() || emp.zone?.trim();
        if (program) {
          isNotZoneKeyInImport = false;
          let existingProgram = programName.find(
            (p) => p.name.replace(/ /g, "").toLowerCase() === program.replace(/ /g, "").toLowerCase()
          );
          if (!existingProgram) {
            const programNewToSave = getValidSpaces(program || "");
            existingProgram = await api.newZone(programNewToSave, appContext.user);

            programName.push(existingProgram);
          }
          emp.programID = existingProgram.id;
          emp.programName = existingProgram.name;
          emp.isSchedule = STATUS.zoneAssign;
        }
      } catch (programErr) {
        console.log("Program Error", programErr);
      }

      allEmployess = allEmployess + 1;
      const result = await api.newEmployee(emp, allEmployess, appContext.user);

      // get the new employee id and push into array to set as selected
      if (result?.id) newEmpIds.push(result.id);
      if (result) {
        logData.push({
          schrID: result.id,
          auditType: "crewMemberCreated",
          action: "Created",
          companyID: result.companyID,
          userName: appContext.user.name,
        });
      }

      empList.push({ firstName: emp.firstName, lastName: emp.lastName, dob: emp.dob });
      arrdata.push("Success");
    }
    getDepartments();
    await api.updateCompnayEmployeeNo(allEmployess);
    if (logData.length > 0) {
      await api.addlogs(logData);
    }
    await appContext.resetPrograms();
    await appContext.resetEmployees();
    await appContext.resetLocations();
    await appContext.resetRegions();
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle("Employee");
      setCount(allEmployess);
      setsuccessData(arrdata);
      setOpenErrModal(true);
      setShowZoneAssignModal(isNotZoneKeyInImport);
    }
    // set newly created employee as selected
    if (newEmpIds.length > 0) {
      setCheckboxes(newEmpIds);
    }
  };

  const handleImportEmployee = async (empData) => {
    if (empData.length === 0) return;
    const ttlLength = empData.length;
    const likeIds = [];
    const ids = [];
    let i = 0;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      if (data.name) {
        const arr = data.name.split(" ");

        const firstName = arr[0].toLowerCase().trim();
        const lastName = (arr[1] || "").trim().toLowerCase();
        const emp = appContext.employees.filter(
          (e) =>
            (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
              lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
            (e.firstName.toLowerCase().indexOf(firstName) !== -1 && e.lastName.toLowerCase().indexOf(lastName) !== -1)
        );
        if (emp.length === 0) {
          ids.push({
            firstName: firstName,
            lastName: lastName,
            hrFirst: "",
            hrLast: "",
            zone: data.programName,
          });
        } else {
          likeIds.push({
            id: emp[0].id,
            firstName: firstName,
            lastName: lastName,
            hrFirst: emp[0].firstName,
            hrLast: emp[0].lastName,
            zone: data.programName,
          });
        }
      }
    }

    likeIds.forEach(async (e) => {
      await api.updateEmployeeDefault(e.id, e.zone);
    });

    const content = jsonToCSV(ids);
    const fileContent = new Blob([content], { type: "csv" });
    // await FileSaver.saveAs(fileContent, "houston_zone_22_07_22.csv");
  };

  const handleImportEmployeeOld = async (empData) => {
    if (empData.length === 0) return;
    const ttlLength = empData.length;
    const likeIds = [];
    const ids = [];
    let i = 0;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      if (data.firstName) {
        const firstName = data.firstName.toLowerCase();
        const lastName = data.lastName.toLowerCase();
        const firstLike = data.firstName.toLowerCase().substring(0, 2);
        const lastLike = data.lastName.toLowerCase().substring(0, 2);
        const emp = appContext.employees.filter(
          (e) =>
            (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
              lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
            (e.firstName.toLowerCase().indexOf(firstName) !== -1 && e.lastName.toLowerCase().indexOf(lastName) !== -1)
        );
        if (emp.length === 0) {
          // ids.push({ firstName, lastName });
          const emp1 = appContext.employees.filter(
            (e) =>
              (firstLike === e.firstName.toLowerCase().substring(0, 2) &&
                lastName.indexOf(e.lastName.toLowerCase()) !== -1) ||
              (firstName.indexOf(e.firstName.toLowerCase()) !== -1 &&
                lastLike === e.lastName.toLowerCase().substring(0, 2))
          );
          if (emp1.length === 0) {
            likeIds.push({ firstName: data.firstName, lastName: data.lastName, hrFirst: "", hrLast: "" });
          } else {
            likeIds.push({
              firstName: data.firstName,
              lastName: data.lastName,
              hrFirst: emp1[0].firstName,
              hrLast: emp1[0].lastName,
            });
          }
        } else {
          likeIds.push({
            firstName: data.firstName,
            lastName: data.lastName,
            hrFirst: emp[0].firstName,
            hrLast: emp[0].lastName,
          });
        }
      }
    }

    const content = jsonToCSV(likeIds);
    const fileContent = new Blob([content], { type: "csv" });
    //  await FileSaver.saveAs(fileContent, "houston_data_exists_20_07_22.csv");
  };

  const onHandleProgramSave = async (zone) => {
    try {
      const records = users.filter((f) => checkboxes.includes(f.id));
      if (records.length > 0) {
        setLoading(true);
        records.forEach(async (e) => {
          await api.updateEmployeeDefault(e.id, zone.label);
        });
        setLoading(false);
      }
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
    appContext.resetEmployees();
    setOpenAssignModal(false);
    setCheckboxes([]);
    setAllSelected(false);
  };

  const handleTestNow = async (assignTest) => {
    setOpenTestNowModal(false);
    setLoading(true);
    try {
      await api.updateEmployeeTest(checkboxes, assignTest);
      appContext.showSuccessMessage("Assign Test Successfully");
      setCheckboxes([]);
      setAllSelected(false);
      appContext.resetEmployees();
      setLoading(false);
    } catch (err) {
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
  };

  const updateEmpProgramName = async (data, dpt, Ids) => {
    setShowProgramOptions(false);
    if (!data || checkboxes.length === 0) return;
    setLoading(true);
    const findVal = users.filter(
      (f) =>
        (checkboxes.includes(f.id) && f.departmentName === "-") ||
        (checkboxes.includes(f.id) &&
          f.departmentName &&
          departments.findIndex((d) => d.id === f.department && d.deptTimeZone) === -1)
    );
    let checkVal = checkboxes;
    if (findVal.length > 0) {
      checkVal = checkboxes.filter((chb) => findVal.findIndex((f) => f.id === chb) === -1);
    }
    try {
      if (Ids.length > 0)
        await api.assignedDepartmentToEmp(
          dpt.isDptAssigned === "N" ? Ids : checkboxes,
          dpt,
          data,
          STATUS.zoneAssign,
          appContext.user.name
        );
      if (Ids.length === 0 || dpt.isDptAssigned === "N")
        await api.updateEmpProgramName(checkVal, data, STATUS.zoneAssign, appContext.user.name);
      let dataCount = checkVal.length + Ids.length;
      setAssignProgramSuccessMsg({
        success: `Success, you have assigned ${program} of ${dataCount} ${dataCount > 1 ? "employees" : "employee"}`,
        error: Ids.length === 0 ? findVal : null,
      });
      appContext.resetEmployees();
      setCheckboxes([]);
      setAllSelected(false);
      setLoading(false);
      setDisplayMessage(true);
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  const onHandleConfirmation = (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      switch (type) {
        case CONFIRMATION_TYPE.DELETE:
          setCheckboxes([]);
          setAllSelected(false);
          setAssignProgram([]);
          setUserToDelete(null);
          break;
        case CONFIRMATION_TYPE.PROGRAM_START:
          handleAssignGroupSave(programData);
          break;
        case CONFIRMATION_TYPE.ASSIGN_PROGRAM:
          setCheckboxes([]);
          setAllSelected(false);
          setAssignProgram([]);
          break;
        case CONFIRMATION_TYPE.DRIVE_ACCESS:
          setCheckboxes([]);
          setAllSelected(false);
          setDate(null);
          break;
        case CONFIRMATION_TYPE.SEND_QR_CODE:
          setCheckboxes([]);
          setAllSelected(false);
          setDate(null);
          setDateRangeKey("");
          break;
        case CONFIRMATION_TYPE.ASSIGN_DPT:
          const ids = isDepartmentNotExists();
          if (ids.length !== checkboxes.length) {
            setShowProgramOptions(true);
          } else {
            setCheckboxes([]);
            setAllSelected(false);
          }
          break;
        case CONFIRMATION_TYPE.EXPECTED_ON_SET:
          setCheckboxes([]);
          setAllSelected(false);
          setDate(null);
          setDateRangeKey("");
          break;
        case CONFIRMATION_TYPE.BULK_EXPECTED_ON_SET:
          setCheckboxes([]);
          setAllSelected(false);
          setDate(null);
          // setDateRangeKey("");
          break;
      }
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DELETE:
        onDeleteConfirm();
        break;
      case CONFIRMATION_TYPE.PROGRAM_START:
        handleAssignProgramandStart(programData);
        break;
      case CONFIRMATION_TYPE.ASSIGN_PROGRAM:
        if (appContext.isZoneLevelCompany()) {
          handleAssignZone();
        } else {
          setOpenAssignModal(true);
        }
        break;
      case CONFIRMATION_TYPE.DRIVE_ACCESS:
        handleDriveAccessMembers();
        break;
      case CONFIRMATION_TYPE.EXPECTED_ON_SET:
      case CONFIRMATION_TYPE.BULK_EXPECTED_ON_SET:
        handleExpectedOnSet();
        break;
      case CONFIRMATION_TYPE.SEND_QR_CODE:
        handleSendQrCode();
        break;
      case CONFIRMATION_TYPE.ASSIGN_DPT:
        setOpenAssignDepartment(true);
        break;
    }
  };

  const handleClearedProgram = async (isConfirm, reason) => {
    setShowClearedProgram(false);
    setProgramData([]);
    if (!isConfirm) return;

    try {
      setLoading(true);
      appContext.showSuccessMessage("Successfully cleared the employee");
      appContext.resetEmployees();
      setCheckboxes([]);
      setAllSelected(false);
      setLoading(false);
    } catch (err) {
      console.log("Error", err.message);
      setLoading(false);
      appContext.showErrorMessage("Fail to cleared the employee program");
    }
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    const personalisationData = personalizationLocalStorage.saveAs(data, 0);
    setOpenPersonalizationModal(false);
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const tdFormat = (item, user) => {
    if (item === "updatedAt") return formatDate(user.updatedAt);
    if (item === "dob") return formatDateMDY(user.dob);
    if (item === "isVaccinated") return parseBooleanValue(user.isVaccinated);
    if (item === "departmentName") return user.departmentName ? user.departmentName : "";
    if (item === "testingGroup") return user.testingGroup ? user.testingGroup : "-";
    if (item === "jobTitle") return user.jobTitle ? user.jobTitle : "";
    if (item === "localNo") return user.localNo ? user.localNo : "-";
    return user[item];
  };
  const handleShowAll = async (val) => {
    setLoading(true);
    setShowAll(val);
    const showAllData = personalizationLocalStorage.saveShowAll(val ? "1" : "0", "employeeAll");
    await api.udpatePersonalization(appContext.user.phone_number, showAllData);
    setLoading(false);
  };
  const popOverFn = (item, user, itemKey) => {
    if (appContext.company?.programLevel === "Zone" && itemKey === "tests") {
      return <ZonePopoverStick item={item} user={user} itemKey={itemKey} appContext={appContext} />;
    } else {
      return <PopoverStick item={item} user={user} itemKey={itemKey} appContext={appContext} />;
    }
  };
  const renderTd = (item, user) => {
    if (item.itemKey === "email") return tdEmail(user.email);
    if (item.itemKey === "phoneNumber") return tdPhone(user.phoneNumber);
    if (item.itemKey === "qaDone") return tdCheckBox(item, user, openExternalTest);
    if (item.itemKey === "testDone") {
      if (appContext.isZoneLevelCompany()) {
        return tdCheckBox(item, user, openExternalTest);
      }
      return tdCheckBox(item, user);
    }
    if (item.itemKey === "checkIn") return tdCheckBox(item, user);
    if (item.itemKey === "onBoardingTesting") return tdCheckBox(item, user);
    if (item.itemKey === "isSchedule") return tdProgramStatus(item, user, appContext.isZoneLevelCompany());
    if (item.itemKey === "zoneColor") return tdZoneColor(item, user);
    if (item.itemKey === "scheduleName") return popOverFn(item, user);
    if (item.itemKey === "callTime") return tdCallTime(user.callTime);
    if (item.itemKey === "tests") return popOverFn(item, user, item.itemKey);
    if (item.itemKey === "driveOnAccess") return tdCheckBox(item, user, handleExternalTestView);
    if (item.itemKey === "onSet") return tdCheckBox(item, user);
    if (item.itemKey === "qrCode") {
      return (
        <td
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
            cursor: "pointer",
          }}
          title="QR Code"
          onClick={() => handleQrPrint(user)}
        >
          <i className="fa fa-qrcode" />
        </td>
      );
    }
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const openDeleteConfirmation = (user) => {
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.DELETE,
      title: "Delete Employee",
      message: "Are you sure, you want to remove Employee?",
    });
    if (user) setUserToDelete(user);
  };

  const checkOnSetErrors = (selectedEmployees) => {
    let error = "";

    let firstEmpDatesArr = [];

    if (selectedEmployees[0].onSetDates?.startDate) {
      firstEmpDatesArr =
        getDaysArray(selectedEmployees[0].onSetDates.startDate, selectedEmployees[0].onSetDates.endDate) || [];
    } else if (selectedEmployees[0].onSetDates?.dates?.length > 0) {
      firstEmpDatesArr = selectedEmployees[0].onSetDates.dates || [];
    }
    const firstEmpDatesStr = JSON.stringify(firstEmpDatesArr) || "";

    const differentDatesLength = selectedEmployees.some(({ onSetDates }) => {
      if (onSetDates && onSetDates?.dates?.length > 0) {
        return firstEmpDatesArr.length !== onSetDates.dates.length;
      } else if (onSetDates && onSetDates?.startDate && onSetDates?.endDate) {
        return firstEmpDatesArr.length !== getDaysArray(onSetDates.startDate, onSetDates.endDate).length;
      } else {
        return firstEmpDatesArr.length !== 0;
      }
    });
    if (differentDatesLength) {
      error = "Selected users includes different dates";
      return error;
    }

    const checkDateStartEnd = selectedEmployees.some(({ onSetDates }) => {
      if (onSetDates && onSetDates?.dates?.length > 0) {
        return firstEmpDatesStr !== JSON.stringify(onSetDates.dates);
      } else if (onSetDates && onSetDates?.startDate && onSetDates?.endDate) {
        return firstEmpDatesStr !== JSON.stringify(getDaysArray(onSetDates.startDate, onSetDates.endDate));
      } else {
        return firstEmpDatesArr.length !== 0;
      }
    });

    if (checkDateStartEnd) {
      error = "Selected users includes different dates";
      return error;
    }

    return "";
  };

  const openOnSetConfirmation = () => {
    const temp = users.filter(({ id }) => checkboxes.includes(id));
    setSelectedEmps(temp);

    let errors = "";
    if (temp.length > 1) errors = checkOnSetErrors(temp) || "";

    if (!errors) {
      setOpenExpectedOnSetModal(true);
    } else {
      appContext.showErrorMessage(errors);
      setSelectedEmps([]);
    }
  };

  const handleCreateSystemUSer = async (user) => {
    try {
      setLoading(true);
      const res = await api.createSystemUser({ ...user, password: "Pass1234!" });
      console.log("res", res);
      if (res) {
        await api.updateEmployeeSub(user.id, res.userSub);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };

  const openBulkOnSetConfirmation = (dates) => {
    setOpenExpectedOnSetModal(false);

    setDate({
      dates: dates.map((d) => d.start),
    });

    let message = expectedOnSet
      ? `Would you like to add the selected crew member(s) to the ${CONFIG.expectedOnSet} list??`
      : `Would you like to update the selected crew member(s) in to the ${CONFIG.expectedOnSet} List?`;
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.BULK_EXPECTED_ON_SET,
      title: CONFIG.expectedOnSet,
      message,
    });
  };

  const openDriveAccessConfirmation = (driveAccessibility) => {
    if (driveAccessibility) {
      setOpenDateRangeModal(true);
      setDateRangeKey(CONFIRMATION_TYPE.DRIVE_ACCESS);
    } else {
      setOpenConfirmation({
        isShow: true,
        actionType: CONFIRMATION_TYPE.DRIVE_ACCESS,
        title: "Drive on Access",
        message: `Would you like to remove the selected crew members drive on access?`,
      });
    }
  };

  const handleDateRange = (dateRange) => {
    setOpenDateRangeModal(false);
    setDate(dateRange);
    if (dateRangeKey === CONFIRMATION_TYPE.DRIVE_ACCESS) {
      setOpenConfirmation({
        isShow: true,
        actionType: CONFIRMATION_TYPE.DRIVE_ACCESS,
        title: "Drive on Access",
        message: `Would you like to grant the selected crew members drive on access?`,
      });
    } else if (dateRangeKey === CONFIRMATION_TYPE.EXPECTED_ON_SET) {
      setOpenConfirmation({
        isShow: true,
        actionType: CONFIRMATION_TYPE.EXPECTED_ON_SET,
        title: CONFIG.expectedOnSet,
        message: `Would you like to add in ${CONFIG.expectedOnSet} List`,
      });
    }
  };

  const isMainAdmin = () => appContext.user?.isAdmin() && !appContext.isReadOnly();

  const openClearedForWorkModal = () => {
    const filterCheckData = users.filter(
      (i) => [...new Set(checkboxes.map((ch) => ch.replace("%testNow", "")))].includes(i.id) && i.qaDone === "X"
    );
    if (filterCheckData.length === 0) {
      appContext.showErrorMessage("No Active Employee to Process");
      return;
    }
    setProgramData(filterCheckData);
    setShowClearedProgram(true);
  };

  const handleExpectedOnSet = async () => {
    try {
      setOpenConfirmation({ isShow: false });

      // const allowedUsers = filteredUsers.filter(
      //   ({ id, onSet, isQuarantine }) => checkboxes.includes(id) && (expectedOnSet ? !onSet && !isQuarantine : onSet)
      // );
      const allowedUsers = filteredUsers.filter(
        ({ id, onSet, isQuarantine }) => checkboxes.includes(id) && (expectedOnSet ? !onSet && !isQuarantine : true)
      );

      if (allowedUsers.length > 0) {
        setLoading(true);
        await api.updateExceptedOnSet(
          allowedUsers.map(({ id }) => id),
          appContext.user.name,
          date?.dates?.includes(moment().format("YYYY-MM-DD")) ? true : false,
          date
        );
        setLoading(false);
        appContext.showSuccessMessage(
          `Successfully ${formatNumber(allowedUsers.length)} Crew ${
            allowedUsers.length === 1 ? "member" : "members"
          }  ${expectedOnSet ? " added" : " updated"} ${CONFIG.expectedOnSet} List.`
        );
        appContext.resetEmployees();
      } else {
        const errorMessage = expectedOnSet ? "Employee in Quarantine" : "Unable to process request";
        appContext.showErrorMessage(errorMessage);
      }
      setCheckboxes([]);
      setAllSelected(false);
      setDate(null);
      setDateRangeKey("");
    } catch (error) {
      console.log(error);
      appContext.showErrorMessage("Fatal error occurred! Please try again.");
    }
  };

  const handleDriveAccessMembers = async () => {
    try {
      setOpenConfirmation({ isShow: false });

      const allowedUsers = filteredUsers.filter(
        ({ id, driveOnAccess, isQuarantine }) =>
          checkboxes.includes(id) && (driveAccessibility ? !driveOnAccess && !isQuarantine : driveOnAccess)
      );

      if (allowedUsers.length > 0) {
        setLoading(true);
        await api.updateDriveAccessMembers(
          allowedUsers.map(({ id }) => id),
          appContext.user.name,
          driveAccessibility,
          date
        );
        setLoading(false);
        appContext.showSuccessMessage(
          `Successfully ${driveAccessibility ? "given access to" : "removed access from"} ${formatNumber(
            allowedUsers.length
          )} ${allowedUsers.length === 1 ? "member" : "members"}.`
        );
        appContext.resetEmployees();
      } else {
        const errorMessage = driveAccessibility ? "Employee in Quarantine" : "Unable to remove access from the user.";
        appContext.showErrorMessage(errorMessage);
      }
      setDate(null);
      setDateRangeKey("");
      setCheckboxes([]);
      setAllSelected(false);
    } catch (error) {
      console.log(error);
      appContext.showErrorMessage("Fatal error occurred! Please try again.");
    }
  };

  const openSendQrCodeConfirmation = () => {
    setOpenConfirmation({
      isShow: true,
      actionType: CONFIRMATION_TYPE.SEND_QR_CODE,
      title: `Send Qr Code`,
      message: `Do you want to send QR code via Email or SMS`,
    });
  };

  const isDepartmentNotExists = () => {
    const findVal = users.filter(
      (f) =>
        (checkboxes.includes(f.id) && f.departmentName === "-") ||
        (checkboxes.includes(f.id) &&
          f.departmentName &&
          departments.findIndex((d) => d.id === f.department && d.deptTimeZone) === -1)
    );
    return findVal.map((m) => m.id);
  };

  const handleAssignDepartment = async (dpt) => {
    setOpenAssignDepartment(false);
    const ids = isDepartmentNotExists();
    if (ids.length > 0) {
      try {
        setLoading(true);
        await api.assignedDepartmentToEmp(ids, dpt);
        await appContext.resetEmployees();
        setLoading(false);
        setShowProgramOptions(true);
      } catch (err) {
        setLoading(false);
        console.log("Error:-", err.message);
      }
    }
  };

  const handleAssignZone = () => {
    setShowProgramOptions(true);
    // const ids = isDepartmentNotExists();
    // if (ids.length) {
    //   setOpenConfirmation({
    //     isShow: true,
    //     actionType: CONFIRMATION_TYPE.ASSIGN_DPT,
    //     title: `Assign Department`,
    //     message: `Department is also Required Would you like to assign the Department?`,
    //   });
    // } else {

    // }
  };

  const handleSendQrCode = async () => {
    setLoading(true);
    await api.sendQrCode(checkboxes, sendQrCodeVia);
    setLoading(true);
    setCheckboxes([]);
    setAllSelected(false);
  };

  const handleExternalTestView = async (filePath) => {
    if (!filePath) return;
    try {
      const resImage = await Storage.get(filePath, { bucket: "employee-external-tests" });
      setExternalTestImage(resImage);
    } catch (err) {
      console.log("Error:- AuditTrail External Image", err.message);
    }
  };

  const openExternalTest = (user, type) => {
    if (appContext.isReadOnly()) return;
    setProgramData(user);
    setSelectedType(type);
    if (type === "QA") {
      setShowClearedProgram(true);
    } else {
      setShowExternalTest(true);
    }
  };

  const handleQrPrint = (user) => {
    setQrPrint(user);
    setTimeout(() => {
      window.print();
    }, 500);
  };

  const handleExternaltest = async (userObject, logObject) => {
    setShowExternalTest(false);
    setProgramData([]);
    setSelectedType("");
    if (!userObject) return;
    try {
      setLoading(true);
      await api.uploadExternalTest(userObject, true);
      if (logObject) {
        await api.addlogs([logObject]);
      }
      appContext.showSuccessMessage("Successfully added external test");
      appContext.resetEmployees();
      setCheckboxes([]);
      setAllSelected(false);
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };

  const handleHistoryView = (user) => {
    setSelectedEmployee(user);
  };

  const TableRow = ({ user }) => {
    return (
      <tr className={isResultPositive(user) ? `isPositiveResult` : ""}>
        <td className="ellipsis" style={{ textAlign: "left" }}>
          <div className="d-flex align-items-center w-100 column-gap-10">
            <Form.Check>
              <Form.Check.Input
                type="checkbox"
                checked={checkboxes.indexOf(user.id) !== -1}
                value={user.id}
                onChange={(e) => handleCheckboxChange(e, user)}
              />
            </Form.Check>
            {!user.testNow ? (
              !appContext.isReadOnly() && (
                <img
                  src={EditIcon}
                  width="18"
                  alt="edit icon"
                  onMouseLeave={(e) => (e.target.style.color = "black")}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  aria-hidden="true"
                  id={user.id}
                  onClick={(e) => handleEditUser(user)}
                />
              )
            ) : (
              <i className="fa fa-star fa-2xs" />
            )}
          </div>
        </td>

        {personalize.map((item) => item.isCheck && renderTd(item, user))}

        <td style={{ textAlign: "center", textOverflow: "visible" }}>
          {appContext.company?.programLevel === "Zone" ? (
            <div
              style={{ textAlign: "center", color: "#42cef5", textAlign: "center", cursor: "Pointer" }}
              onClick={() => handleHistoryView(user)}
            >
              View
            </div>
          ) : (
            <>
              {!appContext.isReadOnly() && (
                <img
                  src={TrashIcon}
                  width="15"
                  alt="trash icon"
                  onMouseLeave={(e) => (e.target.style.color = "black")}
                  onMouseOver={(e) => {
                    e.target.style.cursor = "pointer";
                  }}
                  aria-hidden="true"
                  id={user.id}
                  onClick={(e) => {
                    openDeleteConfirmation(user);
                  }}
                />
              )}
            </>
          )}
        </td>
      </tr>
    );
  };

  const headerAlign = (item) => (headerAlignKeys.includes(item) ? "centered" : undefined);
  return (
    <Container fluid>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Employees ({filteredUsers ? formatNumber(filteredUsers.length) : formatNumber(users.length)})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    label="Filter"
                    title="Filter"
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => {
                      if (filteredUsers && filteredUsers.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  {!appContext.isReadOnly() && (
                    <>
                      <Icon
                        handleClick={() => openDeleteConfirmation()}
                        label="Delete"
                        title="Delete"
                        disabled={checkboxes.length === 0}
                        iconType={"binIcon"}
                      />
                      <Icon
                        handleClick={() => {
                          setNewUser({ ...EmptyUserObj });
                          setOpenCreator(true);
                        }}
                        label={"Create"}
                        title={"Create Employee"}
                        iconType={"addUserIcon"}
                      />
                      <Icon
                        handleClick={() => {
                          setOpenImportModal(true);
                        }}
                        title={"Load Employee Data"}
                        iconType="loadCrewIcon"
                        label="Load Crew"
                      />
                      <Icon
                        handleClick={async () => {
                          try {
                            const ids = filteredUsers.map((t) => {
                              let dob = "";
                              if (t.dob) {
                                const arr = t.dob.split("-");
                                dob = `${arr[1]}/${arr[2]}/${arr[0]}`;
                              }

                              return {
                                firstName: t.firstName,
                                lastName: t.lastName,
                                phoneNumber: t.phoneNumber,
                                email: t.email,
                                dob: dob,
                                isVaccinated: t.isVaccinated,
                                isBooster: t.isBooster,
                                gender: t.gender,
                                idNumber: t.idNumber,
                                street: t.street,
                                street2: t.street2,
                                city: t.city,
                                state: t.state,
                                zip: t.zip,
                                jobTitle: t.jobTitle,
                                location: t.location,
                                region: t.region,
                                country: t.country,
                                zone: t.programName,
                                department: t.departmentName,
                                callTime: t.callTime,
                              };
                            });
                            const content = jsonToCSV(ids);
                            const fileContent = new Blob([content], { type: "csv" });
                            await FileSaver.saveAs(fileContent, "employee_data.csv");
                          } catch (err) {
                            console.log("Error", err);
                          }
                        }}
                        title="Export"
                        iconType="XLSIcon"
                        label="Export"
                      />
                    </>
                  )}

                  {/* <ExportToExcel
                    employee
                    selectedItem={personalize.length > 0 && personalize.filter((m) => m.isCheck && m.itemKey)}
                    items={filteredUsers}
                    appContext={appContext}
                    filter={filter}
                    title="Employee Data"
                    sortBy={sortBy}
                  /> */}
                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={"Personalize"}
                    label={"Personalize"}
                    iconType={"personalizeIcon"}
                  />

                  {!appContext.isZoneLevelCompany() && !appContext.isReadOnly() && (
                    <>
                      <Icon
                        handleClick={() => setOpenTestNowModal(true)}
                        disabled={checkboxes.length === 0}
                        title={"Test Now"}
                        label={"Test Now"}
                        iconType={"testNowIcon"}
                      />

                      <Icon
                        handleClick={() => setOpenAssignModal(true)}
                        disabled={checkboxes.length === 0}
                        title="Assign Program"
                        label="Assign Program"
                        iconType="assignZoneIcon"
                      />
                      <Icon
                        handleClick={() => handleProgram(1)}
                        title="Start Program"
                        label="Start Program"
                        disabled={checkboxes.length === 0}
                        iconType="assignZoneIcon"
                      />

                      <ExportSelectedData filteredUsers={filteredUsers} uniquePrograms={uniquePrograms} />
                    </>
                  )}
                  {appContext.isZoneLevelCompany() && !appContext.isReadOnly() && (
                    <>
                      <Icon
                        handleClick={handleCustomSch}
                        disabled={checkboxes.length === 0}
                        title="Custom Schedule"
                        label="Schedule"
                        iconType="assignZoneIcon"
                      />
                      <Icon
                        handleClick={() => handleAssignZone()}
                        title={"Assign Zone"}
                        label="+ Zone"
                        iconType="assignZoneIcon"
                        disabled={checkboxes.length === 0}
                      />

                      <Icon
                        handleClick={() => handleRemoveGroup()}
                        title={`Remove ${program}`}
                        label={`X ${program}`}
                        disabled={
                          checkboxes.length === 0 ||
                          filteredUsers.filter((f) => checkboxes.includes(f.id) && f.programName).length === 0
                        }
                        iconType="removeZoneIcon"
                      />

                      <Icon
                        handleClick={() => updateAcknowledgeBy()}
                        title={"Acknowledge Positive"}
                        label="Ack Pos"
                        disabled={
                          users.filter(
                            (f) =>
                              checkboxes.includes(f.id) &&
                              f.testHistory &&
                              f.testHistory.filter(
                                (t) => t.result && !t.isAcknowledged && t.result.toLowerCase() === "positive"
                              ).length > 0
                          ).length === 0
                        }
                        iconType={"acknowledgedPositiveIcon"}
                      />

                      {/* <Icon
                        handleClick={() => setShowMergeProfileModal(true)}
                        title={"Combine Profile"}
                        label="Combine"
                        iconType="assignZoneIcon"
                        disabled={checkboxes.length === 0}
                      /> */}

                      {/* {isMainAdmin() && (
                        <Button
                          className="headerButton btn-fill"
                          variant="secondary"
                          onClick={openClearedForWorkModal}
                          disabled={checkboxes.length === 0}
                        >
                          Clear for Work
                        </Button>
                      )} */}
                    </>
                  )}
                  {!appContext.isZoneLevelCompany() && !appContext.isReadOnly() && (
                    <>
                      <Icon
                        handleClick={() => handleRemoveGroup()}
                        disabled={checkboxes.length === 0}
                        title={`Remove ${program}`}
                        label={`X ${program}`}
                        iconType="removeZoneIcon"
                      />
                    </>
                  )}

                  {!appContext.isReadOnly() && (
                    <>
                      <Icon
                        handleClick={() => setOpenNotificationModal(NOTIFICATION_FROM.employee)}
                        title={"Message"}
                        label={"Message"}
                        disabled={checkboxes.length === 0}
                        iconType="messageIcon"
                      />
                      <Icon
                        handleClick={() => setOpenNotificationModal(NOTIFICATION_FROM.qrCode)}
                        title={"Send QR Code"}
                        label="Send QR"
                        disabled={checkboxes.length === 0}
                        iconType={"qrIcon"}
                      />
                      <Icon
                        handleClick={() => openDriveAccessConfirmation(driveAccessibility)}
                        title={driveAccessibility ? "Drive on Access" : "Remove Drive Access"}
                        label={driveAccessibility ? "DOA" : "RDA"}
                        iconType={driveAccessibility ? "assignZoneIcon" : "removeZoneIcon"}
                        disabled={checkboxes.length === 0}
                      />

                      {/* <Icon
                        handleClick={() => openOnSetConfirmation()}
                        // title={expectedOnSet ? "Expected on Set" : "Remove on Set List"}
                        // label={expectedOnSet ? "EOS" : "REOS"}
                        title={expectedOnSet ? CONFIG.expectedOnSet : CONFIG.updateOnSetList}
                        label={expectedOnSet ? CONFIG.eos : CONFIG.ueos}
                        disabled={checkboxes.length === 0}
                        iconType={expectedOnSet ? "assignZoneIcon" : "removeZoneIcon"}
                      /> */}
                      <Icon
                        handleClick={() => setOpenExpectedOnSetDetailModal(true)}
                        title={`${CONFIG.expectedOnSet} Details`}
                        label={`${CONFIG.eos}D`}
                        // disabled={checkboxes.length === 0}
                        iconType={"showAllIcon"}
                      />
                    </>
                  )}
                </div>
                {showFilter && (
                  // <Filter
                  //   filterTerms={searchTerms}
                  //   setFilter={setFilter}
                  //   filter={filter}
                  //   triggerFilter={triggerFilter}
                  //   setTriggerFilter={setTriggerFilter}
                  //   isZoneLevel={appContext.isZoneLevelCompany()}
                  //   program={program}
                  // />
                  <FilterPopOver
                    grouped
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                    isZoneLevel={appContext.isZoneLevelCompany()}
                    program={program}
                  />
                )}
                <div id="section-to-print" className="printSide">
                  {qrPrint && <QrPrint newUser={qrPrint} appContext={appContext} />}
                </div>
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={pageNumbers}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                  showAll={showAll}
                  showAllBtn
                  handleShowAll={(e) => handleShowAll(e)}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div
                  className={
                    filteredUsers.length > 0
                      ? "table-responsive pendingReleaseTable"
                      : "table-responsive pendingReleaseTable overFlow-y-hidden"
                  }
                >
                  <Table className={`user-table employeeTableWrapper`}>
                    <thead ref={componentRef}>
                      <tr>
                        <th />
                        {personalize.map(
                          (item, i) =>
                            item.isCheck && (
                              <HeaderItem
                                key={i}
                                width={tdWidth}
                                ItemKey={item.itemKey}
                                title={item.title}
                                flipSort={flipSort}
                                sortBy={sortBy}
                                sortDescending={sortDescending}
                                aligned={headerAlign(item.itemKey)}
                              />
                            )
                        )}
                        <th
                          className={`border-0 ellipsis centered`}
                          style={{
                            color: "#231f20",
                            fontWeight: "bold",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersToMap != "" && (
                        <ViewportList
                          items={usersToMap}
                          itemMinSize={42}
                          margin={16}
                          overflowAnchor="auto"
                          withCache={false}
                        >
                          {(item) => <TableRow user={item} key={item.id} />}
                        </ViewportList>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      {openExpectedOnSetModal && (
        <ExpectedOnSetCalendarModal
          handleClose={() => setOpenExpectedOnSetModal(false)}
          handleSave={openBulkOnSetConfirmation}
          selectedEmps={selectedEmps}
        />
      )}
      {openExpectedOnSetDetailModal && (
        <ExpectedOnSetCalendarDetailsModal handleClose={() => setOpenExpectedOnSetDetailModal(false)} />
      )}
      {showAlert && (
        <ShowAlertMessage
          message={"Schedule Not assigned"}
          handleClose={() => {
            setShowAlert(false);
            setAssignProgramSuccessMsg("");
          }}
          info
        />
      )}
      {openCreator && (
        <EmployeeModal user={newUser} handleClose={handleClose} handleSave={createUser} program={program} />
      )}
      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={onHandleConfirmation}
          closeBtn
          checked={sendQrCodeVia}
          setChecked={setSendQrCodeVia}
        />
      )}

      {openAssignDeprtment && (
        <AssigneDepartmentModal
          handleClose={() => {
            const ids = isDepartmentNotExists();
            setOpenAssignDepartment(false);
            if (ids.length !== checkboxes.length) {
              setShowProgramOptions(true);
            } else {
              setCheckboxes([]);
              setAllSelected(false);
            }
          }}
          handleSave={handleAssignDepartment}
        />
      )}

      {openImportModal && (
        <EmployeeImportModal handleClose={() => setOpenImportModal(false)} handleImport={handleImport} />
      )}
      {openProgram && (
        <StartProgramModal
          successMessage={programMessage}
          assignProgramErrorMsg={assignProgramSuccessMsg?.error}
          appContext={appContext}
          msg={
            Object.keys(assignProgramSuccessMsg).length === 1
              ? "Schdule Not assigned"
              : "Department or Department TimeZone not Assigned"
          }
          handleClose={() => {
            setOpenProgram(false);
            setProgramData(null);
            setAssignProgramSuccessMsg("");
          }}
          program={program}
        />
      )}
      {showClearedProgram && (
        <ClearedProgramModal handleConfirm={handleClearedProgram} employees={[programData]} appContext={appContext} />
      )}
      {displayMessage && (
        <StartProgramModal
          successMessage={assignProgramSuccessMsg?.success}
          assignProgramErrorMsg={assignProgramSuccessMsg?.error}
          appContext={appContext}
          msg={"Department or Department TimeZone not Assigned"}
          assignProgram
          handleClose={() => {
            setDisplayMessage(false);
            setProgramData(null);
            setAssignProgramSuccessMsg("");
          }}
          program={program}
        />
      )}
      {openErrModal && (
        <ImportErrorModal
          title={title}
          errData={errorData}
          count={count}
          successData={successData}
          handleClose={async (newIds) => {
            if (!appContext.isReadOnly() && (newIds.length > 0 || checkboxes.length > 0) && showZoneAssignModal) {
              setCheckboxes(checkboxes.concat(newIds));
              setOpenConfirmation({
                isShow: true,
                actionType: CONFIRMATION_TYPE.ASSIGN_PROGRAM,
                title: `Assign ${program}`,
                message: `Would you like to assign the ${program.toLowerCase()}?`,
              });
            }
            setOpenErrModal(false);
            setErrorData([]);
            setsuccessData([]);
            setTitle("");
            await appContext.resetEmployees();
            await appContext.resetLocations();
            await appContext.resetRegions();
          }}
        />
      )}
      {openAssignModal && (
        <AssignGroupModal
          handleClose={handleAssignGroupClose}
          handleSave={(obj) => {
            setProgramData(obj);
            setOpenConfirmation({
              isShow: true,
              actionType: CONFIRMATION_TYPE.PROGRAM_START,
              title: "Start Program",
              message: "Would you like to start the Program?",
            });
          }}
          handleProgramSave={onHandleProgramSave}
          data={assignProgram[0]}
        />
      )}

      {openDateRangeModal && (
        <DateRangeModal handleClose={() => setOpenDateRangeModal(false)} handleSave={handleDateRange} />
      )}
      {openTestNowModal && (
        <TestNowModal
          handleClose={() => setOpenTestNowModal(false)}
          handleSave={handleTestNow}
          company={appContext.company}
        />
      )}
      {zoneTestNow && (
        <ZoneTestNowModal
          handleClose={() => setZoneTestNow(false)}
          handleSave={handleZoneTestNow}
          company={appContext.company}
          empIds={checkboxes}
        />
      )}
      {/* {showProgramOptions && (
        <ProgramsOptionsModal
          data={appContext.programs}
          handleClose={() => {
            setShowProgramOptions(false);
            setCheckboxes([]);
          }}
          handleSave={updateEmpProgramName}
          program={program}
        />
      )} */}
      {showProgramOptions && (
        <AssignZoneModal
          data={appContext.programs}
          handleClose={() => {
            setShowProgramOptions(false);
            setCheckboxes([]);
            setAllSelected(false);
          }}
          isDepartmentNotExists={isDepartmentNotExists}
          handleSave={updateEmpProgramName}
          checkboxes={checkboxes}
          program={program}
        />
      )}
      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {showExternalTest && (
        <ExternalTestProgramModal
          handleConfirm={handleExternaltest}
          user={programData}
          selectedType={selectedType}
          companyType={appContext.isZoneLevelCompany()}
        />
      )}
      {customSchData && (
        <CustomScheduleModal
          zone={{ ...customSchData }}
          handleClose={() => {
            setCustomSchData(null);
            setCheckboxes([]);
            setAllSelected(false);
            appContext.resetEmployees();
          }}
          appContext={appContext}
        />
      )}
      {openNotificationModal && (
        <NotificationInputModal
          data={users.filter((i) => uniqueEmpIds(checkboxes).indexOf(i.id) !== -1)}
          handleClose={() => (setOpenNotificationModal(null), setCheckboxes([]))}
          appContext={appContext}
          dataType={openNotificationModal}
        />
      )}
      {showMergeProfileModal && (
        <MergeProfileModal
          handleClose={(val) => {
            setShowMergeProfileModal(false);
            if (val) {
              appContext.showSuccessMessage("Successfully Combine Profiles");
              setCheckboxes([]);
              appContext.resetEmployees();
            }
          }}
          employees={filteredUsers.filter((f) => checkboxes.includes(f.id))}
          userName={appContext.user.name}
        />
      )}
      {selectedEmployee && <EmployeeAuditTrail user={selectedEmployee} handleClose={() => setSelectedEmployee(null)} />}
      <FullImageView image={externalTestImage} handleClose={() => setExternalTestImage(null)} />
    </Container>
  );
};

export default Employees;
