import debounce from "debounce";
import moment from "moment";
import React, { useCallback, useEffect, useState, useMemo, useContext } from "react";
import { Button } from "react-bootstrap";
import { removeSpaceIns } from "utils";

import Select from "react-select";
import { GROUPS } from "constant";
import GroupLabelTab from "components/GroupLabelTab";
import { formatCallTime } from "utils";
import CalendarSideBarHeader from "./Components/CalendarSideBarHeader";
import WorkingDayCalendarSideBar from "./Components/WorkingDayCalendarSideBar";
import ScheduleZoneEventBar from "./Components/ScheduleZoneEventBar";
import { AppContext } from "context/app-context";

const CalendarDetails = (props) => {
  const {
    selectedData,
    testByZone,
    childNode,
    handleAddNotes,
    blockOutDate,
    workingDates,
    removeNotes,
    setRemoveNotes,
    setCustomScheduleData,
    selectedGroup,
    setShowMessage,
  } = props;

  const [notes, setNotes] = useState("");
  const [zones, setZones] = useState([]);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (blockOutDate.length > 0) {
      const note = blockOutDate.find((d) => d.notesDate === selectedData.date)?.note || "";
      setNotes(note);
      setRemoveNotes("");
    }
  }, [selectedData.date]);

  useEffect(() => {
    if (selectedData?.zones) {
      setZones(
        selectedData.zones.map((m) => {
          const emps = appContext.employees.filter((f) => f.programID === m.id);
          return { ...m, employees: emps };
        })
      );
    }
  }, [selectedData.zones, appContext.employees]);

  useEffect(() => {
    if (removeNotes) {
      setNotes("");
    }
  }, [removeNotes]);

  // Get Remaining Zones
  const RemainingZone = useMemo(() => {
    if (selectedData) {
      const zoneData = appContext.programs.filter(
        (f) => selectedData?.zones?.findIndex((sch) => sch.id === f.id) === -1
      );
      return zoneData.map((z) => {
        const emps = appContext.employees.filter((f) => f.programID === z.id);
        return { ...z, testTypes: [], zoneDateTests: [], employees: emps, title: z.name };
      });
    }
  }, [selectedData?.zones]);

  useEffect(() => {
    if (selectedGroup) {
      if (selectedGroup.value === "notScheduled") {
        setZones(RemainingZone);
      } else {
        setZones(
          selectedData?.zones?.map((m) => {
            const emps = appContext.employees.filter((f) => f.programID === m.id);
            return { ...m, employees: emps };
          }) || []
        );
      }
    }
  }, [selectedGroup, selectedData?.zones, RemainingZone]);

  const handleSaveNotes = (data) => {
    if (data.note.length === 0) {
      handleAddNotes(null, data.date, data.blockOutDates, data.workingDates);
    } else {
      handleAddNotes(data.note, data.date, data.blockOutDates, data.workingDates);
    }
  };

  const autoSaveFromApi = useCallback(
    debounce((data) => {
      handleSaveNotes(data);
    }, 1000),
    []
  );

  const handleClickCustomSch = (event) => {
    if (moment().startOf("day").isAfter(selectedData.date)) {
      setShowMessage("Past Date not be scheduled");
      return;
    }
    if (event.employees.length === 0) {
      setShowMessage("No Employee Assign");
      return;
    }
    setCustomScheduleData({ ...event, date: selectedData.date });
  };

  return (
    <>
      <div className="h-100">
        <div className="details-container px-3">
          <div className="events-container">
            {zones.length > 0 &&
              zones.map((event) => (
                <ScheduleZoneEventBar
                  event={event}
                  testByZone={testByZone}
                  handleClickCustomSch={handleClickCustomSch}
                  childNode={childNode}
                />
              ))}
          </div>
          <div className="notes-container">
            <span
              className="d-flex cursor-pointer"
              onClick={() => {
                setNotes("");
                handleSaveNotes({
                  note: "",
                  date: selectedData.date,
                  blockOutDates: blockOutDate,
                  workingDates,
                });
              }}
              style={{ color: "red", justifyContent: "flex-end" }}
            >
              Clear All
            </span>
            <textarea
              placeholder={"Add Note..."}
              style={{ resize: "none" }}
              className="MessageInput"
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
                autoSaveFromApi({
                  note: e.target.value,
                  date: selectedData.date,
                  blockOutDates: blockOutDate,
                  workingDates,
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CalendarDetails;
