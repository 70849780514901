import { DROPDOWN_OPT } from "constant";
import React from "react";
import DropdownMenu from "./DropdownMenu";

const Badge = (props) => {
  const { label, handleClose, location, backgroundColor, handleDropDownOptions } = props;

  return (
    <div
      className="badge bg-secondary d-flex align-items-center justify-content-center gap-2 "
      ref={(el) => {
        if (el) {
          el.style.setProperty("background-color", `${backgroundColor || "#6C757D"}`, "important");
          el.style.setProperty("opacity", ".7");
        }
      }}
    >
      {location && <i className={location === "At Home" ? "fa fa-home" : "fa fa-building"} />}
      <span className="fs-6 fw-light">{label}</span>
      {handleClose && <i className="fas fa-times" onClick={() => handleClose(label)} />}
      {/* {handleDropDownOptions && (
        <DropdownMenu options={DROPDOWN_OPT} handleDropDownOptions={(item) => handleDropDownOptions(label, item)} />
      )} */}
    </div>
  );
};

export default Badge;
