import React, { useState, useContext } from "react";
import { Button, Card, Table, Row, Col, DropdownButton, Dropdown, Modal } from "react-bootstrap";
import { useEffect } from "react";
import api from "api";
import { Auth } from "aws-amplify";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";
import { AppContext } from "../context/app-context";
import Filter from "../components/Filter";
import TimeFilter from "../components/TimeFilter";
import HeaderItem from "components/Table/HeaderItem";
import UserModal from "components/Users/UserModal";
import UserPasswordModal from "components/Users/UserPasswordModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import TDData from "components/Table/TDData";
import { EmptyUserObj, USER_ROLE_TYPE, PAGE_LIMIT, HEALTH_AND_SAFTEY, PERSONALIZATION } from "../constant";
import { formatPhoneNumber, setPhoneNo, capitalizeLetter, formatNumber } from "../utils";
import EditIcon from "../assets/img/edit-icon.png";
import TrashIcon from "../assets/img/trash-icon.png";
import Select from "react-select";
import "../../src/tableStyling.css";
import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import Icon from "components/Icon";
import FilterPopOver from "components/FilterPopOver";

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [newUser, setNewUser] = useState(EmptyUserObj);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const appContext = useContext(AppContext);
  const [fullscreen, setFullscreen] = useState(true);
  const searchTerms = ["First Name", "Last Name", "Email", "Phone", "Role", "Associated"];
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(PAGE_LIMIT);
  // const [pageNumbers, setPageNumbers] = useState([]);
  const [clients, setClients] = useState([]);

  const setUsersArray = async (admins, securitystaffs) => {
    if (!admins || admins === undefined) return;
    const users = [];
    await users.push(...admins.map((admin) => formatUserObject(admin, "Admin")));
    await users.push(...securitystaffs.map((securitystaffs) => formatUserObject(securitystaffs, "SecurityStaff")));

    // users.push(...employees.map((employee) => formatUserObject(employee, "Employee")));
    // users.push(...coordinators.map((coordinator) => formatUserObject(coordinator, "Coordinator")));
    // users.push(...staffs.map((staff) => formatUserObject(staff, "Staff")));
    // setUsers(sortUsers(users, sortBy));
    // return users;
  };

  // useEffect(() => {
  //   if (users) {
  //     const tmp = users.map((u) => {
  //       const userClient = clients.find((c) => c.id === u.clientID);
  //       const userCordClients = clients.find((c) => c.testingCordNumber === u.username);

  //       return {
  //         ...u,
  //         mainContact: userClient && userClient.contactNumber === u.username,
  //         testingCord: userCordClients ? true : false,
  //         clientCordID: userCordClients?.id,
  //         associatedCord: userCordClients?.companyName,
  //       };
  //     });
  //     setFilteredUsers(sortUsers(tmp, sortBy));
  //   }
  // }, [clients, users]);

  const refereshUsers = (users, clients) => {
    const tmp = users.map((u) => {
      const userClient = clients.find((c) => c.id === u.clientMainID);
      const userCordClients = clients.find((c) => c.testingCordNumber === u.phoneNumber);
      const clientNames = u.note?.split(",") || [];
      let userRole = u.userRole;
      if (u.userRole === "Staffs" && u.privilege === "readOnly") {
        userRole = "Admin";
      }
      let associated = "";
      if (clientNames.length > 1) {
        associated = "Multi";
      } else if (clientNames.length === 1) {
        associated = clientNames[0];
      }

      return {
        ...u,
        mainContact: userClient && userClient.contactNumber === u.phoneNumber,
        testingCord: userCordClients ? true : false,
        clientCordID: userCordClients?.id,
        associatedCord: userCordClients?.companyName,
        clientID: u.clientMainID,
        username: u.userID,
        associated: associated,
        phone_number: u.phoneNumber,
        role: userRole,
      };
    });
    setFilteredUsers(nestedFilter(sortUsers(tmp, sortBy), filter));
    setUsers(sortUsers(tmp, sortBy));
    setLoading(false);
  };

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => {
        const testerB = b[sortParam] || "";
        const testerA = a[sortParam] || "";
        return testerA < testerB ? 1 : testerB < testerA ? -1 : 0;
      });
    } else {
      return [...users].sort((a, b) => {
        const testerB = b[sortParam] || "";
        const testerA = a[sortParam] || "";
        return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
      });
    }
  };

  const formatUserObject = (user, role) => {
    const newUser = {};
    const email = user.Attributes.find((attr) => {
      return attr.Name === "email";
    }).Value;
    newUser.email = email;
    const phone = user.Attributes.find((attr) => {
      return attr.Name === "phone_number";
    }).Value;
    const firstName = user.Attributes.find((attr) => {
      return attr.Name === "custom:firstName";
    })?.Value;
    const lastName = user.Attributes.find((attr) => {
      return attr.Name === "custom:lastName";
    })?.Value;

    const note = user.Attributes.find((attr) => {
      return attr.Name === "custom:note";
    })?.Value;
    const clientID = user.Attributes.find((attr) => {
      return attr.Name === "custom:labID";
    })?.Value;
    const privilege = user.Attributes.find((attr) => {
      return attr.Name === "custom:privilege";
    })?.Value;

    const userClient = clients.find((c) => c.id === clientID);

    newUser.name = firstName && lastName ? `${firstName} ${lastName}` : "-";
    newUser.phone_number = formatPhoneNumber(phone);
    newUser.phone = phone;
    newUser.username = user.Username;
    newUser.firstName = firstName ? `${capitalizeLetter(firstName)}` : "-";
    newUser.lastName = lastName ? `${capitalizeLetter(lastName)}` : "-";
    newUser.status = user.UserStatus;
    newUser.role = role;
    newUser.clientID = clientID;
    newUser.associated = note || "N/A";
    newUser.mainContact = userClient && userClient.contactNumber === phone;
    newUser.privilege = privilege;

    const obj = Object.assign(
      {
        role: role,
      },
      newUser
    );
    return obj;
  };

  const filterUsers = (searchTerm, searchInput) => {
    return setFilteredUsers(users.filter((user) => user[searchTerm].includes(searchInput)));
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filter).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects

    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        if (key === "First Name") {
          return obj.firstName && obj.firstName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "Last Name") {
          return obj.lastName && obj.lastName.toLowerCase().includes(filters[key].toLowerCase());
        }

        if (key === "phone_number") {
          return obj.phone_number && obj.phone_number.replace(/\D/g, "").includes(filters[key].replace(/\D/g, ""));
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    // console.log("FROM NESTED FILTER", models);
    return models;
  };

  const createUser = async (user) => {
    console.log("Create user", user);

    try {
      setLoading(true);
      let userId = null;
      const phoneNo = user.phone;
      const userRole = user.privilege && user.privilege === "readOnly" ? "Staffs" : user.role;
      const userClients = user.clients.split(",");
      let clientName = "System";
      let testCordName = "";
      let mainContactName = "";
      let clientMainID = "";
      let clientCordID = "";
      if (userClients.length > 1) {
        clientName = "Multi";
      } else if (userClients.length === 1) {
        const client = clients.find((c) => c.id === userClients[0]);
        if (client) {
          clientName = client.companyName;
        }
      } else if (user.clientID) {
        const client = clients.find((c) => c.id === user.clientID);
        if (client) {
          clientName = client.companyName;
        }
      }
      if (user.mainContact) {
        const client = clients.find((c) => c.id === user.clientID);
        if (client) {
          mainContactName = client.companyName;
          clientMainID = user.clientID;
        }
      }
      if (user.testingCord) {
        const client = clients.find((c) => c.id === user.clientCordID);
        if (client) {
          testCordName = client.companyName;
          clientCordID = user.clientCordID;
        }
      }

      const associatedClients = clients
        .filter((c) => user.clients.indexOf(c.id) !== -1)
        .map((e) => e.companyName)
        .join(",");

      if (user.isNew) {
        const attributePayload = {
          preferred_username: phoneNo,
          email: user.email,
          phone_number: phoneNo,
          "custom:role": `${user.role}s`,
          "custom:autoConfirm": "true",
          "custom:firstName": `${user.firstName}`,
          "custom:lastName": `${user.lastName}`,
          "custom:note": clientName,
          "custom:labID": user.clientID || user.clients[0],
          "custom:privilege": user.privilege,
          "custom:personalisation": JSON.stringify({
            personalize:
              "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25:1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28",
            employeeAll: "0",
            programAll: "0",
          }),
        };

        const signUp = await Auth.signUp({
          username: phoneNo,
          password: user.password,
          attributes: attributePayload,
        });

        userId = signUp.userSub;
        await api.addUserClients(
          phoneNo,
          user,
          userRole,
          phoneNo,
          associatedClients,
          clientMainID,
          mainContactName,
          clientCordID,
          testCordName,
          clientName
        );
        appContext.showSuccessMessage("Create User Successfully");
      } else {
        userId = user.username;
        await api.updateUserInfo(
          user,
          clientName,
          userRole,
          associatedClients,
          clientMainID,
          mainContactName,
          clientCordID,
          testCordName
        );
        if (newUser.role !== user.role) {
          await api.changeUserRole(newUser.role, user.role, phoneNo);
        }
        // if (!newUser.clientID) {
        //   await api.udpatePersonalization(userId, PERSONALIZATION);
        // }
        appContext.showSuccessMessage("Update User Successfully");
      }

      if (user.mainContact && user.clientID) {
        await api.updateMainContact(`${user.firstName} ${user.lastName}`, phoneNo, user.clientID, user.email);
      } else if (newUser.mainContact && !user.mainContact) {
        await api.updateMainContact("", "", newUser.clientID, "");
      }
      if (user.testingCord && user.clientCordID) {
        await api.updateTestingCord(`${user.firstName} ${user.lastName}`, phoneNo, user.clientCordID, user.email);
      } else if (newUser.testingCord && !user.testingCord) {
        await api.updateTestingCord("", "", newUser.clientCordID, "");
      }

      let msg = `Hello, you have been added as an administrator for SafeCamp HR. Please login at https://www.houston.safecamphr.com/. User Name: ${user.phone} \nPassword: ${user.password}`;
      if (userId) {
        if (user.isNew) {
          //  await api.sendSMSNotification(user.phone, msg);
        }
        getUsers(appContext.user);
      }
      setLoading(false);
    } catch (error) {
      console.log("ERROR:User-", error);
      setLoading(false);
      appContext.showErrorMessage(error.message);
      setOpenCreator(true);
    }
  };

  const handleResetPassword = (obj) => {
    console.log("Password Obj", obj);
  };

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = (users) => {
    return users.slice(indexOfFirstUser, indexOfLastUser);
  };

  let usersToMap = currentUsers(filteredUsers);

  const getPageNumbers = (users) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(users.length / usersPerPage); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(users);

  useEffect(async () => {
    if (appContext.user) {
      setLoading(true);
      await getUsers(appContext.user);
    }
  }, [appContext.user]);
  // useEffect(() => {
  //   if (appContext.users instanceof Promise) {
  //     setLoading(true);
  //     appContext.users.then((resp) => {
  //       setUsersArray(resp[0], resp[1]);
  //       setLoading(false);
  //     });
  //   }
  // }, [appContext.users]);
  useEffect(() => {
    if (filteredUsers.length > 0) setFilteredUsers(sortUsers(filteredUsers, sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    if (users.length > 0) {
      setFilteredUsers(nestedFilter(users, filter));
      setCurrentPage(1);
    }
  }, [filter]);

  const loadClients = async () => {
    const models = await api.getClients();
    setClients(models);
    return models;
  };

  const getUsers = async (user) => {
    let res = await api.getAllUsersForShow(user);
    const models = await loadClients();
    if (user.phone_number !== "+19999999999") {
      res = res.filter((f) => f.phoneNumber !== "+19999999999");
    }
    refereshUsers(res, models);
  };

  if (filteredUsers.length > 0) {
    pageNumbers = getPageNumbers(filteredUsers);
  }

  const onRoleChange = async (e, user) => {
    setLoading(true);
    await api.changeUserRole(user.role, e.value, user.username);
    setTimeout(() => {
      appContext.resetUsers();
    }, 1500);
  };

  const renderPageNumbers = () => {
    let slicedPages = pageNumbers;
    if (currentPage === 1) {
      slicedPages = pageNumbers.slice(0, 3);
    } else if (currentPage === pageNumbers.length) {
      slicedPages = pageNumbers.slice(currentPage - 3, currentPage);
    } else {
      slicedPages = pageNumbers.slice(currentPage - 2, currentPage + 1);
    }

    return slicedPages.map((number) => {
      return (
        <li
          key={number}
          onClick={() => handleChangePage(number)}
          style={{
            textDecoration: currentPage === number ? "none" : "underline",
            color: currentPage === number ? "black" : "#A82632",
            display: "inline-block",
            padding: 5,
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = currentPage === number ? "none" : "underline";
          }}
        >
          {number}
        </li>
      );
    });
  };

  const roleChangeOptions = [
    { value: "Admin", label: "Admin" },
    { value: "Lab", label: "Lab" },
    { value: "Tester", label: "Tester" },
    { value: "Employee", label: "Crew" },
    { value: "Employer", label: "Health & Safety" },
  ];

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const getUserLabName = (user) => {
    return appContext.labs.find((lab) => {
      if (lab.lab_contacts !== null && lab.lab_contacts) {
        const isExists = lab.lab_contacts.find((u) => u === user.username);
        if (isExists) return lab;
      }
    });
  };

  const getUserSiteName = (user) => {
    return appContext.sites.find((site) => {
      if (site.show_contacts !== null && site.show_contacts) {
        const isExists = site.show_contacts.find((u) => u === user.username);
        if (isExists) return site;
      }
    });
  };

  const handleEditUser = (user) => {
    const obj = { ...user, isNew: false, phone: user.phoneNumber };
    // if (obj.role === HEALTH_AND_SAFTEY) {
    //   const site = getUserSiteName(user);
    //   if (site) {
    //     obj.show = { label: site.name, value: site.id, id: site.id, name: site.name };
    //   }
    // } else if (obj.role === USER_ROLE_TYPE.Lab) {
    //   const lab = getUserLabName(user);
    //   if (lab) {
    //     Object.assign(obj, {
    //       lab: { label: lab.name, value: lab.id, id: lab.id, name: lab.name, appPackage: lab.whiteLabelPackage },
    //     });
    //   }
    // }
    setNewUser(obj);
    setOpenCreator(true);
  };

  const handleConfirm = async (isConfirm) => {
    setOpenDeleteConfirmation(false);

    if (!isConfirm) {
      setUserToDelete(null);
      return;
    }
    try {
      setLoading(true);
      // await api.updateMainContact("", "", userToDelete.clientID);
      // await api.updateTestingCord("", "", userToDelete.clientCordID);
      await api.deleteUser(userToDelete.username);
      setUserToDelete(null);
      // appContext.resetUsers();
      appContext.showSuccessMessage("Successfully delete user");
      getUsers(appContext.user);
      setLoading(false);
    } catch (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const getRoleDetails = (user) => {
    if (user.role === USER_ROLE_TYPE.Tester || user.role === USER_ROLE_TYPE.Admin) {
      return "System";
    }
    if (user.role === HEALTH_AND_SAFTEY) {
      const site = getUserSiteName(user);
      if (site) return site.name;
    }
    if (user.role === USER_ROLE_TYPE.Lab) {
      const lab = getUserLabName(user);
      if (lab) return lab.name;
    }
    if (user.role === USER_ROLE_TYPE.Employee) {
      const f = async () => await api.getEmployeeLastShow(user.username);
      return f();
    }
    return user.role;
  };

  const TableRow = ({ user }) => {
    return (
      <tr key={user.username}>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
          <img
            src={EditIcon}
            alt="edit icon"
            width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={user.id}
            onClick={(e) => handleEditUser(user)}
          />
        </td>
        <td className="ellipsis" style={{ textAlign: "left" }} title={user.firstName}>
          {user.firstName}
        </td>
        <td className="ellipsis" style={{ textAlign: "left" }} title={user.lastName}>
          {user.lastName}
        </td>
        <td
          className="table-column ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => user?.email && window.open(`mailto:${user.email}`)}
          title={user.email}
        >
          {user.email}
        </td>
        <td
          className="ellipsis"
          style={{
            textDecoration: "underline",
            color: "#A82632",
          }}
          onMouseOver={(e) => {
            e.target.style.cursor = "pointer";
            e.target.style.textDecoration = "none";
          }}
          onMouseLeave={(e) => {
            e.target.style.textDecoration = "underline";
          }}
          onClick={() => user?.phone_number && window.open(`tel:+${user.phone_number.replace(/\D/g, "")}`)}
          title={user.phone_number}
        >
          {user.phone_number}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.role}>
          {user.role}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.note}>
          {user.associated}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.mainContact ? <ViewCheckBox id="testCord" isChecked={true} /> : "-"}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }} title={user.associatedCord}>
          {user.associatedCord}
        </td>
        <td className="ellipsis" style={{ textAlign: "center" }}>
          {user.testingCord ? <ViewCheckBox id="testCord" bgColor="green" isChecked={true} /> : "-"}
        </td>
        <td
          className="icon"
          style={{ textAlign: "center", textOverflow: "visible", width: "45px", minWidth: "45px", maxWidth: "45px" }}
        >
          <img
            src={TrashIcon}
            alt="trash icon"
            width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            id={user.id}
            onClick={(e) => {
              setOpenDeleteConfirmation(true);
              setUserToDelete(user);
            }}
          />
        </td>
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    color: "#565656",
                  }}
                >
                  System Users ({filteredUsers ? formatNumber(filteredUsers.length) : formatNumber(users.length)})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    label="Filter"
                    title="Filter"
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => {
                      setNewUser({ ...EmptyUserObj });
                      setOpenCreator(true);
                    }}
                    label={"Create"}
                    title={"Create Employee"}
                    iconType={"addUserIcon"}
                  />
                </div>
                {showFilter && (
                  <FilterPopOver filterTerms={searchTerms} setFilter={setFilter} filter={filter} isUsersTable={true} />
                )}

                <Row className="pull-right" style={{ borderWidth: 1 }}>
                  <Col></Col>
                  <Col style={{ textAlign: "right" }} md={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }}>
                    Page
                    <ul className="createButtons pr-0" style={{ display: "inline" }}>
                      <i
                        className="fas fa-angle-left"
                        style={{ color: "#585858" }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.color = "black";
                        }}
                        onMouseLeave={(e) => (e.target.style.color = "#585858")}
                        onClick={() => {
                          currentPage !== 1 && handleChangePage(currentPage - 1);
                        }}
                      />
                      {/* {currentPage !== 1 && "..."} */}
                      {currentPage} of {pageNumbers.length}
                      {/* {pageNumbers.length >= currentPage && "..."} */}
                      <i
                        className="fas fa-angle-right"
                        style={{ color: "#585858" }}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                          e.target.style.color = "black";
                        }}
                        onMouseLeave={(e) => (e.target.style.color = "#585858")}
                        onClick={() => {
                          currentPage !== pageNumbers.length && handleChangePage(currentPage + 1);
                        }}
                      />
                    </ul>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table>
                    <thead>
                      <tr>
                        <th />

                        <HeaderItem
                          ItemKey="firstName"
                          title="First Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />

                        <HeaderItem
                          ItemKey="lastName"
                          title="Last Name"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          ItemKey="email"
                          title="Email"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          cssClass="emailWidth"
                        />
                        <HeaderItem
                          ItemKey="phone_number"
                          title="Phone Number"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                        />
                        <HeaderItem
                          ItemKey="role"
                          title="Role"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          ItemKey="associated"
                          title="Associated"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          ItemKey="mainContact"
                          title="Main Contact"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          ItemKey="associatedCord"
                          title="Testing Coordinator"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                        <HeaderItem
                          ItemKey="testingCord"
                          title="Testing Contact"
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned="centered"
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {usersToMap &&
                        usersToMap.map((user, i) => {
                          return <TableRow key={i} user={user} />;
                        })}
                      {/* {usersToMap.map((user, i) => <TableRow user={user} key={i}/>)} */}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        // <h3
        //   style={{
        //     display: "block",
        //     marginLeft: "auto",
        //     marginRight: "auto",
        //     paddingTop: 50,
        //     paddingBottom: 50,
        //   }}
        // >
        //   Loading...
        // </h3>
        <Bounce
          style={{
            position: "fixed",
            left: "50%",
            right: "50%",
            top: "50%",
          }}
          size={35}
          color={"#A82632"}
        />
      )}
      {openCreator && (
        <UserModal
          show={openCreator}
          user={newUser}
          clients={clients.map((c) => {
            return { label: c.companyName, value: c.id };
          })}
          handleClose={handleClose}
          handleSave={createUser}
          resetCall={setOpenPasswordModal}
          adminUser={appContext.user}
        />
      )}
      {openPasswordModal && (
        <UserPasswordModal user={newUser} handleClose={handlePasswordClose} handleSave={handleResetPassword} />
      )}

      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete User"
          message="Are you sure, you want to remove User?"
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default Users;
