import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { getValidReg, checkValidity } from "../../utils";

const CustomDropDown = (props) => {
  const { title, data, selectedOption, onOptionChange, setSelectedVal } = props;
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    selectedOption ? { value: selectedOption, label: selectedOption } : null
  );

  useEffect(() => {
    setOptions(data);
  }, [data]);
  useEffect(() => {
    setSelectedVal && setSelectedVal(selectedValue && selectedValue.value);
  }, [selectedValue]);
  useEffect(() => {
    if (selectedOption) setSelectedValue({ value: selectedOption, label: selectedOption });
  }, [selectedOption]);

  const dropDownOptions = useMemo(() => {
    return options.map((opt) => {
      const val = getValidReg(opt.value);
      return { value: val, label: val };
    });
  }, [options]);
  useEffect(() => {
    if (dropDownOptions.length === 1 && !selectedOption) {
      const obj = { value: dropDownOptions[0].value, label: dropDownOptions[0].label };
      setSelectedValue(obj);
      onOptionChange(obj);
    }
  }, [dropDownOptions]);
  let changeValue = true;
  const handleOnChange = (e) => {
    setSelectedValue(e);
    changeValue = false;
    if (onOptionChange) {
      onOptionChange(e);
    }
  };

  const handleChangeSelectInput = (e, r) => {
    const newValue = r.prevInputValue;
    if (r.action === "input-change") {
      changeValue = true;
    }
    if (r.action === "input-blur" && newValue && changeValue) {
      const val = getValidReg(newValue);
      let newOption = { value: val, label: val };
      let items = [...options];
      const findVal = items.findIndex((c) => checkValidity(c.label) == checkValidity(newOption.label));
      if (findVal !== -1) {
        newOption = items[findVal];
        setOptions(items);
      } else {
        setOptions([...options, newOption]);
      }
      handleOnChange({ value: newOption.value, label: newOption.value });
    }
  };
  return (
    <Select
      options={dropDownOptions}
      blurInputOnSelect={true}
      menuPlacement="auto"
      placeholder={title}
      className="w-100"
      onInputChange={handleChangeSelectInput}
      value={selectedValue}
      onChange={handleOnChange}
    />
  );
};

export default CustomDropDown;
