const stringConstants = {
  questionScheduleList: "Pre-Screening Schedule",
  questionnaire: "Pre-Screening Question Group",
  EOS: "EA{S}",
  UEOS: "UEA{S}",
  onSetDate: "At {Set} Date Range",
  expectedOnSet: "Expected At {Set}",
  updateExpectedOnSet: "Update At {Set} List",
};

export const t = (key, obj) => {
  let val = stringConstants[key];
  if (obj) {
    Object.entries(obj).forEach(([key, value]) => {
      val = val.replace(`{${key}}`, value);
    });
  }
  return val;
};
