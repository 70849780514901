import React from "react";
import workingDayOnIcon from "assets/img/film.png";
import workingDayOffIcon from "assets/img/film-gray.png";

const WorkingDayCalendarSideBar = (props) => {
  const { workingDate, date, handleWorkingDate } = props;
  return (
    <div className="calendar-detail-top my-2 mx-3">
      <span className="fw-bold">{workingDate ? "Working Day" : "Not working Day"}</span>
      <span className="exclamation-mark ms-1 cursor-pointer" onClick={() => handleWorkingDate(date)}>
        {workingDate ? (
          <img style={{ width: "20px", height: "20px" }} src={workingDayOnIcon} alt="working-day-on-img" />
        ) : (
          <img style={{ width: "20px", height: "20px" }} src={workingDayOffIcon} alt="working-day-off-img" />
        )}
      </span>
    </div>
  );
};

export default WorkingDayCalendarSideBar;
